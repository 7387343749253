import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Card,
    CardContent,
    Typography,
} from '@mui/material'
import DirectionsOffIcon from '@mui/icons-material/DirectionsOff';
import "./404Page.scss"
import {useEffect} from "react";
function Page404({}) {



    return (
            <div className="glitch" style={{
                padding: "50px",
            }}>
                <div className="hero-container">
                    <div className="environment" />
                    <h2 className="hero glitch layers" data-text="404 Page"><span>404 Page</span></h2> <br/>
                    <h6 className="hero glitch layers" data-text="Not Found"
                        style={{
                            margin: 0,
                            fontSize: '40px',
                        }}
                    >
                        <span>Page Not Found</span>
                    </h6>
                </div>
            </div>
    )
}

Page404.propTypes = {}

Page404.defaultProps = {}

export default Page404;
