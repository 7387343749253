import * as React from 'react';
import {useState} from "react";
import {
    Button,
    Card,
    CardContent,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Stack,
    Switch,
    TextField,
    Collapse,
    LinearProgress,
} from '@mui/material'
import CloseIcon from "@mui/icons-material/Close";
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import AddIcon from "@mui/icons-material/Add";
import AnimatedDoubleList from "./animatedList/AnimatedDoubleList";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import Box from "@mui/material/Box";
import AddImageSearchTable from "./AddImageSearchTable";
import Papa from "papaparse";
import DataService from "../DataService";
import toastr from "toastr";
import CircularProgress from '@mui/material/CircularProgress';
import Typography from '@mui/material/Typography';

const MAX_PROMISES_BEFORE_DELAY = 20;
const UPLOAD_DELAY = 1000;

function takeABreak() {
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve('Done waiting for 1 second');
        }, UPLOAD_DELAY);
    });
}
function CircularProgressWithLabel(props) {
    return (
        <Box sx={{ position: 'relative', display: 'inline-flex'}}>
            <CircularProgress
                size={'20em'}
                variant="determinate" {...props} />
            <Box
                sx={{
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    position: 'absolute',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                }}
            >
                <Typography variant="h1" component="div" color="text.secondary">
                    {`${Math.round(props.value)}%`}
                </Typography>
            </Box>
        </Box>
    );
}

function AddImageSearchModal(
    {
        open,
        handleClose,
        updateTotal,
    }
) {
    const [name, setName] = useState('');
    const [url, setUrl] = useState('');
    const [exampleType, setExampleType] = useState('url');
    const [images, setImages] = useState([]);
    const [openTagsCard, setOpenTagCard] = useState(false);
    const [tagName, setTagName] = useState('');
    const [tagValue, setTagValue] = useState('');
    const [tags, setTags] = useState({});
    const [exampleFiles, setExampleFiles] = useState([undefined]);
    const [progress, setProgress] = React.useState(96);
    const [buffer, setBuffer] = React.useState('Ready to upload...');
    const [isLoading, setIsLoading] = useState(false);

    async function uploadImages(images) {
        const totalUpload = images.length
        const rounds = totalUpload / MAX_PROMISES_BEFORE_DELAY;
        setProgress(0);
        setBuffer(`Loading the first batch of images...`);
        setIsLoading(true);
        for (let j = 0; j < rounds; j++) {
            const currIndex = j * MAX_PROMISES_BEFORE_DELAY;
            const nextIndex = currIndex + MAX_PROMISES_BEFORE_DELAY;
            const onGoing = images
                .slice(currIndex, nextIndex)

            const promises = onGoing.map((image) =>
                DataService.addImage(
                    image.name,
                    image.url,
                    image.mediaBase64,
                    image.meta
                ).catch((err) => {
                    console.error(err)
                })
            );

            await Promise.all(promises)
            await takeABreak();
            const percent = Math.ceil(nextIndex * (100 / totalUpload))
            setBuffer(`We have processed ${ j + 1 } ${j===0? 'batch' : 'batches'}`)
            setProgress(percent);
        }

        // setTotalCount(prevState => prevState + images.length)

        setIsLoading(false);
    }


    const cleanModal = () => {
        setName('');
        setUrl('');
        setTags({});
        setExampleFiles([undefined]);
        setBuffer('Ready to start...');
    }

    const onSubmit = () => {
        setImages([]);
        uploadImages(images)
            .then(() => {
                handleClose();
                cleanModal();
                updateTotal(images.length)
                toastr.success(
                    "Thank you. Your images have been activated"
                );
            })
    }

    const handleAddTag = () => {
        const oldTags = tags;

        oldTags[tagName] = tagValue;

        setTags(
            oldTags
        );

        setTagName('');
        setTagValue('');
    }

    const removeTag = (item) => {
        const newTags = {...tags};
        delete newTags[item]
        setTags(newTags);
    };

    const handleChangeSwitch = (event) => {
        console.log(exampleFiles)
        setExampleType(
            !event.target.checked ? "url" : "file"
        )
    }

    const handleUploadCSV = (files) => {
        if (files.length === 0) {
            return;
        }
        Papa.parse(files[0], {
            download: true,
            skipEmptyLines: true,
            complete: function (input) {
                parseVisualCsv(input.data);
            }
        })
    }

    const parseVisualCsv = (csvLines) => {
       const elems = csvLines.slice(1).map((el) => {
           const name = el[0];
           const url = el[1];
           const tag = el[2];
           const value = el[3];
           const visualEl = {
               name, url
           };
           if (!!tag && !!value) {
             visualEl.meta = {};
             visualEl.meta[tag] = value;
           }
           return visualEl;
       })
        setImages(elems);
    }

    const handleAddToMainList = () => {
        const image = {
            name,
            url,
        }
        const exampleFilesFiltered = exampleFiles.filter(Boolean);

        if (exampleFilesFiltered.length > 0 && exampleType !== 'url') {
            image.mediaBase64 = exampleFilesFiltered[0].base64;
            image.fileName = exampleFilesFiltered[0].name;
            image.url = '';
        }

        if (Object.keys(tags).length > 0) {
            image.meta = tags;
        }

        setImages([...images, image])
        cleanModal();
    }


    const removeImage = (image) => {
        const copyImages = [...images];
        const index = copyImages.indexOf(image);
        if (index > -1) {
            copyImages.splice(index, 1); // 2nd parameter means remove one item only
        }
        setImages(copyImages);
    }

    /**
     *
     * @param {FileList} fileList
     * @param {*} i
     */
    function onExampleFileChange(fileList, i) {
        if (fileList.length === 0) {
            return;
        }

        const reader = new FileReader();
        reader.onloadend = function () {
            exampleFiles[i] = {
                base64: reader.result,
                name: fileList[0].name,
            };
            setExampleFiles([...exampleFiles]);
        };
        reader.readAsDataURL(fileList[0]);
    }



    return (
        <>
            <Dialog
                maxWidth={'xl'}
                fullWidth={true}
                open={open}
                onClose={handleClose}
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span>Add Images</span>
                    <IconButton
                        onClick={handleClose}
                        sx={{ml: 'auto'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        isLoading && <LinearProgress />
                    }

                    {
                        isLoading ? (
                                <Grid container={true}>

                                    <Grid item
                                          sx={{
                                              my: 5,
                                              mx: 'auto',
                                              display: 'flex'
                                          }}
                                    >
                                        <CircularProgressWithLabel value={progress} />
                                        <Typography variant={'h5'} sx={{m: 'auto', ml: 2}}>
                                            {buffer}
                                        </Typography>
                                    </Grid>
                                </Grid>
                        ) : (
                            <Grid container={true}>
                                <Grid item xs={4}>
                                    <Grid
                                        container={true}
                                        sx={{
                                            p: 1,
                                        }}
                                    >
                                        <Stack
                                            spacing={2}
                                            sx={{
                                                width: "100%",
                                                p: 1,
                                            }}
                                        >
                                            <TextField
                                                label={`Name(Optional)`}
                                                placeholder={'E.g. Coca Cola'}
                                                variant="standard"
                                                fullWidth
                                                value={name}
                                                onChange={
                                                    e => {
                                                        setName(e.target.value)
                                                    }
                                                }
                                            />

                                            <Grid container>
                                                <Grid item xs={4}>
                                            <span>
                                                Add an
                                            </span>
                                                </Grid>
                                                <Grid item xs={4} sx={{ml: 'auto'}}>
                                                    <Grid
                                                        component="label"
                                                        container
                                                        alignItems="center"
                                                        justifyContent={'center'}
                                                        sx={{mb: 0}}
                                                    >
                                                        <Grid item>
                                                            <Typography
                                                                variant={'body1'}
                                                                sx={{
                                                                    fontSize: '0.8rem',
                                                                    color: exampleType !== 'file' ? 'primary.main' : "text.disabled"
                                                                }}
                                                            >
                                                                URL
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item>
                                                            <Switch
                                                                size={'small'}
                                                                checked={exampleType === 'file'} // relevant state for your case
                                                                onChange={handleChangeSwitch} // relevant method to handle your change
                                                            />
                                                        </Grid>
                                                        <Grid item>
                                                            <Typography
                                                                variant={'body1'}
                                                                sx={{
                                                                    fontSize: '0.8rem',
                                                                    color: exampleType === 'file' ? 'primary.main' : "text.disabled"
                                                                }}
                                                            >
                                                                Files
                                                            </Typography>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>

                                            <Box sx={{
                                                display: 'flex', alignItems: 'center', justifyContent: 'center'
                                            }}>

                                                {
                                                    exampleType === 'url' ?

                                                        <TextField
                                                            label={`Image URL*`}
                                                            placeholder={'E.g. https://coca.com/img.jpg'}
                                                            variant="standard"
                                                            value={url}
                                                            onChange={e => setUrl(e.target.value)}
                                                            sx={{m: 0, minWidth: '230px'}}
                                                        />
                                                        :
                                                        <label
                                                            htmlFor="csvFileUpload"
                                                        >
                                                            <input
                                                                style={{display: 'none'}}
                                                                accept="image/*"
                                                                id="csvFileUpload"
                                                                type="file"
                                                                onChange={e => onExampleFileChange(e.target.files, 0)}
                                                            />
                                                            <Button
                                                                variant="contained"
                                                                component="span"
                                                                startIcon={
                                                                    <InsertPhotoIcon/>
                                                                }
                                                            >
                                                                Upload
                                                            </Button>
                                                        </label>
                                                }

                                            </Box>

                                            <Card
                                                sx={{
                                                    my: 1,
                                                }}
                                            >
                                                <Grid
                                                    container
                                                    alignItems={'center'}
                                                    sx={{
                                                        p: 1,
                                                    }}
                                                >
                                                    <Grid
                                                        item
                                                        xs={6}
                                                        onClick={_ => {
                                                            setOpenTagCard(
                                                                !openTagsCard
                                                            )
                                                        }}
                                                    >
                                                        <Typography
                                                            variant={'h5'}
                                                            sx={{
                                                                fontSize: '1rem',
                                                                fontWeight: 'bold',
                                                            }}
                                                        >
                                                            Meta Tags
                                                        </Typography>
                                                    </Grid>
                                                    <Grid item sx={{ml: 'auto'}}>
                                                        <IconButton
                                                            onClick={_ => {
                                                                setOpenTagCard(
                                                                    !openTagsCard
                                                                )
                                                            }}
                                                            sx={{ml: 'auto'}}
                                                        >
                                                            {
                                                                openTagsCard
                                                                    ? <KeyboardArrowUpIcon/>
                                                                    : <KeyboardArrowDownIcon/>
                                                            }
                                                        </IconButton>
                                                    </Grid>
                                                </Grid>

                                                <Collapse in={openTagsCard}>
                                                    <CardContent
                                                        sx={{pt: 0}}
                                                    >
                                                        <Grid container spacing={1}>
                                                            <Grid item xs={5}>
                                                                <TextField
                                                                    label="Tag Name"
                                                                    variant="standard"
                                                                    value={tagName}
                                                                    onChange={e => setTagName(e.target.value)}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={6}>
                                                                <TextField
                                                                    label="Tag Value"
                                                                    variant="standard"
                                                                    value={tagValue}
                                                                    onChange={e => setTagValue(e.target.value)}
                                                                    fullWidth
                                                                />
                                                            </Grid>
                                                            <Grid item xs={1}>
                                                                <IconButton
                                                                    color={'success'}
                                                                    onClick={_ => {
                                                                        handleAddTag()
                                                                    }}
                                                                >
                                                                    <AddIcon/>
                                                                </IconButton>
                                                            </Grid>
                                                        </Grid>
                                                        <AnimatedDoubleList
                                                            items={Object.keys(tags)}
                                                            subtitles={ Object.keys(tags).map(el => tags[el])}
                                                            removeListItem={removeTag}
                                                        />
                                                    </CardContent>
                                                </Collapse>
                                            </Card>

                                            <Button
                                                variant={'contained'}
                                                fullWidth
                                                onClick={_ => {
                                                    return handleAddToMainList()
                                                }}
                                                sx={{
                                                    fontWeight: 'bold',
                                                }}
                                                disabled={
                                                    (exampleType === 'file' && exampleFiles[0] === undefined)
                                                    || (exampleType === 'url' && url.length === 0)
                                                }
                                            >
                                                Add To List >>
                                            </Button>
                                        </Stack>
                                    </Grid>
                                </Grid>
                                <Grid item xs={8}>
                                    <AddImageSearchTable
                                        rows={images}
                                        handleRemoveItem={removeImage}
                                    />
                                </Grid>
                            </Grid>
                        )
                    }



                </DialogContent>

                <DialogActions
                    sx={{
                        borderTop: '1px solid #ccc',
                        py: 2,
                        px: 3,
                    }}
                >

                    <Grid container>
                        <Grid
                            item
                            xs={10}
                            sx={{}}
                        >
                            <Grid container justifyContent={'center'} alignItems={'center'}>
                                <Grid item xs={2}>
                                    <label
                                        htmlFor="csvFileUpload"
                                    >
                                        <input
                                            style={{display: 'none'}}
                                            accept="text/csv"
                                            id="csvFileUpload"
                                            type="file"
                                            onChange={e => handleUploadCSV(e.target.files)}
                                        />
                                        <Button
                                            variant="contained"
                                            component="span"
                                            color={'info'}
                                        >
                                            Upload CSV
                                        </Button>
                                    </label>
                                </Grid>
                                <Grid
                                    item
                                    xs={10}
                                    sx={{fontSize: '10px'}}
                                >
                                    If submitting a large batch of images use the 'Upload CSV' feature.
                                    Please create the file in the following CSV format.
                                    <a href="files/LogoGrab_image_request.csv" download>Download template.</a> <br/>
                                </Grid>
                            </Grid>
                        </Grid>
                        <Grid item xs={2} textAlign={'right'}>
                            <Button
                                variant={'contained'}
                                sx={{ml: 'auto'}}
                                disabled={images.length === 0}
                                onClick={_ => {
                                    onSubmit()
                                }}
                            >
                                Submit
                            </Button>
                        </Grid>
                    </Grid>

                </DialogActions>

            </Dialog>
        </>
    )
}

AddImageSearchModal.propTypes = {}


export default AddImageSearchModal;
