import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Stack,
    Skeleton,
    Grid,
} from "@mui/material";

import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import ImageListItemBar from '@mui/material/ImageListItemBar';

import {useEffect, useState} from "react";
import Box from "@mui/material/Box";
import Image from 'material-ui-image';
import Typography from "@mui/material/Typography";

function PlacementPreview(
    {
        pictures,
        isLoading,
    }
) {
    console.log(pictures)
    const [selected, setSelected] = useState(0);

    useEffect(_ => {
            return setSelected(0);
    }, [pictures])

    const onThumbUrlClick = (index) => {
        setSelected(index)
    }

    return (
        <>

            <Grid container spacing={1}>
                <Grid
                    item={true}
                    xs={2}
                    sx={{
                        height: "calc(100vh - 72px - 32px - 60px - 32px - 50px - 10px)",
                        overflow: 'auto'
                    }}
                >
                    {/*}
                    <ImageList cols={1}   className={'removeImgSize'}>
                        {pictures.map((item, index) => (
                            <ImageListItem
                                cols={1}
                                key={item.id}
                                onClick={_ => onThumbUrlClick(index)}

                            >
                                <div
                                    style={{
                                        width: '100%',
                                        background: 'grey',
                                        padding: '10px'
                                    }}
                                >
                                    <img
                                        src={item.thumbUrl}
                                        srcSet={item.thumbUrl}
                                        alt={item.name}
                                        loading="lazy"
                                        style={{
                                            width: '100%',
                                            border:
                                                index === selected ? '4px solid #1DB8E0' : 'none',
                                        }}
                                    />
                                </div>
                                <ImageListItemBar
                                    title={item.name}
                                    subtitle={item.id}
                                    position={'below'}
                                />
                            </ImageListItem>
                        ))}
                    </ImageList>


                     {*/}

                    <Stack spacing={2} sx={{borderRight: '1px solid #DEE3E8', pr: 1}}>
                        {
                            isLoading && [...Array(10).keys()].map((numb) => (
                                <Skeleton
                                    key={numb}
                                    variant={'rectangular'}
                                    height={125}
                                />
                            ))
                        }
                        {
                            !isLoading && pictures.map((pic, index) => (
                                <div key={pic.id} className={'removeImgSize'}>
                                    <img
                                        src={pic.thumbUrl}
                                        alt=""
                                        onClick={_ => onThumbUrlClick(index)}
                                        loading="lazy"
                                        style={{
                                            border:
                                                index === selected ? '4px solid #1DB8E0' : '4px solid transparent',
                                            width: '100%'
                                        }}
                                    />
                                    <p style={{fontWeight: 'bold', textAlign: 'center', margin: '0'}}>
                                        {pic.name}
                                    </p>
                                </div>

                            ))
                        }
                    </Stack>
                </Grid>
                <Grid item={true} xs={10} sx={{
                    overflow: 'auto',
                    height: "calc(100vh - 72px - 32px - 60px - 50px)",
                }}>
                    {
                        isLoading ?
                            <>
                                <Skeleton
                                    variant={'text'}
                                    width={200}
                                />
                                <Skeleton
                                    variant={'rectangular'}
                                    height={500}
                                />
                            </>
                            :
                            <div
                                className={'removeImgSize'}
                            >
                                {
                                    pictures.length === 0 &&
                                    <Typography variant={'h4'} sx={{m: 2}}>
                                        Sorry! We couldn't find any data using the filters selected. <br/>
                                        Maybe try something else?
                                    </Typography>
                                }
                                {
                                    pictures.length > selected &&
                                    <>
                                        <b>
                                            {pictures[selected].name}
                                        </b>
                                        <span style={{marginLeft: '15px'}}>
                                    ID {pictures[selected].id}
                                </span>
                                        <Image
                                            src={pictures[selected].rasterUrl}
                                            alt={pictures[selected].name}
                                            style={{
                                                width: '100%',
                                            }}
                                        />
                                    </>

                                }

                            </div>
                    }
                </Grid>
            </Grid>
        </>
    )
}

PlacementPreview.propTypes = {}

PlacementPreview.defaultProps = {}

export default PlacementPreview;
