import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Button,
    Card, CardContent,
    Grid, Switch, Typography
} from '@mui/material';
import {Toolbar} from 'visuaui';
import SearchInput from "../../components/SearchInput";
import AddIcon from "@mui/icons-material/Add";
import CloudDownloadIcon from "@mui/icons-material/CloudDownload";
import {useEffect, useState} from "react";
import {getPlacement, getPlacementLog, postPlacement} from "../../service/ApiService";
import ErrorIcon from '@mui/icons-material/Error';
import PlacementListTable from "../../components/PlacementListTable";
import PlacementPreview from "../../components/PlacementPreview";
import ActivationLogModal from "../../components/ActivationLogModal";
import AddPlacementModal from "../../components/AddPlacementModal";
import toastr from "toastr";

function Placement(
    {

    }
) {


    const [isLoading, setIsLoading] = useState(true);
    const [place, setPlace] = useState([])

    const [placeRendered, setPlaceRendered] = useState([]);
    const [searchText, setSearchText] = useState([]);
    const [listType, setListType] = useState('table');
    const [logModal, setLogModal] = useState(false);
    const [logModalItem, setLogModalItem] = useState(false);
    const [showAddModal, setShowAddModal] = useState(false);

    useEffect(_ => {
        getPlacement()
            .then(res => {
                const {data} = res;
                if (data.placements) {
                   setPlace(data.placements) ;
                   setIsLoading(false);
                }

            })
            .catch(err => console.error(`Get Placement error:  ${err}`))
        getPlacementLog()
            .then(res => {
                const {data} = res;
                console.log(data);
                if (data.placements) {
                    setLogModalItem(data.placements) ;
                }
            })
            .catch(err => console.error(`Placement Activation Log error:  ${err}`))
    }, [])


    useEffect(_ => {
        if (place.length === 0) return;
        if (searchText.length === 0) setPlaceRendered(place);

        const filteredPlace = place.filter(el => el.name.indexOf(searchText) !== -1);
        setPlaceRendered(filteredPlace);

    }, [place, searchText])

    const handleChangeSwitch = (event) => {
        setListType(
            !event.target.checked ? "table" : "full"
        )
    }


    const addNewPlacements = (placements) => {
        setShowAddModal(false)
        postPlacement(placements)
            .then(_ =>
                toastr.success(
                    "Thank you. These placements will be activated in the next 48 hours"
                )
            )
            .catch(_ => {
                toastr.error('Something went wrong')
            })
    }


    return (
        <>
            <ActivationLogModal
                open={logModal}
                handleClose={_ => setLogModal(false)}
                items={logModalItem}
            />
            <AddPlacementModal
                open={showAddModal}
                handleClose={_ => setShowAddModal(false)}
                addNewPlacements={addNewPlacements}
            />
            <Card
                raised={true}
                sx={{p: 1}}
            >
                <Toolbar removeBackground={true}>
                    <Grid
                        container
                        spacing={2}
                        alignItems={'center'}
                    >
                        <Grid item>
                            <SearchInput
                                onChange={txt => {
                                    setSearchText(txt)
                            }}/>
                        </Grid>


                        <Grid item >
                            <Grid
                                component="label"
                                container
                                alignItems="center"
                                justifyContent={'center'}
                                sx={{mb: 0}}
                            >
                                <Grid item>
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontSize: '0.8rem',
                                            color: listType !== 'full' ? 'primary.main' : "text.disabled"
                                        }}
                                    >
                                        List
                                    </Typography>
                                </Grid>
                                <Grid item>
                                    <Switch
                                        size={'small'}
                                        checked={listType === 'full'} // relevant state for your case
                                        onChange={handleChangeSwitch} // relevant method to handle your change
                                    />
                                </Grid>
                                <Grid item>
                                    <Typography
                                        variant={'body1'}
                                        sx={{
                                            fontSize: '0.8rem',
                                            color: listType === 'full' ? 'primary.main' : "text.disabled"
                                        }}
                                    >
                                        Preview
                                    </Typography>
                                </Grid>
                            </Grid>
                        </Grid>



                        <Grid item marginLeft={'auto'}>
                            <Button disabled={false} onClick={_ => { setLogModal(true)}}>
                                Activation log
                            </Button>
                        </Grid>
                        <Grid item>
                            <Button startIcon={<AddIcon/>} variant={'contained'} color={'success'} onClick={_ => {
                                setShowAddModal(true)
                            }}>
                                REQUEST PLACEMENT
                            </Button>
                        </Grid>
                        <Grid item sx={{display: 'none'}}>
                            <Button
                                startIcon={<CloudDownloadIcon/>}
                                color={'info'}
                                variant={'contained'}
                                onClick={_ => {}}
                            >
                                CSV
                            </Button>
                        </Grid>
                    </Grid>

                </Toolbar>
                <CardContent sx={{
                    overflow: 'hidden',
                    width: "100%",
                    height: "calc(100vh - 72px - 32px - 60px - 32px - 50px - 10px)",
                }}>
                    {
                        place.length === 0 && !isLoading &&
                            <Grid container justifyContent={'center'}
                                  alignItems={'center'}>
                                <Grid item xs={8} sx={{mt: 4}}>
                                    <Typography
                                        variant={'h3'}
                                    >
                                        <ErrorIcon fontSize={'large'} sx={{mr: 1}}/>
                                        Uh oh...
                                    </Typography>
                                    <Typography
                                        variant={'h6'}
                                    >
                                        Sorry! We couldn't find any data using the
                                        filters selected. Maybe
                                        try something else?
                                    </Typography>
                                </Grid>
                            </Grid>
                    }
                    {
                        listType !== 'full'
                            ? <PlacementListTable
                                rows={placeRendered}
                                loading={isLoading}
                            />
                            : <PlacementPreview
                                pictures={placeRendered}
                                isLoading={isLoading}
                            />
                    }

                </CardContent>
            </Card>
        </>
    )
}

Placement.propTypes = {}

Placement.defaultProps = {}

export default Placement;
