import {createTheme} from '@mui/material/styles';

let visuaTheme;
export default visuaTheme = createTheme({
    palette: {
        type: 'light',
        primary: {
            main: '#1DB8E0',
            light: '#D2F1F9',
            contrastText: '#FFFFFF',
            dark: '#0088AE',
        },
        secondary: {
            main: '#02264D',
            light: '#FBC02D',
            dark: '#BCC5CF',
        },
        success: {
            main: '#88BF40',
            contrastText: '#FFF'
        },
        info: {
          main: '#8092A6'
        },
        background: {
            default: '#F5F7F8',
            paper: '#FFFFFF',
        },
        text: {
            secondary: '#02264D',
            primary: '#02264D',
            disabled: '#BCC5CF',
            hint: '#F1BE48',
        },
    },
    props: {
        MuiTooltip: {
            arrow: true,
        },
        MuiAppBar: {
            backgroundColor: '#FFF',
            color: 'default',
        },
    },
});
