import React from "react";
import {Toolbar} from "visuaui";
import {Link, Route, useRouteMatch} from "react-router-dom";
import {
    Container,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import PipelineTab from "./pipeline/pipeline-tab";

const OPTIONS = [
    'progress',
    'complete',
    'archived',
]

export default function PipelineContainer() {
    let { url } = useRouteMatch();
    const pipelineTabs = OPTIONS.map((option) => (
        <Tab
            key={option}
            label={option}
            component={Link}
            to={`${url}/${option}`}
            value={`${url}/${option}`}
            sx={{
                "&.Mui-selected": {
                    fontWeight: 'bold !important',
                }
            }}
        />
    ))

    return (
        <>
            <Toolbar>
                <Route
                    path="/"
                    render={({location}) => (
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={location.pathname}>
                                {pipelineTabs}
                            </Tabs>
                        </Box>
                    )}
                />
            </Toolbar>
                <Route
                    path={`${url}/progress`}
                    render={() => <PipelineTab type={1}/>}
                />
                <Route
                    path={`${url}/complete`}
                    render={() => <PipelineTab type={2} />}
                />
                <Route
                    path={`${url}/archived`}
                    render={() => <PipelineTab type={3} />}
                />
        </>
    )
}
