import React, {useState, useEffect} from "react";
import moment from "moment";
import c3 from "c3";

import {
    StatCard,
    Card,
    Toolbar
} from 'visuaui';
import {
    Grid,
    List,
    ListItem,
    ListItemText,
    Container,
} from "@mui/material";
import "daterangepicker";
import {
    useDateRangePicker
} from "../CustomHooks";
import ToolbarSelect from "../components/ToolbarSelect";
import DataService from "../DataService";


function Summary(
    {
        settings,
        location,
        isVideo,
        isVisualSearch,
    }
) {

    const SELECT_PRECISION = [
        "ALL", "0.4", "0.6", "0.8", "1",
    ];
    const params = new URLSearchParams(location.search);
    const [vm, setVM] = useState({
        minScore: settings.minScore || 0.6,
        isVideo
    });
    const [logosDetected, setLogosDetected] = useState({count: [], dateEntered: []});
    const [prevLogosDetected, setPrevLogosDetected] = useState({count: []});

    const {
        startDate,
        endDate,
        selectedDateRange
    } = useDateRangePicker(params, settings);


    /***************************
     * Graph and counter cards *
     ***************************/

    useEffect(() => {
        const periodDuration = moment.duration(endDate - startDate).asDays() + 1;
        const prevStartDate = startDate.clone().subtract(periodDuration, "days");


        function generateChart(imagesUploadedDaily, dets) {
            const secondAxeName = vm.isVideo ? "Media duration" : "Detections";

            const daysToShow = endDate.diff(startDate, "days") + 1;

            const imagesUploadedDailyNoGaps = {
                date: Array(daysToShow),
                count: Array(daysToShow),
                detected: Array(daysToShow)
            };

            for (var x = 0; x < daysToShow; x++) {
                const date = startDate
                    .clone()
                    .add(x, "days")
                    .format("YYYY-MM-DD");
                const count = imagesUploadedDaily.count[imagesUploadedDaily.date.indexOf(date)];

                imagesUploadedDailyNoGaps.date[x] = date;
                imagesUploadedDailyNoGaps.count[x] = count ? parseInt(count) : 0;
                const detsDate = dets.date ? dets.date : dets.dateEntered
                const foundDate = detsDate.findIndex(d => d === date)
                if (foundDate !== -1) {
                    imagesUploadedDailyNoGaps.detected[x] = !!dets ? parseInt(dets.count[foundDate]) : 0;
                } else {
                    imagesUploadedDailyNoGaps.detected[x] = 0
                }
            }

            imagesUploadedDailyNoGaps.date.unshift("x");
            imagesUploadedDailyNoGaps.count.unshift("Media submitted");
            imagesUploadedDailyNoGaps.detected.unshift(secondAxeName);

            const config = {
                data: {
                    bindto: "#chart",
                    x: "x",
                    columns: [
                        imagesUploadedDailyNoGaps.date,
                        imagesUploadedDailyNoGaps.count
                    ],
                    axes: {
                        "Media submitted": "y"
                    }
                },
                axis: {
                    x: {
                        type: "timeseries",
                        tick: {
                            format: "%Y-%m-%d"
                        }
                    },
                    y: {
                        min: 0,
                        padding: {
                            top: 10,
                            bottom: 10
                        }
                    }
                }
            };

            if (isVisualSearch === false) {
                config.data.columns.push(imagesUploadedDailyNoGaps.detected);
                config.axis.y2 = {
                    min: 0,
                    show: true,
                    padding: {
                        top: 10,
                        bottom: 10
                    }
                };
                config.data.axes[secondAxeName] = "y2";
            }

            c3.generate(config);
        }


        const fetchData = async () => {
            const response = await DataService.getLimits(prevStartDate, endDate);

            const countCurrent = [];
            const dateCurrent = [];
            const countPrev = [];
            const datePrev = [];
            const duration = {};

            let durationCurrent = 0;
            let durationPrev = 0;

            if (response.data["POST/detect"].usage) {
                const usage = response.data["POST/detect"].usage.filter(
                    u => u.statusCode === 200 || u.statusCode === 202
                );
                usage.forEach(u => {
                    u.timeBegin = moment(u.timeBegin, "YYYY-MM-DD HH-mm-ss");
                    const month = u.timeBegin.format("MM"),
                        day = u.timeBegin.format("DD"),
                        year = u.timeBegin.format("YYYY");
                    if (u.timeBegin >= startDate) {
                        countCurrent.push(u.count);
                        dateCurrent.push([year, month, day].join("-"));

                        // Only for videos
                        if (u.duration) {
                            // To design the chart
                            const key = year + "-" + month + "-" + day;
                            if (!duration[key]) {
                                duration[key] = 0;
                            }
                            duration[key] += u.duration.time;

                            // For the difference count
                            durationCurrent += u.duration.time;
                        }
                    } else {
                        countPrev.push(u.count);
                        datePrev.push([year, month, day].join("-"));

                        // Only for videos
                        if (u.duration) {
                            durationPrev += u.duration.time;
                        }
                    }
                });
            }

            const logosDetectedCount = logosDetected.count.reduce((a, b) => {
                return a + b;
            }, 0);

            const prevLogosDetectedCount = prevLogosDetected.count.reduce((a, b) => {
                return a + b;
            }, 0);

            let imagesUploadedDaily = {
                count: countCurrent,
                date: dateCurrent
            };
            const imagesReceivedCount = imagesUploadedDaily.count.reduce((a, b) => {
                return a + b;
            }, 0);

            const durationChart = {
                date: [],
                count: []
            };

            let d;
            for (d in duration) {
                if (duration.hasOwnProperty(d)) {
                    durationChart.date.push(d);
                    durationChart.count.push(duration[d]);
                }
            }

            const durationCountSeconds = durationChart.count.reduce(function (a, b) {
                return a + b;
            }, 0);

            const durationCount = (durationCountSeconds / 60 / 60).toFixed(1); // In hours

            let durationChange;
            if (vm.isVideo) {
                durationChange =
                    ((durationCurrent - durationPrev) / durationPrev) * 100;
                generateChart(imagesUploadedDaily, durationChart);
            } else {
                generateChart(imagesUploadedDaily, logosDetected);
            }


            imagesUploadedDaily = {
                count: countPrev,
                date: datePrev
            };
            var prevImagesReceivedCount = imagesUploadedDaily.count.reduce(
                (a, b) => a + b,
                0
            );

            const receivedChange =
                ((imagesReceivedCount - prevImagesReceivedCount) /
                    prevImagesReceivedCount) *
                100;

            const detectedChange =
                ((logosDetectedCount - prevLogosDetectedCount) /
                    prevLogosDetectedCount) *
                100;

            setVM(prevState => ({
                ...prevState,
                receivedChange,
                detectedChange,
                durationCount,
                durationChange,
                imagesReceivedCount,
                logosDetectedCount,
            }));
        };

        fetchData().catch(e => console.error(e));
    }, [startDate, endDate, vm.minScore, logosDetected]);


    /******************
     * TOP TEN BRANDS *
     ******************/

    useEffect(() => {
        if (isVisualSearch) {
            return;
        }

        DataService.getSummaryLimits(
            startDate,
            endDate,
            (vm.minScore === 'ALL' || vm.minScore === 'All') ? null : vm.minScore,
        ).then(({data}) => {
            setPrevLogosDetected({
                count: data.imagesUploadedDailyPreviousPeriod.map((data) => data.count)
            });

            setLogosDetected({
                count: data.imagesUploadedDailyCurrentPeriod.map((data) => data.count),
                dateEntered: data.imagesUploadedDailyCurrentPeriod.map((data) => data.dateEntered),
            });

            if (!data) {
                setVM(prevState => ({...prevState, topBrands: null}));
                return;
            }

            setVM(prevState => ({
                ...prevState,
                activeLogoCount: data.brandsCountCurrentPeriod,
                activeLogoChange: data.brandsCountCurrentPeriod - data.brandsCountPreviousPeriod,
                topBrands: {
                    brand_id: data.brandDetectionCount.map(({brand}) => brand.id),
                    count: data.brandDetectionCount.map(({count}) => count),
                    names: data.brandDetectionCount.map(({brand}) => brand.name),
                }
            }));
        })
            .catch(err => console.error({err}));
    }, [startDate, endDate, vm.minScore]);

    /**
     * Keep in syc developer Meta
     */
    useEffect(() => {
        DataService
            .updateDeveloperMeta({
                minScore: vm.minScore
            })
            .catch(err => console.error(err))
        ;
    }, [vm.minScore]);

    useEffect(() => {
        if (isVisualSearch === false) {
            return
        }

        Promise.all([
            DataService
                .getItems(
                    {dateEnd: startDate.format("YYYY-MM-DD")}
                ),
            DataService
                .getItems(
                    {dateEnd: endDate.format("YYYY-MM-DD")}
                ),
        ]).then(
            ([startRes, endRes]) => {
                const endDateItemCount = endRes.headers["x-total-count"];
                const startDateItemCount = startRes.headers["x-total-count"];
                const itemCountChange =
                    ((endDateItemCount - startDateItemCount) /
                        startDateItemCount) *
                    100;

                setVM(prevState => ({
                    ...prevState,
                    endDateItemCount,
                    itemCountChange
                }));
            })
            .catch(err => console.error);
    }, [startDate, endDate])


    return (
        <>
            <div style={{marginBottom: '2rem'}}>
                <Toolbar>
                    <Grid
                        container={true}
                        spacing={2}
                        alignItems={'center'}
                        sx={{
                            p: 1,
                        }}
                    >
                        {/* Date Range Item */}
                        <Grid item>
                            <label className={'visuaLabel'}>
                                Date range
                            </label>
                        </Grid>
                        <Grid item>
                            <div lg-range-selector="" className="form-control">
                                {selectedDateRange}
                            </div>
                        </Grid>

                        {!vm.isVideo && (
                            <>
                                <Grid item>
                                    <label style={{
                                        marginBottom: 0,
                                        fontSize: '12px',
                                        color: '#4D6782'
                                    }}>
                                        Confidence
                                    </label>
                                </Grid>
                                <Grid item>
                                    <ToolbarSelect
                                        inputId='toolbarSelect'
                                        initialState={vm.minScore}
                                        options={SELECT_PRECISION}
                                        onChange={
                                            value => setVM({...vm, minScore: value})
                                        }
                                    />
                                </Grid>
                            </>
                        )}


                    </Grid>
                </Toolbar>
            </div>
            <Container maxWidth={'xl'}>
                <Grid
                    container={true}
                    spacing={2}
                >
                    <Grid
                        item={true}
                        xs={(!vm.isVideo && !isVisualSearch) ? 9 : 12}
                    >
                        <Card
                            headerTitle={'Usage'}
                            headerTooltip={"Volume of media submitted and number of detections in the media submitted in the selected time period."}
                        >
                            <div id="chart"/>
                        </Card>

                        <Grid
                            container={true}
                            spacing={2}
                            sx={{mt: 2}}
                            className={'card-row'}
                        >
                            <Grid item={true} xs={4} className={'d-flex'}>
                                <StatCard
                                    statName={"Media submitted"}
                                    tooltip={"Total number of media submitted in the selected time period - and percentage change from previous period"}
                                    statValue={vm.imagesReceivedCount}
                                    changeValue={isFinite(vm.receivedChange) ? vm.receivedChange : 0}
                                    loading={isNaN(vm.imagesReceivedCount)}
                                />
                            </Grid>
                            <Grid item={true} xs={4} className={'d-flex'}>
                                {vm.isVideo
                                    ? <StatCard
                                        statName={"Media duration"}
                                        tooltip={"Media duration in the selected time period - and percentage change from previous period"}
                                        statValue={vm.durationCount}
                                        changeValue={isFinite(vm["durationChange"]) ? vm["durationChange"] : 0}
                                        loading={isNaN(vm.durationCount)}
                                    />
                                    :
                                    <StatCard
                                        statName={"Detections"}
                                        tooltip={"Total number of detections in the selected time period - and percentage change from previous period"}
                                        statValue={vm.logosDetectedCount}
                                        changeValue={isFinite(vm["detectedChange"]) ? vm["detectedChange"] : 0}
                                        loading={isNaN(vm.logosDetectedCount)}
                                    />
                                }
                            </Grid>
                            {/** Reference Images */}
                            <Grid item={true} xs={4} className={'d-flex'}>
                                {isVisualSearch
                                    ? <StatCard
                                        statName={"Reference Images"}
                                        tooltip={"Total number of reference images active in the selected time period - and numerical change from previous period"}
                                        statValue={vm.endDateItemCount}
                                        changeValue={isFinite(vm.itemCountChange) ? vm.itemCountChange : 0}
                                        loading={isNaN(vm.endDateItemCount)}
                                    />
                                    :
                                    <StatCard
                                        statName={"Brands Active"}
                                        tooltip={"Total number of brands active in the selected time period - and numerical change from previous period"}
                                        statValue={vm.activeLogoCount}
                                        changeValue={isFinite(vm.activeLogoChange) ? vm.activeLogoChange : 0}
                                        loading={isNaN(vm.activeLogoCount)}
                                    />
                                }
                            </Grid>

                        </Grid>

                    </Grid>
                    {!vm.isVideo && !isVisualSearch && (
                        <Grid
                            item={true}
                            xs={3}
                        >
                            <Card
                                headerTitle={"Top 10 Brands"}
                                headerTooltip={"Ranking of top 10 most-detected brands in the selected time period: from highest to lowest by volume of logos"}
                                loading={!vm.topBrands}
                            >
                                <List>
                                    {vm.topBrands &&
                                        vm.topBrands.names.map((brandName, index) => (
                                            <ListItem
                                                key={brandName}
                                                sx={{py: 0}}
                                            >
                                                <ListItemText
                                                    primary={
                                                        <Grid
                                                            container={true}
                                                            justifyContent={'space-between'}
                                                            alignItems={'center'}
                                                        >
                                                            <Grid item sx={{fontWeight: 'bold', fontSize: '1.1rem'}}
                                                                  xs={9}>
                                                                {index + 1} {brandName}
                                                            </Grid>
                                                            <Grid item sx={{textAlign: 'right'}} x={3}>
                                                                {vm.topBrands.count[index].toLocaleString()}
                                                            </Grid>
                                                        </Grid>
                                                    }
                                                    sx={{

                                                        ".MuiListItemText-secondary": {
                                                            textAlign: 'right'
                                                        }
                                                    }}
                                                />
                                            </ListItem>
                                        ))}
                                </List>
                            </Card>
                        </Grid>
                    )}

                </Grid>
            </Container>
        </>
    )
}


export default Summary;
