import * as React from 'react';

function Label(
    {
        children,
        fullWidth,
    }) {
    return (
        <label style={{
            marginBottom: 0,
            fontSize: '12px',
            color: '#4D6782',
            width: fullWidth ? "100%" : 'auto',
        }}>
            {children}
        </label>
    )
}

export default Label;
