import React, { useState } from 'react';
import { Button } from '@mui/material';
import { Search } from '@mui/icons-material';

const SearchBar = ({ value = '', placeholder, onSearch }) => {
    const [searchValue, setSearchValue] = useState(value);

    return (
        <div
            style={{
                display: 'flex',
                alignItems: 'center'
            }}
        >
            <input
                value={searchValue}
                aria-label="Search"
                placeholder={placeholder ?? 'Search...'}
                onChange={(e) => setSearchValue(e.target.value)}
                style={{
                    height: '40px',
                    width: '20ch',
                    position: 'relative',
                    borderTopLeftRadius: '0.25rem',
                    borderBottomLeftRadius: '0.25rem',
                    padding: '0 8px',
                    outline: 'none',
                    boxShadow: '0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -1px rgba(0, 0, 0, 0.05)',
                    backgroundColor: '#f1f5f9',
                    border: "1px solid #f2f2f2",
                }}
            />
            <Button
                variant="contained"
                color="primary"
                style={{
                    height: '40px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0
                }}
                onClick={() => {
                    onSearch(searchValue);
                }}
            >
                <Search />
            </Button>
        </div>
    );
};

export default SearchBar;
