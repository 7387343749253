import * as React from 'react';
import {useEffect, useState} from "react";

import {
    Alert,
    Grid,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    InputAdornment,
    IconButton,
    OutlinedInput, InputLabel,
    FormControl,
    Tabs,
    Tab,
    Tooltip
} from '@mui/material'
import UndoIcon from '@mui/icons-material/Undo';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import {Toolbar} from 'visuaui';
import TagTable from "./TagTable";
import TabPanel from "../TabPanel";
import './EditBrandModal.css';
import ItemTagManager from "../ItemTagManager";
import CloseIcon from "@mui/icons-material/Close";
import BrandTextManager from "../BrandTextManager";

export default function EditBrandModal(
    {
        open,
        item,
        imgIndex,
        handleClose,
        activateBrand,
        toggleLogoVersionState,
        updateMeta,
        updateItemData,
        showTextTab,
    }) {
    const BRAND_LEVEL_INDEX = -1;

    const [tabVal, setTabVal] = useState(0);
    const [areTagsDifferent, setAreTagsDifferent] = useState(false);
    const [brandName, setBrandName] = useState(item ? item.name : '');

    const [imgBG, setImgBG] = useState('light');
    const [imgInfo, setImgInfo] = useState({
        id: BRAND_LEVEL_INDEX,
        iconUrl: '',
        precision: null,
        status: null,
        timeCreated: '',
        name: '',
    });
    const [editableState, setEditableState] = useState(true);
    const [meta, setMeta] = useState({});

    const resetName = () => {
        /**
         *  originalName,
         *  brandName,
         *  item.name,
         */
        setBrandName(item.name);
    }
    const saveEdit = () => {
        handleClose();
        if (tabVal === 0) {
            const newMeta = {...meta};
            const isResettingTheName = brandName === item.name;
            if (isResettingTheName) {
                delete newMeta['.name'];
            } else {
                newMeta['.name'] = brandName;
            }

            if (imgIndex === BRAND_LEVEL_INDEX) {
                 updateMeta(
                    newMeta,
                    item.id,
                );
            } else {
                // Logo Level
                return updateMeta(
                    newMeta,
                    item.id,
                    item.logoVersionArray[imgIndex]
                );
            }


        }

        if (tabVal === 1) {
            updateMeta(meta, item.id, imgIndex !== -BRAND_LEVEL_INDEX? item.logoVersionArray[imgIndex] : null);
        }

    }

    const handleChangeBrandName = (event) => {
        setBrandName(event.target.value)
    }

    useEffect(_ => {
        if (item && open) {
            console.log({item})
            setImgInfo({
                id: BRAND_LEVEL_INDEX,
                iconUrl: '',
                precision: null,
                status: null,
                timeCreated: '',
                name: '',
            });
            setMeta({})
            setTabVal(0);
            setImgBG('light')
            setBrandName(item.name);
            setAreTagsDifferent(false);
            setEditableState(true);

            if (
                imgIndex >= 0
                && item.logoVersionArray
            ) {
                if (item.logoVersionArray.length > imgIndex) {
                    const {
                        id,
                        iconUrl,
                        meta,
                        name,
                        precision,
                        status,
                        timeCreated,
                    } = item.logoVersionArray[imgIndex];

                    console.log(item.logoVersionArray[imgIndex])

                    setImgInfo({
                        id,
                        iconUrl,
                        precision,
                        status,
                        timeCreated,
                        name,
                    })

                    if (status !== 1) setEditableState(false);

                    if (meta) {
                        setMeta(meta);
                        if (meta['.name']) {
                            setBrandName(meta['.name']);
                        }
                    } else {
                        setBrandName(item.computedName);
                    }
                } else {
                    console.error('Invalid img index')
                }
            } else {
                /****
                 * Global Brand Setting
                 */

                const {logoVersionArray} = item;
                console.log({logoVersionArray})
                const numLogos = logoVersionArray.length;
                let allSameTags = true;
                const firstLogoMeta = logoVersionArray[0].meta?? {};
                const firstLogoMetaNumber = Object.keys(firstLogoMeta).length;
                if (numLogos > 1) {
                    logoVersionArray.forEach(
                        logo => {
                            if (!allSameTags) return;
                            const currMeta = logo.meta?? {};
                            if (
                                Object.keys(currMeta).length
                                !== firstLogoMetaNumber
                            )  return allSameTags = false;

                            Object.keys(firstLogoMeta).forEach(key => {
                                const value = firstLogoMeta[key];
                                if (!!currMeta[key]) {
                                    if (currMeta[key] !== value) {
                                        return allSameTags = false;
                                    }
                                } else {
                                    return allSameTags = false;
                                }
                            })
                        }
                    )

                    setAreTagsDifferent(!allSameTags);
                }

                setMeta(allSameTags? firstLogoMeta : {});
                if (firstLogoMeta.hasOwnProperty('.name')) {
                    setBrandName(firstLogoMeta['.name']);
                }

                const isLoading = !!logoVersionArray.find(
                    logoV => logoV.status === -1
                )
                const isInactive = !!logoVersionArray.find(
                    logoV => logoV.status === 0
                )

                setEditableState(
                    !(
                        isLoading || isInactive
                    ) && allSameTags
                );
                console.log({isLoading, isInactive, allSameTags})

                setImgInfo({
                    id: BRAND_LEVEL_INDEX,
                    iconUrl: '',
                    precision: null,
                    status: isLoading? -1 : isInactive? 0 : 1,
                    timeCreated: '',
                    name: item.name,
                });

            }
        }
    }, [open])

    return (
        <Dialog
            maxWidth={'md'}
            fullWidth
            open={open}
            keepMounted
            onClose={handleClose}
            aria-describedby="Edit Brand Modal"
            sx={{
                minWidth: '600px',
            }}
        >
            <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                {
                    (imgIndex === BRAND_LEVEL_INDEX) ? <span>Edit Brand</span> : <span>Edit Logo Version</span>
                }
                <IconButton
                    onClick={handleClose}
                    sx={{ml: 'auto'}}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <Toolbar>
                <Tabs value={tabVal} onChange={(event, newValue) => setTabVal(newValue)}>
                    <Tab
                        value={0}
                        label={'Info'}
                    />
                    <Tab
                        value={1}
                        label={'Tags'}
                        disabled={!editableState}
                    />
                    {
                        (
                            imgInfo.id === BRAND_LEVEL_INDEX
                            && showTextTab
                        ) &&
                        <Tab
                            value={2}
                            label={'Text'}
                            disabled={!item?.logoVersionArray.some(
                                logoV => logoV.status === 1
                            )}
                        />
                    }

                </Tabs>
            </Toolbar>
            <DialogContent
                sx={{
                    py: 2,
                }}
            >
                <TabPanel value={tabVal} index={0}>
                    {
                        areTagsDifferent &&
                        <Alert severity="warning" sx={{mb: 3}}>
                            All logo versions must contain the same tags and the same brand name to
                            apply a new brand tag or a new brand name.
                        </Alert>
                    }
                    <Grid container>
                        <Grid item xs={12}>
                            <FormControl sx={{width: '100%'}}>
                                <InputLabel htmlFor="changeBrandName--input">
                                    Brand Name
                                </InputLabel>
                                <OutlinedInput
                                    id={"changeBrandName--input"}
                                    value={brandName}
                                    onChange={handleChangeBrandName}
                                    fullWidth={true}
                                    label="Brand Name"
                                    variant="outlined"
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <Tooltip title={'Reset Brand name'}>
                                                <IconButton
                                                    aria-label="Reset Brand Name"
                                                    edge="end"
                                                    onClick={resetName}
                                                >
                                                    <UndoIcon/>
                                                </IconButton>
                                            </Tooltip>
                                        </InputAdornment>
                                    }
                                />
                            </FormControl>
                        </Grid>
                    </Grid>
                    <Grid container>
                        <Grid item xs={6} sx={{mt: 1}}>
                            {
                                (item && item.id) &&

                                (
                                    <>
                                        <b>Brand ID</b>: <code>{item.id}</code>
                                    </>
                                )
                            }
                            {
                                imgIndex !== BRAND_LEVEL_INDEX &&
                                <>
                                    <div>
                                        <b>Logo Version ID</b>: <code> {imgInfo.id} </code>
                                    </div>
                                    <div>
                                        <b>Precision</b>: <code> {imgInfo.precision}</code>
                                    </div>
                                    <div>
                                        <b>Date added</b>: <code> {imgInfo.timeCreated}</code>
                                    </div>
                                </>
                            }
                        </Grid>

                        {
                            (imgIndex !== BRAND_LEVEL_INDEX && imgInfo.iconUrl) &&
                            <Grid item xs={6} sx={{mt: 1}} textAlign={'right'}>
                                <div className={`modalBrandImage--imageContainer ${imgBG}`}>
                                    <div className={`invertBackgroundBtn--container`}>
                                        <Tooltip title={'Change Background color'}>
                                            <IconButton onClick={_ => {
                                                if (imgBG === 'light') setImgBG('dark');
                                                else setImgBG('light')
                                            }
                                            }>
                                                <ChangeCircleIcon/>
                                            </IconButton>
                                        </Tooltip>
                                    </div>


                                    <img
                                        src={imgInfo.iconUrl}
                                        alt={`${item.name} version ${imgIndex}`}
                                    />
                                </div>
                            </Grid>
                        }
                    </Grid>
                </TabPanel>
                <TabPanel value={tabVal} index={1}>
                    {
                        areTagsDifferent &&
                        <Alert severity="warning" sx={{mb: 1}}>
                            All logo versions must contain the same tags and the same brand name to
                            apply a new brand tag or a new brand name.
                        </Alert>
                    }
                    <ItemTagManager
                        logoTags={meta}
                        setLogoTags={setMeta}
                    />
                </TabPanel>
                <TabPanel value={tabVal} index={2}>
                    <BrandTextManager
                        brand={item}
                        setItems={(textData) => {
                            const itemCopy = {
                                ...item
                            };
                            itemCopy['items'] = textData;
                            updateItemData(itemCopy)
                        }}
                    />
                </TabPanel>
            </DialogContent>
            {
                tabVal !== 2 &&
                <DialogActions>
                    <Button
                        variant={'contained'}
                        onClick={saveEdit}
                        disabled={!editableState || imgInfo.status !== 1}
                    >
                        {
                            tabVal === 0 ? "Save" : "Apply tag changes"
                        }
                    </Button>
                    <Button
                        variant={'contained'}
                        onClick={_ => {
                            if (imgIndex === BRAND_LEVEL_INDEX ) {
                                activateBrand(imgInfo.status !== 1, item)
                            } else {
                                toggleLogoVersionState(imgInfo.status !== 1, item,  item.logoVersionArray[imgIndex])
                            }
                            handleClose();
                        }}
                        color={imgInfo.status === 1? 'info' : 'success'}
                    >
                        {imgInfo.status === 1? "Disable" : "Enable"} {imgIndex === BRAND_LEVEL_INDEX ? 'Brand' : 'Logo Version'}
                    </Button>
                </DialogActions>
            }

        </Dialog>
    );
}
