import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Stack,
} from '@mui/material'


import DeleteIcon from "@mui/icons-material/Delete";
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ImageIcon from '@mui/icons-material/Image';
import LinkIcon from '@mui/icons-material/Link';
import ElectricBoltIcon from '@mui/icons-material/ElectricBolt';
import FlashOffIcon from '@mui/icons-material/FlashOff';
import * as React from "react";
import Box from "@mui/material/Box";

const AddBrandsSummaryTable = ({rows, handleRemoveItem, type, brandIdsInUserLibrary}) => {
    return (
        <Table
            sx={{minWidth: 600, maxWidth: 1024}}
            aria-label="Brands Added Table" size="small">
            <TableHead>
                <TableRow>
                    <TableCell
                        style={{width: "10%"}}
                    >
                        #
                    </TableCell>
                    <TableCell
                        style={{width: "40%"}}
                    >
                        {type} Name
                    </TableCell>
                    <TableCell
                        style={{width: "30%"}}
                    >
                        {type} Url
                    </TableCell>
                    <TableCell
                        style={{width: "5%"}}
                    >
                    </TableCell>
                    <TableCell
                        align={'center'}
                        style={{width: "5%"}}
                    >
                        Remove
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow
                        key={index}
                    >
                        <TableCell
                            style={{width: "10%"}}
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell
                            style={{width: "40%"}}
                        >
                            <Tooltip
                                title={(row.name.length > 45) ? row.name : ''}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'clip',
                                        width: '100%'
                                    }}
                                >
                                    {row.name.slice(0, 45)}{(row.name.length > 45) ? "..." : ''}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            style={{width: "30%"}}
                        >
                            <Tooltip
                                title={(row.url.length > 40) ? row.url : ''}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'clip',
                                        width: '100%'
                                    }}
                                >
                                    {row.url.slice(0, 40)}{(row.url.length > 40) ? "..." : ''}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell>
                            <Stack
                                direction="row"
                                alignItems={'center'}
                            >
                                {
                                    !!row.brandId &&
                                    <Tooltip
                                        title={
                                        <Box sx={{
                                            m: 0,
                                            p: 0,
                                            "> *": {
                                                m: 0,
                                                p: 0,
                                            }
                                        }}>
                                            {
                                                brandIdsInUserLibrary.includes(row.brandId)
                                                    ? (!row.exampleUrls || row.exampleUrls?.length === 0)
                                                        ? "Direct Activation"
                                                        : <p>Example Image/s Found. <br/>Direct Activation not available</p>
                                                    : <p>This brand id is not included in your brand library. <br/>Direct
                                                        Activation not available</p>
                                            }
                                        </Box>
                                        }
                                    >
                                        {
                                            (
                                                brandIdsInUserLibrary.includes(row.brandId)
                                                && (!row.exampleUrls || row.exampleUrls?.length === 0)
                                            )?
                                                <ElectricBoltIcon
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: '#88BF40'
                                                    }}
                                                />
                                                :
                                                <FlashOffIcon
                                                    sx={{
                                                        fontSize: '14px',
                                                        color: brandIdsInUserLibrary.includes(row.brandId) ?
                                                            "#F1BE48" : "red"
                                                    }}
                                                />
                                        }


                                    </Tooltip>
                                }
                                {
                                    (row.meta)
                                    &&
                                    <Tooltip
                                        title={`The brand has ${Object.keys(row.meta).length} tag/s`}
                                    >
                                        <LocalOfferIcon
                                            sx={{
                                                fontSize: '14px',
                                                color: '#8092A6'
                                            }}
                                        />
                                    </Tooltip>
                                }

                                {
                                    (
                                        (row.exampleUrls && row.exampleUrls.length > 0)
                                    )
                                    &&
                                    <Tooltip
                                        title={`The brand has ${row.exampleUrls.length} example url/s`}
                                    >
                                        <LinkIcon
                                            sx={{fontSize: '14px', color: '#8092A6'}}
                                        />
                                    </Tooltip>
                                }

                                {
                                    (
                                        (row.exampleImagesBase64 && row.exampleImagesBase64.length > 0)
                                    )
                                    &&
                                    <Tooltip
                                        title={`The brand has ${row.exampleImagesBase64.length} example images`}
                                    >
                                        <ImageIcon
                                            sx={{fontSize: '14px', color: '#8092A6'}}
                                        />
                                    </Tooltip>
                                }

                            </Stack>
                        </TableCell>
                        <TableCell
                            align={'center'}
                            style={{width: "5%"}}
                        >
                            <IconButton onClick={_ => handleRemoveItem(index)}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default AddBrandsSummaryTable;
