import React, {useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    List,
    Grid,
    ListItem,
    ListItemText,
    IconButton, Snackbar, Alert,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';

export default function VideoQADataModal({item, open, handleClose}) {
    const {
        qa,
        timeCreated,
    } = item;
    let info = []
    if (qa) {
        const {chunks} = qa;
        info = chunks;
    }

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'sm'}
                fullWidth={true}
            >
                <DialogTitle sx={{display: 'flex'}}>
                    QA Status
                    <IconButton sx={{ml: 'auto'}} onClick={_ => handleClose()}>
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{pb: '5px'}}>
                    <Grid container justifyContent={'space-between'}>
                        <Grid item>
                            <b>
                                Chunk Title
                            </b>
                            </Grid>
                        <Grid item>
                            <b>
                                Status
                            </b>
                        </Grid>
                    </Grid>
                    <List dense={true}>
                        {
                            info.map(chunk =>
                                <ListItem>
                                   <ListItemText primary={
                                       <Grid container={true} justifyContent={'space-between'}>
                                           <Grid item>
                                               <a href={chunk.url} target={'_blank'}>
                                                   {chunk.hashId}
                                               </a>
                                           </Grid>
                                           <Grid item>
                                               {chunk.status}
                                           </Grid>
                                       </Grid>
                                   }/>
                                </ListItem>
                               )
                           }
                    </List>
                </DialogContent>
                <DialogActions sx={{
                    p: '5px 24px'
                }}>
                    <span>{timeCreated}</span>
                </DialogActions>
            </Dialog>
        </>
    )
}
