import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    IconButton,
    DialogContent,
    Typography,
    DialogActions,
    Tooltip,
    Grid,
    CircularProgress,
    Box,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import {styled} from "@mui/system";
import Chip from "@mui/material/Chip";
import ErrorIcon from '@mui/icons-material/Error';
import InfoIcon from '@mui/icons-material/Info';

const StyleChip = styled(
    (props) => (
        <Chip
            {...props}
        />
    )
)({
    fontSize: '12px',
    fontWeight: 'bold',
    margin: "5px",
});

function ActivationLogContent({items}) {
    return (
        <div>
            {
                items.map((item, index) => (
                    <Grid container={true} key={item.requestHash} sx={{mb: 2}}>
                        <Grid container={true} justifyContent={'space-between'}
                              sx={{
                                  background: '#EEE',
                                  p: 1,
                              }}
                        >
                            <Grid item>
                                <b>{item.requestHash}</b>
                            </Grid>
                            <Grid item>

                                {item.status === "Completed" && (
                                    <Tooltip title={'Completed'}>
                                        <CircularProgress
                                            size={15}
                                            sx={{
                                                mr: 1,
                                            }}
                                            variant="determinate"
                                            value={100}
                                            color={'success'}
                                        /></Tooltip>

                                )}

                                {item.status === "In progess" && (
                                    <Tooltip title={'In progess'}>
                                        <CircularProgress
                                            size={15}
                                            sx={{
                                                mr: 1,
                                            }}
                                        />
                                    </Tooltip>
                                )}

                                {item.status === "Rejected" && (
                                    <Tooltip title={'Rejected'}>
                                        <ErrorIcon
                                            fontSize={'small'}
                                            color={'error'}
                                            sx={{mr: 1,}}
                                        />
                                    </Tooltip>
                                )}
                                Requested {item.dateEntered}
                            </Grid>
                        </Grid>
                        <Grid container={true} sx={{p: 1}}>
                            {item.brands.map((brand, index) => (
                                <StyleChip
                                    key={index}
                                    color={'error'}
                                    size={'small'}
                                    label={brand.name}
                                />
                            ))}

                        </Grid>
                    </Grid>
                ))
            }
        </div>
    )
}

function ActivationLogModal(
    {
        open,
        handleClose,
        items = [],
    }
) {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span>Activation log</span>
                    <IconButton
                        onClick={handleClose}
                        sx={{ml: 'auto'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    {
                        items.length === 0
                            ? <Typography> No items in queue </Typography>
                            : <ActivationLogContent items={items}/>
                    }


                </DialogContent>
                <Box
                    sx={{
                        borderTop: '1px solid #ccc',
                        p: 2,
                        fontSize: '10px',
                    }}
                >
                    <InfoIcon sx={{fontSize: '14px', color: '#8092A6'}}/>
                    Brands will become activate within your contracted time period.
                </Box>
            </Dialog>
        </>
    )
}

ActivationLogModal.propTypes = {}

ActivationLogModal.defaultProps = {}

export default ActivationLogModal;
