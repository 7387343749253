import React from 'react';
import {
    Snackbar,
    Alert
} from "@mui/material";
import PropTypes from 'prop-types';

function Toast(
    {
        open,
        handleClose,
        severity = 'success',
        message = '',
        autoHideDuration = 5000,
    }) {

    return (
        <Snackbar
            open={open}
            autoHideDuration={autoHideDuration === 0? null : autoHideDuration}
            onClose={handleClose}
            anchorOrigin={
                {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
        >
            <Alert onClose={handleClose} severity={severity} sx={{width: '100%'}}>
                {message}
            </Alert>
        </Snackbar>
    )
}

Toast.propTypes = {
    open: PropTypes.bool.isRequired,
    handleClose: PropTypes.func.isRequired,
    severity: PropTypes.string,
    message: PropTypes.string,
    autoHideDuration: PropTypes.number,
}

export default Toast;
