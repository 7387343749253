import * as React from 'react';
import {
    Card,
    Button,
    Box,
} from '@mui/material';
import {useState} from "react";
import ToolbarControllers from "./ToolbarControllers";
import ExploreTable from "./ExploreTable";
import {Toolbar} from "visuaui";
import DetectionImageModal from "../DetectionImageModal";
import {Download} from "@mui/icons-material";
import CircularProgress from '@mui/material/CircularProgress';

function TableCard(
    {
        editors,
        filters,
        changeConfidence,
        changeText,
        changeTextField,
        changeReviewStatus,
        changeReviewer,
        changeFilterOption,
        rowState,
        setRowState,
        metaCols,
        exportFeed,
        isExporting,
    }
) {
    const [openModal, setOpenModal] =useState(false);
    const [selectedItem, setSelectedItem] = useState();

    const closeModal = () => {
       setOpenModal(false);
    }

    const openImgModal = (id) => {
        const item = rowState.rows.find(el => el.sessionId === id);

        if (item) {
            setSelectedItem(item);
            setOpenModal(true);
        } else {
            console.error('item not found');
        }
    }

    const exportData = () => {

    }

    return (
        <>
            <Card>
                <Toolbar removeBackground={true} style={{padding: 0}}>
                    <ToolbarControllers
                        editors={editors}
                        filters={filters}
                        handleChangeConfidence={changeConfidence}
                        handleChangeText={changeText}
                        handleChangeSearchField={changeTextField}
                        handleReviewStatus={changeReviewStatus}
                        handleReviewer={changeReviewer}
                        changeFilterOption={changeFilterOption}
                        metaCols={metaCols}
                    />
                    <Button
                        onClick={
                            () => exportFeed()
                        }
                        variant={'contained'}
                        size={'small'}
                        disabled={isExporting}
                    >
                        {
                            isExporting ? (
                                <CircularProgress size={18} />
                            ) : (
                                <>
                                    <Download fontSize={'inherit'}/>
                                    Export
                                </>
                            )
                        }

                    </Button>
                </Toolbar>
                <ExploreTable
                    openModal={openImgModal}
                    rowState={rowState}
                    setRowState={setRowState}
                    metaCols={metaCols}
                />
            </Card>
            <DetectionImageModal
                open={openModal}
                handleClose={closeModal}
                feed={selectedItem}
            />
        </>
    )
}

TableCard.propTypes = {}

TableCard.defaultProps = {}

export default TableCard;
