import React, {useReducer, useState} from "react";
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Skeleton,
    Stack,
    Switch,
    Typography,
    TextField,
} from '@mui/material'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AddIcon from "@mui/icons-material/Add";
import CollapseTagCard from "../CollapseTagCard";
import InfoIcon from "@mui/icons-material/Info";
import CollapseExamplesCard from "../CollapseExamplesCard";

export default function AddBrandsModalForm(
    {
        addToMainList,
    }
) {



    const [formData, updateFormData] = useReducer(
        (prev, next) => {
            return {...prev, ...next}
        }, {
        name: '',
        url: '',
        examplesTypeIsFile: false,
        examples: [],
        tags: {},
    })

    function handleAddToMainList() {
        const {
            name,
            url,
            examplesTypeIsFile,
            examples,
            tags,
        } = formData

        const brand = {
            name: name,
            url: url,
        }

        if(examples.length > 0) {
            if (examplesTypeIsFile) {
                brand.exampleImagesBase64 = examples.map(el => el.base64);
            } else {
                brand.exampleUrls = examples;
            }
        }

        if (Object.keys(tags).length !== 0) {
            brand.meta = tags;
        }

       updateFormData(
           {
               name: '',
               url: '',
               examplesTypeIsFile: false,
               examples: [],
               tags: {},
           }
       )
        addToMainList(brand)
    }



    return (
        <Stack
            spacing={2}
            sx={{
                width: "100%",
                p: 1,
                position: 'sticky',
                top: 0,
            }}
        >
            <TextField
                id={'formAddBrands--Name'}
                label={`Brand Name*`}
                placeholder={'E.g. Coca Cola'}
                variant="standard"
                fullWidth
                value={formData.name}
                onChange={
                    e => {
                        updateFormData(
                            {name: e.target.value}
                        )
                    }
                }
            />

            <TextField
                id={'formAddBrands--url'}
                label={`Brand URL*`}
                placeholder={'E.g. https://coca-cola.com'}
                variant="standard"
                fullWidth
                value={formData.url}
                onChange={
                    e => {
                        updateFormData(
                            {url: e.target.value}
                        )
                    }
                }
            />
            <CollapseExamplesCard
                isAFile={formData.examplesTypeIsFile}
                setIsAFile={
                    val => updateFormData({examplesTypeIsFile : val})
                }
                examples={formData.examples}
                setExamples={
                    val => updateFormData(
                        {
                            examples: val
                        }
                    )
                }
            />
            <CollapseTagCard
                tags={formData.tags}
                setTags={newTags => updateFormData({tags: newTags})}
            />
            <Button
                variant={'contained'}
                fullWidth
                onClick={_ => {
                    return handleAddToMainList()
                }}
                sx={{
                    fontWeight: 'bold',
                }}
                disabled={
                    formData.name.length === 0 || formData.url.length === 0
                }
            >
                Add To List >>
            </Button>
            <Box
                sx={{
                    fontSize: '10px',
                    display: 'flex',
                    justifyContent: 'center',
                }}>
                <InfoIcon sx={{fontSize: '14px', color: '#8092A6', mr: 1,}}/>
                <span>
                Brands will become active within your contracted time period.
                </span>
            </Box>
        </Stack>
    )
}
