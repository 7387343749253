import { useState, useRef, useEffect } from "react";
import $ from "jquery";
import moment from "moment";
import DataService from "./DataService";
import ReactDOM from "react-dom";

/**
 * @template S
 * @param {S} initialValue
 */
export const useFormInput = initialValue => {
  /** @type {UseStateTuple<S>} */
  const [value, setValue] = useState(initialValue);
  const [isDirty, setDirty] = useState(false);

  return {
    value,
    setValue,
    isDirty,
    setDirty,
    bind: {
      value,
      onChange: e => {
        setValue(e.target.value);
        setDirty(true);
      }
    },
    clear: () => setValue("")
  };
};

// Create date range picker
export const useDateRangePicker = (params, settings) => {
  const [startDate, setStartDate] = useState(
    moment(
      params.get("startDate") ||
        settings.dateRange.begin ||
        moment()
          .subtract(1, "month")
          .startOf("month")
    )
  );
  const [endDate, setEndDate] = useState(
    moment(
      params.get("endDate") ||
        settings.dateRange.end ||
        moment()
          .subtract(1, "month")
          .endOf("month")
    )
  );

  const [selectedDateRange, setSelectedDateRange] = useState(
    startDate.format("MMM D, YYYY") + " - " + endDate.format("MMM D, YYYY")
  );

  $("[lg-range-selector]").daterangepicker(
    {
      startDate,
      endDate,
      ranges: {
        Today: [moment(), moment()],
        Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
        "Last 7 Days": [moment().subtract(6, "days"), moment()],
        "Last 30 Days": [moment().subtract(29, "days"), moment()],
        "This Month": [moment().startOf("month"), moment()],
        "Last Month": [
          moment()
            .subtract(1, "month")
            .startOf("month"),
          moment()
            .subtract(1, "month")
            .endOf("month")
        ]
      },
      maxDate: moment(),
      dateLimit: {
        months: 1
      }
    },
    (start, end) => {
      ReactDOM.unstable_batchedUpdates(() => {
        setStartDate(start);
        setEndDate(end);
      });
      setSelectedDateRange(
        start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY")
      );
      DataService.updateDeveloperMeta({
        dateRange: {
          begin: start.format("YYYY-MM-DD"),
          end: end.format("YYYY-MM-DD")
        }
      });
    }
  );

  return { startDate, endDate, selectedDateRange };
};

// https://dev.to/thekashey/the-same-useref-but-it-will-callback-8bo
export function useCallbackRef /*<T>*/(
  initialValue, //: T | null,
  callback //: (newValue: T | null, lastValue: T | null) => void
) {
  //: MutableRefObject<T | null>
  const [ref] = useState(() => ({
    // value
    value: initialValue,
    // last callback
    callback,
    // "memoized" public interface
    facade: {
      get current() {
        return ref.value;
      },
      set current(value) {
        const last = ref.value;
        if (last !== value) {
          ref.value = value;
          ref.callback(value, last);
        }
      }
    }
  }));
  // update callback
  ref.callback = callback;

  return ref.facade;
}

export function useDidUpdateEffect(fn, inputs) {
  const didMountRef = useRef(false);

  useEffect(() => {
    if (didMountRef.current)
      fn();
    else
      didMountRef.current = true;
  }, inputs);
}