import React, {useState, useEffect} from "react";
import {
    alpha,
    Card,
    CardContent,
    Grid,
    Button,
    FormControlLabel,
    Switch, Select, MenuItem
} from "@mui/material";
import TextTableToolbar from "./TextTableToolbar";
import TextTable from "../TextTable";

export default function TextTableCard({
    loading,
    setLoading,
    setItems,
    items,
    openAddModal,
    isAddBlackListed,
}) {
    const SELECT_FILTER_OPTION = ['All', 'Active', 'Disabled'];
    const [renderedItems, setRenderItems] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        text: '',
        select: SELECT_FILTER_OPTION[0],
    })

    const searchText = (text) => {
        setFilterOptions(
            prevState => {
                return {
                    ...prevState,
                    text,
                }
            }
        )
    }

    const activationFilter = (status) => {
        setFilterOptions(
            prevState => {
                return {
                    ...prevState,
                    select: status,
                }
            }
        )
    }

    const filterMainFunction = () => {
        // don't run filter function if there are
        // no elements yet
        if (items.length === 0){
            return;
        }

        let filteredElements = [];
        const {
            select,
            text,
        } = filterOptions;

        // Select Filter
        switch (select){
            case SELECT_FILTER_OPTION[1]:
                filteredElements = items.filter(
                    item =>
                        item.status === 1
                )
                break;
            case SELECT_FILTER_OPTION[2]:
                filteredElements = items.filter(
                    item =>
                        item.status === 0
                )
                break;
            default:
                filteredElements = items;
                break;
        }

        // Text Filter
        if (text.length !== 0) {
            filteredElements = filteredElements.filter(el => el.text.toLowerCase().includes(text.toLowerCase()))
        }

        setRenderItems(filteredElements);
    }

    useEffect(
        _ => {
            filterMainFunction();
        },
        [filterOptions]
    )

    useEffect(
        _ => {
            setRenderItems(items)
        }, [items]
    )


    const handleItemsChange = (items) => {
        setItems(items);
    }

    return (
       <Card
           raised={true}
           sx={{
               p: 1,
           }}
       >
           <TextTableToolbar
            searchCallback={searchText}
            selectFilter={activationFilter}
            openAddModal={openAddModal}
            isAddBlackListed={isAddBlackListed}
           />
           <CardContent
           sx={{
               height: "calc(100vh - 72px - 32px - 60px - 32px - 50px - 10px)",
           }}
           >
               <TextTable
                   items={renderedItems}
                   setItems={setItems}
                   loading={loading}
                   setLoading={setLoading}
                   change={handleItemsChange}
               />
           </CardContent>
       </Card>
   )
}
