import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Stage,
    Layer,
    Image,
    Rect,
    Shape,
} from 'react-konva';
import BrokenImageIcon from '@mui/icons-material/BrokenImage';
import {useEffect, useState} from "react";
import useImage from "use-image";
import {Skeleton} from "@mui/material";

function BoundingBox(
    {
        width,
        height,
        src,
        activeDetection,
        detection,
        scalar,
    }
) {

    const [coordinates, setCoordinates] =useState(null) ;
    const [lineColor, setLineColor] = useState('black')
    const [imgError, setImgError] = useState(false);
    const [image, status] = useImage(src);
    console.log(status);

    useEffect(_ => {
        if (detection) {
            const {
                coordinates,
                type
            } = detection;
            setCoordinates(coordinates);

            if (type === 'logo') {
                setLineColor('#d32f2f')
            }
            if (type === 'text') {
                setLineColor('#02264D')
            }
            if (type === 'object') {
                setLineColor('#FBC02D')
            }

        }
    }, [detection])

    useEffect(_ => {
        if (!!status && status === 'failed' ) {
            console.log('fff')
        }
    }, [image, status])


    const cropSectionFunction = (context) => {

        if (coordinates) {
            context.beginPath();
            context.moveTo(coordinates[0] * scalar, coordinates[1] * scalar);
            context.lineTo(coordinates[2] * scalar, coordinates[3] * scalar);
            context.lineTo(coordinates[4] * scalar, coordinates[5] * scalar);
            context.lineTo(coordinates[6] * scalar, coordinates[7] * scalar);
            context.closePath();
        }
    }


    return (
        <>
            {
                status === 'failed'
                && <h3>
                    <BrokenImageIcon fontSize={'large'} /> <br/>
                    Failed to load the image
                </h3>
            }
            {
                status === 'loading'
                && <Skeleton
                    width={width}
                    height={height}
                />
            }
            {
                status === 'loaded' &&
                <Stage width={width} height={height}>
                    <Layer>
                        <Image
                            x={0}
                            y={0}
                            width={width}
                            height={height}
                            image={image}
                            opacity={activeDetection? 0.2 : 1}

                        />
                    </Layer>

                    <Layer clipFunc={(context) => {
                        cropSectionFunction(context);
                    }}>
                        <Image
                            x={0}
                            y={0}
                            width={width}
                            height={height}
                            image={image}
                        />
                    </Layer>

                    <Layer>
                        <Shape
                            sceneFunc={(context, shape) => {
                                if (coordinates) {
                                    cropSectionFunction(context)
                                    // (!) Konva specific method, it is very important
                                    context.fillStrokeShape(shape);
                                }
                            }}
                            fill="transparent"
                            stroke={activeDetection? lineColor :  'transparent'}
                            strokeWidth={1}
                            shadowBlur={5}
                        />
                    </Layer>
                </Stage>


            }

        </>
    )
}

BoundingBox.propTypes = {
    width: PropTypes.number,
    height: PropTypes.number,
    src: PropTypes.string,
    activeDetection: PropTypes.bool,
    scalar: PropTypes.number,
}

BoundingBox.defaultProps = {
    width: 500,
    height: 500,
    scalar: 1,
}

export default BoundingBox;
