import {Box} from "@mui/system";
import * as React from "react";

function TabPanel(props) {
    const {children, value, index, ...other} = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{p: 2, pt: 0}}>
                    {children}
                </Box>
            )}
        </div>
    );
}

export default TabPanel;
