import * as React from 'react';
import Papa from 'papaparse';
import DataService from "../../../DataService";
import toastr from "toastr";
import Box from "@mui/material/Box";
import {useState} from "react";
import UploadMediaLoading from "./UploadMediaLoading";
import UploadMainView from "./UploadMainView";
import PreviewCSVModal from "../../../components/PreviewCSVModal/PreviewCSVModal";


function MediaUpload(
    {
        user = {},
    }
) {
    const [showPreview, setShowPreview] = useState(false);
    const [isCsvLoading, setIsCsvLoading] = useState(false);
    const [csvData, setCsvData] = useState([]);

    function onFileChange(files) {
        setIsCsvLoading(true)
        setShowPreview(true);
        Papa.parse(files[0], {
            download: true,
            complete: function (input) {
                setIsCsvLoading(false);
                const cleanedData = input.data.filter(row =>
                    !(
                        row.length === 1 &&
                        row[0] === ''
                    )
                )
                setCsvData(cleanedData);
            }
        });
    }

    function sendMedia(urlsTextArea) {


        const dataToSend = [];
        const arrayOfUrls = urlsTextArea.split(/\n/)
        arrayOfUrls.forEach(url => {
            dataToSend.push({
                mediaUrl: url,
            })
        })
        return sendMediaFile(dataToSend)
    }

    function sendMediaFile(mediaUrlsFile) {
        return DataService.postDetectMultipleJson(mediaUrlsFile)
            .then(() => {
                toastr.success("Your batch has been sent to process");
            })
            .catch(() => {
                toastr.error("The upload failed");
                return Promise.reject()
            })
    }


    return (
        <Box>
            <PreviewCSVModal
                open={showPreview}
                isLoading={isCsvLoading}
                csvData={csvData}
                handleClose={_ => {
                    setShowPreview(false)
                }}
                detectMultipleValidation={!!user && user.detectMultipleValidation}
            />

            {
                isCsvLoading
                    ? <UploadMediaLoading/>
                    : <UploadMainView
                        onFileChange={onFileChange}
                        sendMedia={sendMedia}
                        mandatoryFields={!!user && user.detectMultipleValidation}
                    />


            }


        </Box>
    )
}

MediaUpload.propTypes = {}

MediaUpload.defaultProps = {}

export default MediaUpload;
