import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Grid,
    Card,
    TextField,
    InputAdornment,
    IconButton,
    Drawer,
    Typography,
    Tabs,
    Tab,
    Box, TableCell, FormControl, InputLabel, Select, MenuItem,
    Tooltip, tooltipClasses,
} from '@mui/material';
import {useState} from "react";
import MenuIcon from "@mui/icons-material/Menu";
import SearchWithBtn from "../SearchWithBtn";
import ViewColumnIcon from "@mui/icons-material/ViewColumn";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import HelpIcon from "@mui/icons-material/Help";
import {styled} from "@mui/material/styles";
import ToolbarSelect from "../ToolbarSelect";
import Label from '../Label'
import SearchInput from "../SearchInput";
import FilterMenu from "./FilterMenu";

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
    },
}));



function ToolbarControllers(
    {
        editors,
        handleChangeConfidence,
        handleChangeText,
        handleChangeSearchField,
        handleReviewStatus,
        handleReviewer,
        filters,
        changeFilterOption,
        metaCols,
    }) {
    const SELECT_SEARCH_FIELD = metaCols.map(meta => meta.name);

    const SELECT_PRECISION = [
        "All",
        "0.2",
        "0.4",
        "0.6",
        "0.8",
        "1",
    ]

    const [selectSearchField, setSelectSearchField] = useState(SELECT_SEARCH_FIELD[0]);
    const [selectPrecision, setSelectPrecision] = useState("0.8");

    const handleTextChange = (txt) => {
        handleChangeText(txt);
    }

    const handleSelectChange = (value) => {
        setSelectSearchField(value);
        handleChangeSearchField(value)
    };

    const handleSelectPrecision = (event) => {
        setSelectPrecision(event);
        handleChangeConfidence(event);
    }

    return (
        <Grid
            spacing={1}
            container
            alignItems="center"
            sx={{py: 1, px: -1}}
        >

            <Grid item>
                <SearchInput
                    onChange={handleTextChange}
                />
            </Grid>
            <Grid item>
                <ToolbarSelect
                    value={selectSearchField}
                    initialState={SELECT_SEARCH_FIELD[0]}
                    options={SELECT_SEARCH_FIELD}
                    onChange={handleSelectChange}
                    renderValue={(txt) => <span><Label>in</Label> {txt}</span>}
                    sx={{
                        minWidth: '200px',
                    }}
                />
            </Grid>

            <Grid item sx={{ml: 6}}>
                <Label>Confidence</Label>
            </Grid>
            <Grid item>
                <ToolbarSelect
                    value={selectPrecision}
                    initialState={selectPrecision}
                    options={SELECT_PRECISION}
                    onChange={handleSelectPrecision}
                />
            </Grid>
            <Grid item sx={{marginLeft: 'auto'}}>
                <FilterMenu
                    editors={editors}
                    handleReviewStatus={handleReviewStatus}
                    handleReviewer={handleReviewer}
                    filters={filters}
                    changeFilterOption={changeFilterOption}
                />
            </Grid>
        </Grid>
    )
}

ToolbarControllers.propTypes = {}

ToolbarControllers.defaultProps = {}

export default ToolbarControllers;
