import http from "../http-common";


export const getPlacement = (params) => {
    return http.get("/library/placements/active", { params });
};

export const postPlacement = (params) => {
    return http.post(
        "/library/placements/activate",
        "placements=" + encodeURIComponent(JSON.stringify(params)),
        {
            headers: {
                "Content-Type": "application/x-www-form-urlencoded"
            }
        });
}

export const getPlacementLog = (params) => {
    return http.get("/library/placements/activate/status", { params });
};
