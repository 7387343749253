import React, {useState, useEffect} from "react";

import {
    Select, MenuItem
} from "@mui/material";

export default function ToolbarSelect(
    {
        inputId = 'toolbarSelect',
        options,
        initialState,
        onChange,
        sx = [],
        ...other
    }) {
    const [activeSelect, setActiveSelect] = useState(initialState);

    return (
        <Select
            sx={[
                {
                    ".MuiSelect-select": {
                        padding: "8px 14px",
                    },
                    "& .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                        borderBottom: "1px solid #8092A6",
                        borderRadius: "0"
                    },
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                        border: 0,
                        borderBottom: "1px solid #8092A6",
                        borderRadius: "0"
                    },
                },
                ...(Array.isArray(sx) ? sx : [sx]),
            ]}
            id={inputId}
            value={activeSelect}
            onChange={
                (event) => {
                    const value = event.target.value;
                    setActiveSelect(value)
                    if (onChange) {
                        onChange(value);
                    }
                }
            }
            {...other}
        >
            {options.map(option => <MenuItem key={option} value={option}>{option}</MenuItem>)}
        </Select>
    )
}
