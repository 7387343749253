import React, {useState, useEffect} from "react";
import {
    Grid,
    Button,

} from "@mui/material";
import {Toolbar} from 'visuaui';
import SearchInput from '../SearchInput'
import ToolbarSelect from "../ToolbarSelect";
import CloudDownloadIcon from '@mui/icons-material/CloudDownload';
import AddIcon from '@mui/icons-material/Add';

export default function BrandTableToolbar(
    {
        openAddModal,
        selectFilter,
        searchCallback,
        openActivationModal,
        downloadCSV,
        filterOptions,
    }
) {

    const selectionConfig = {
        options: ['All brands', 'Active Brands', 'Inactive Brands'],
        init: 'Active Brands',
    }

    return (
        <Toolbar
            removeBackground
        >
            <Grid
                container
                spacing={2}
                alignItems={'center'}
            >
                <Grid item>
                    <SearchInput
                        value={filterOptions.text}
                        onChange={searchCallback}
                    />
                </Grid>
                <Grid item>
                    <ToolbarSelect
                        options={selectionConfig.options}
                        initialState={selectionConfig.init}
                        onChange={selectFilter}
                    />
                </Grid>
                <Grid item marginLeft={'auto'}>
                    <Button onClick={openActivationModal}>
                        Activation log
                    </Button>
                </Grid>
                <Grid item>
                    <Button startIcon={<AddIcon/>} variant={'contained'} color={'success'} onClick={_ => {
                        openAddModal()
                    }}>
                        Add Brand
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        startIcon={<CloudDownloadIcon/>}
                        color={'info'}
                        variant={'contained'}
                        onClick={downloadCSV}
                    >
                        CSV
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    )
}
