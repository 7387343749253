import * as React from 'react';
import PropTypes from 'prop-types';
import {useState} from "react";
import moment from "moment";
import Box from "@mui/material/Box";
import {Stack, Tab, Tabs} from "@mui/material";
import Label from "../Label";
import ToolbarSelect from "../ToolbarSelect";
import TabPanel from "../TabPanel";
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Autocomplete from '@mui/material/Autocomplete';
import TextField from '@mui/material/TextField';
import Typography from "@mui/material/Typography";
import $ from "jquery";
import ReactDOM from "react-dom";
import DataService from "../../DataService";

const DrawerContent = (
    {
        editors,
        filters,
        handleReviewStatus,
        handleReviewer,
        changeFilterOption
    }) => {

    const SELECT_OPTION = ['Not Reviewed', 'Reviewed', 'All']
    const [value, setValue] = useState(0);
    const [dateBegin, setDateBegin] = useState(moment().subtract(1, "month").startOf("month"));

    const [dateEnd, setDateEnd] = useState(moment().subtract(1, "month").endOf("month"));

    const [selectedDateRange, setSelectedDateRange] = useState(
        dateBegin.format("MMM D, YYYY") + " - " + dateEnd.format("MMM D, YYYY")
    );


    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const handleCheckAllReviewers = (event) => {
        const {target} = event;
        const {checked} = target;


        if (checked) {
            handleReviewer(editors.map(el => el.name))
        } else {
            handleReviewer([])
        }
    }

    $("[lg-range-selector]").daterangepicker(
        {
            startDate: dateBegin,
            endDate: dateEnd,
            maxDate: moment(),
            autoApply: true,
            locale: {
                monthNames: moment.months(),
                daysOfWeek: moment.weekdaysMin(),
            }
        },
        (start, end) => {
            ReactDOM.unstable_batchedUpdates(() => {
                setDateBegin(start);
                setDateEnd(end);
                changeFilterOption({date: [
                        start,
                        end
                    ]})
            });
            setSelectedDateRange(
                start.format("MMM D, YYYY") + " - " + end.format("MMM D, YYYY")
            );
            DataService.updateDeveloperMeta({
                dateRange: {
                    begin: start.format("YYYY-MM-DD"),
                    end: end.format("YYYY-MM-DD")
                }
            });
            changeFilterOption({dateAll: false})
        }
    );



    return (
        <Box sx={{width: '100%', minWidth: '360px'}}>
            <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                <Tabs value={value} onChange={handleChange} sx={{
                    ".MuiTabs-flexContainer": {
                        justifyContent: 'center',
                    }
                }}>
                    <Tab label="Filters"/>
                    <Tab disabled={true} label="Show Rows"/>
                </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
                <Box sx={{width: '100%'}}>
                    <Stack spacing={1} sx={{px: 1}}>
                        <Box>
                            <Label fullWidth={true}>
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                    Date Range
                                    <FormControlLabel
                                        value="start"
                                        control={
                                            <Checkbox
                                                size="small"
                                                checked={filters.dateAll}
                                                onChange={(e,v) => {
                                                    changeFilterOption({dateAll: v})
                                                }}
                                            />
                                        }
                                        label="All"
                                        labelPlacement="start"
                                        sx={{
                                            m: 0,
                                            ml: 'auto',
                                            ".MuiFormControlLabel-label": {
                                                fontSize: '12px'
                                            }
                                        }}
                                    />
                                </Box>

                            </Label>
                            <div className="form-control" lg-range-selector="">
                                {filters.dateAll ? 'Choose date' : selectedDateRange}
                            </div>
                        </Box>
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Label>
                                    Review By
                                </Label>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            onChange={handleCheckAllReviewers}
                                            size="small"
                                            disabled={filters.reviewed === SELECT_OPTION[0]}
                                        />
                                    }
                                    label="All"
                                    labelPlacement="start"
                                    sx={{
                                        m: 0,
                                        ml: 'auto',
                                        ".MuiFormControlLabel-label": {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            </Box>

                            <Autocomplete
                                multiple
                                limitTags={2}
                                options={editors}
                                getOptionLabel={(option) => option.name}
                                value={filters.reviewer}
                                renderInput={(params) => (
                                    <TextField {...params}  placeholder="Type reviewer email" />
                                )}
                                onChange={
                                    (e, v) =>{
                                        console.log(v)
                                        handleReviewer(v)}
                                }
                                sx={{
                                    width: '100%',
                                    ".MuiSelect-select": {
                                        padding: "8px 14px",
                                        border: 0,
                                    },
                                    ".MuiAutocomplete-inputFocused": {
                                      border: 0,
                                    },
                                    "& .MuiOutlinedInput-notchedOutline": {
                                        border: "0 !important",
                                        borderBottom: "1px solid #8092A6 !important",
                                        borderRadius: "0"
                                    },
                                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                                        border: 0,
                                        borderBottom: "1px solid #8092A6",
                                        borderRadius: "0"
                                    }, }}
                                renderTags={(value, getTagProps) => {
                                    const numTags = value.length;
                                    const brandTxt = numTags === 1? 'reviewer' : 'reviewers'
                                    const allReviewers = numTags === editors.length
                                    return (
                                        <Typography sx={{
                                            marginBottom: 0,
                                            fontSize: '12px',
                                            color: '#02264D'
                                        }}>
                                            {
                                                allReviewers? `All(${numTags})` : `${numTags} ${brandTxt} selected`
                                            }
                                        </Typography>
                                    );
                                }}
                                disabled={filters.reviewed === SELECT_OPTION[0]}
                            />
                        </Box>
                        <Stack>
                            <Box
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                }}>
                                <Label>Review Status</Label>
                                <FormControlLabel
                                    value="start"
                                    control={
                                        <Checkbox
                                            onChange={_ => {
                                                if (filters.reviewed !== 'All') {
                                                    handleReviewStatus('All')
                                                } else {
                                                    handleReviewStatus(SELECT_OPTION[0])
                                                }
                                            }}
                                            checked={filters.reviewed === 'All'}
                                            size="small"
                                        />
                                    }
                                    label="All"
                                    labelPlacement="start"
                                    sx={{
                                        m: 0,
                                        ml: 'auto',
                                        ".MuiFormControlLabel-label": {
                                            fontSize: '12px'
                                        }
                                    }}
                                />
                            </Box>


                            <ToolbarSelect
                                placeholder={'dio cane'}
                                value={filters.reviewed}
                                options={SELECT_OPTION}
                                onChange={handleReviewStatus}
                            />
                        </Stack>
                    </Stack>
                </Box>
            </TabPanel>
            <TabPanel value={value} index={1}>
                TO IMPLEMENT: Rows Visibility Selector
            </TabPanel>
        </Box>
    );
}


DrawerContent.propTypes = {}

DrawerContent.defaultProps = {}

export default DrawerContent;
