import React, {useEffect, useState} from "react";
import {
    Card,
} from '@mui/material';
import DataService from "../../DataService";
import PipelineTable from "../../components/pipeline-table";
import {fromIannServerDataToTableData} from "../../Helper/SharedFunctions";

export default function PipelineTab(
    {
        type = 1,
    }
) {
    const [data, setIannData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [reload, setReload] = useState(true);
    const [error, setError] = useState(null);
    const updateData = () => setReload(!reload);
    useEffect(() => {
        setIsLoading(true);
        DataService.getIannData(type)
            .then(resp => {
                setIannData(
                    fromIannServerDataToTableData(resp)
                )
            })
            .catch(err => setError(err))
            .finally(() => setIsLoading(false))
    }, [reload])

    return (
        <PipelineTable
            rows={data}
            isLoading={isLoading}
            type={type}
            error={error}
            updateData={updateData}
            />

    )
}
