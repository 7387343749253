import * as React from 'react';
import PropTypes from 'prop-types';
import {Button, Grid, TextField, Tooltip} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";
import TagTable from "./BrandsTable/TagTable";
import {useEffect, useState} from "react";
import {useGridLogger} from "@mui/x-data-grid";

function ItemTagManager(
    {
        logoTags,
        setLogoTags,
    }
) {
    const TOOLTIP_HEL_TEXT = 'Provide a name and value to create a new tag';

    const [disableAddTagBtn, setDisableAddTagBtn] = useState(true);
    const [tooltipText, setTooltipText] = useState(TOOLTIP_HEL_TEXT)
    const [showTooltip, setShowTooltip] = useState(false);
    const [isEditing, setIsEditing] = useState(false);

    const [newTag, setNewTag] = React.useState({
        tag: '',
        value: '',
    });

    const handleChangeTagName = (event) => {
        setNewTag(
            (prevState) => ({
                ...prevState,
                tag: event.target.value,
            })
        );
    }

    const handleChangeTagValue = (event) => {
        setNewTag(
            (prevState) => ({
                ...prevState,
                value: event.target.value,
            })
        );
    }

    const handleAddTag = () => {
        const tagsCopy ={...logoTags};
        tagsCopy[newTag.tag] = newTag.value;
        setLogoTags(tagsCopy);
        setNewTag({
            tag: '', value: '',
        })
    }

    const removeTag = (key) => {
        const newTags = {...logoTags};
        delete newTags[key]
        setLogoTags(newTags);
    };

    useEffect(_ => {
        setDisableAddTagBtn(true);
        setShowTooltip(true);
        setIsEditing(false);

        if (newTag.tag.length === 0 && newTag.value.length === 0) {
            setShowTooltip(false);
            setTooltipText(TOOLTIP_HEL_TEXT);
            return;
        }
        const tagName = newTag.tag;
        const tagValue = newTag.value;
        const arrayMetas = Object.keys(logoTags);
        if (tagName.length > 0) {
            if (tagName === '.name') {
                setTooltipText(".name is not a valid key");
                return;
            }
            if (arrayMetas.includes(tagName)) {
                setIsEditing(true);
            }
        }
        if (tagName.length > 0 && tagValue.length > 0) {
            setShowTooltip(false);
            setDisableAddTagBtn(false)
            setTooltipText('');
        } else {
            setShowTooltip(true);
            setDisableAddTagBtn(true)
            setTooltipText(TOOLTIP_HEL_TEXT);
        }


    }, [newTag.tag, newTag.value])

    return (
        <>
            <Grid container={true}>
                <Grid
                    container={true}
                    alignItems={'center'}
                    spacing={2}
                    sx={{mb: 2}}
                >
                    <Grid item xs={3}>
                        <TextField
                            value={newTag.tag}
                            id="tagNameInput"
                            label="Tag Name"
                            variant={'standard'}
                            fullWidth
                            onChange={handleChangeTagName}

                        />
                    </Grid>
                    <Grid item xs={6}>
                        <TextField
                            value={newTag.value}
                            id="tagValueInput"
                            label="Tag Value"
                            variant={'standard'}
                            fullWidth
                            onChange={handleChangeTagValue}
                        />
                    </Grid>
                    <Grid item xs={3} textAlign={'right'} marginLeft={'auto'}>
                        <Tooltip
                            title={
                                <span >
                                       <ErrorIcon sx={{mr: 1}}/>
                                    {tooltipText}
                                    </span>
                            }
                            open={showTooltip}
                        >
                                    <span>
                                        <Button
                                            fullWidth={true}
                                            variant={'contained'}
                                            onClick={handleAddTag}
                                            disabled={disableAddTagBtn}
                                            color={isEditing? 'warning' : 'primary'}
                                        >
                                            {isEditing? 'Update Tag' : 'Add New Tag'}
                                        </Button>
                                    </span>
                        </Tooltip>
                    </Grid>
                </Grid>

                <Grid item xs={12}>
                    <TagTable
                        rows={logoTags}
                        handleRemoveItem={removeTag}
                    />
                </Grid>

            </Grid>
        </>
    )
}

ItemTagManager.propTypes = {}

ItemTagManager.defaultProps = {}

export default ItemTagManager;
