import React, {useEffect, useState} from 'react';
import {styled} from "@mui/system";
import {alpha} from "@mui/material";
import InputBase from "@mui/material/InputBase";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    borderRadius: 0,
    backgroundColor: alpha('#fff', 0.15),
    '&:hover': {
        backgroundColor: alpha('#fff', 0.25),
    },
    marginLeft: "0 !important",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
    borderBottom: '1px solid rgb(129, 147, 166)'
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingRight: `calc(1em + ${theme.spacing(4)})`,
        paddingLeft: '5px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));

export default function SearchInput(
    {
        placeholder="Search…",
        onChange
    }) {
    const TYPING_TIME = 1000;
    const [searchTerm, setSearchTerm] = useState('')

    useEffect(() => {
        const delayDebounceFn = setTimeout(() => {
            if (onChange) onChange(searchTerm)
        }, TYPING_TIME)

        return () => clearTimeout(delayDebounceFn)
    }, [searchTerm])


    return (
        <Search>
            <SearchIconWrapper>
                <SearchIcon sx={{
                    color: 'rgb(30, 184, 224)'
                }} />
            </SearchIconWrapper>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{
                    'aria-label': placeholder
                }}
                onChange={e => {
                    setSearchTerm(e.target.value)
                }}
            />
        </Search>
    )
}
