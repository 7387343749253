import React, {useEffect, useState} from 'react';
import { styled } from '@mui/material/styles';
import InputBase from '@mui/material/InputBase';
import {Button} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";

const Search = styled('div')(({ theme }) => ({
    position: 'relative',
    display: 'flex',
    border: '1px solid #ccc',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: "rgb(245, 247, 248)",
    marginRight: theme.spacing(2),
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        width: 'auto',
    },
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
    color: "rgb(2, 38, 77)",
    marginRight: 0,
    '& .MuiInputBase-input': {
        marginRight: 0,
        padding: theme.spacing(1, 1, 1, 1),
        // vertical padding + font size from searchIcon
        transition: theme.transitions.create('width'),
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '20ch',
        },
    },
}));


export default function SearchWithBtn(
    {
        placeholder="Search…",
        onBtnClick,
    }) {

    const [inputValue, setInputValue] = useState('');

    const handleEnter = (e) => {
        const {code} = e;
       if (code && code === 'Enter') {
           onBtnClick(inputValue)
       }
    }

    return (
        <Search sx={{
            marginRight: 0,
        }}>
            <StyledInputBase
                placeholder={placeholder}
                inputProps={{ 'aria-label': placeholder }}
                value={inputValue}
                onChange={e => setInputValue(e.target.value)}
                onKeyDown={handleEnter}
            />
            <Button
                color={'inherit'}
                sx={{
                    backgroundColor: '#1DB8E0',
                    height: '40px',
                    borderTopLeftRadius: 0,
                    borderBottomLeftRadius: 0,
                    '&:hover': {
                        backgroundColor: '#02264D'
                    }
                }}
                onClick={_ => {
                    onBtnClick(inputValue)
                }}
            >
                <SearchIcon/>
            </Button>
        </Search>
    )
}
