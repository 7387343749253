import React, {useState} from "react";
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button,
    IconButton, Snackbar, Alert,
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import toastr from "toastr";

export default function VideoDetectionModal({item, open, handleClose}) {
    const [copySnack, setCopySnack] = useState(false);
    const {
        renderedMediaUrl,
        timeCreated,
    } = item;
    function onShareClick() {
        navigator.clipboard.writeText(window.location.href).then(function() {
            setCopySnack(true);
        });
    }

    return (
        <>
        <Dialog
            open={open}
            onClose={handleClose}
            maxWidth={'lg'}
            fullWidth={true}
        >
            <DialogTitle sx={{display: 'flex'}}>
                Video Preview
                <IconButton sx={{ml: 'auto'}} onClick={_ => handleClose()}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>
            <DialogContent sx={{pb: '5px', textAlign:'center'}}>
                <video
                    autoPlay
                    muted
                    controls
                    src={renderedMediaUrl}
                    style={{width: '100%'}}
                />
            </DialogContent>
            <DialogActions sx={{
                p: '5px 24px'
            }}>
                <span>{timeCreated}</span>
                <Button
                    sx={{ml: 'auto'}}
                    variant={'contained'}
                    onClick={_ => onShareClick()}
                >
                    Share
                </Button>
            </DialogActions>
        </Dialog>
            <Snackbar
                anchorOrigin={
                    {vertical: 'top', horizontal: 'right' }
                }
                open={copySnack}
                autoHideDuration={6000}
                onClose={_=> setCopySnack(false)}
            >
                <Alert onClose={_=> setCopySnack(false)} severity="success" sx={{ width: '100%' }}>
                    The modal url has been copied to your clipboard!
                </Alert>
            </Snackbar>
        </>
    )
}
