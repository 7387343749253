import * as React from 'react';
import './Applicationloader.css'
import ErrorIcon from '@mui/icons-material/Error';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
const Logo = () => {
    return (
        <svg width="285" height="60" viewBox="0 0 285 60" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <polygon id="visua-logo-a" points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"
                         className="svg-elem-1"></polygon>
                <polygon id="visua-logo-c" points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"
                         className="svg-elem-2"></polygon>
                <polygon id="visua-logo-e" points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"
                         className="svg-elem-3"></polygon>
                <polygon id="visua-logo-g" points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"
                         className="svg-elem-4"></polygon>
            </defs>
            <g fill="none" fillRule="evenodd" transform="matrix(1, 0, 0, 1, -719.403259, -763.872009)">
                <rect width="1440" height="4000" className="svg-elem-5"></rect>
                <g transform="matrix(1, 0, 0, 1, 723.043213, 765.577576)">
                    <polygon fill="#E05543" points="30.878 54.871 0 1.083 61.756 1.083"
                             className="svg-elem-6"></polygon>
                    <polygon fill="#02264D" points="30.511 54.871 9.961 1.083 61.756 1.083"
                             className="svg-elem-7"></polygon>
                    <polygon fill="#58BEC7" points="248.021 1.083 217.143 54.871 278.899 54.871"
                             className="svg-elem-8"></polygon>
                    <polygon fill="#02264D" points="247.653 1.083 227.104 54.871 278.899 54.871"
                             className="svg-elem-9"></polygon>
                    <g transform="translate(68.642)">
                        <path fill="#3BB7DE"
                              d="M36.263396,46.4380593 L43.3572567,41.1528706 C42.1383378,39.4737543 41.157634,37.6893061 40.3934894,35.8490938 L32.3869863,38.9285063 C33.3089098,41.7290986 34.6237335,44.281851 36.2881456,46.4814313 L36.263396,46.4380593 Z"
                              className="svg-elem-10"></path>
                        <g transform="translate(36.215)">
                            <mask id="visua-logo-b" fill="#fff">
                                <polygon points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"
                                         transform="matrix(1, 0, 0, 1, 0, 0)" className="svg-elem-11"></polygon>
                            </mask>
                            <path fill="#0C0A09"
                                  d="M48.987857,10.3708286 C45.0279173,5.04226784 39.419034,1.66854538 33.3955945,0.429345212 C29.0675042,-0.301782888 24.4857302,0.0761731635 20.0462666,1.77387739 C6.60412754,6.9134601 -0.44023392,22.1153482 4.18485189,35.845686 L4.17557078,35.851882 C4.93971539,37.6889963 5.92351291,39.4734445 7.14243184,41.1525608 L0.0485711353,46.4377495 C1.35102005,48.1943157 2.83909114,49.7371199 4.46019145,51.0630641 C11.2384945,56.3048808 20.4422605,57.9808991 29.1015349,54.6691366 C41.6186568,49.882726 48.5856757,36.3599541 45.7425627,23.4350963 C44.9258251,20.715052 43.6543133,18.0817516 41.8939963,15.6560173 L48.987857,10.3708286 Z"
                                  mask="url(#visua-logo-b)" className="svg-elem-12"></path>
                        </g>
                        <path fill="#02264D"
                              d="M85.2033006,10.3708286 C81.243361,5.04226784 75.6344776,1.66854538 69.6110381,0.429345212 C65.2829478,-0.301782888 60.7011738,0.0761731635 56.2617102,1.77387739 C42.8195712,6.9134601 35.7752097,22.1153482 40.4002955,35.845686 L40.3910144,35.851882 C41.155159,37.6889963 42.1389565,39.4734445 43.3578755,41.1525608 L36.2640148,46.4377495 C37.5664637,48.1943157 39.0545348,49.7371199 40.6756351,51.0630641 C47.4539381,56.3048808 56.6577042,57.9808991 65.3169785,54.6691366 C77.8341004,49.882726 84.8011193,36.3599541 81.9580063,23.4350963 C81.1412688,20.715052 79.8697569,18.0817516 78.1094399,15.6560173 L85.2033006,10.3708286 Z"
                              className="svg-elem-13"></path>
                        <g transform="translate(0 .922)">
                            <mask id="visua-logo-d" fill="#fff">
                                <polygon points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"
                                         transform="matrix(1, 0, 0, 1, 0, 0)" className="svg-elem-14"></polygon>
                            </mask>
                            <polygon fill="#0C0A09"
                                     points="8.136 .346 .068 53.514 .068 54.081 24.032 54.081 24.115 53.514 24.115 .346"
                                     mask="url(#visua-logo-d)" className="svg-elem-15"></polygon>
                        </g>
                        <polygon fill="#88BF40" points=".068 1.268 .068 54.436 8.136 1.268"
                                 className="svg-elem-16"></polygon>
                        <polygon fill="#02264D"
                                 points="8.136 1.268 .068 54.436 .068 55.003 24.032 55.003 24.115 54.436 24.115 1.268"
                                 className="svg-elem-17"></polygon>
                        <path fill="#F8C659"
                              d="M102.394079,29.2255689 L106.202428,1.26921313 L97.2306893,1.26921313 L97.2306893,29.2255689 C97.1378783,43.2966869 108.454643,54.7747784 122.503148,54.8677184 C122.85583,54.8677184 123.208512,54.8615224 123.555007,54.8460324 C109.852997,54.4278024 100.41411,43.0860228 102.394079,29.2255689"
                              className="svg-elem-18"></path>
                        <path fill="#02264D"
                              d="M148.110655,29.558604 L148.404557,1.26766413 L106.203356,1.26766413 L102.391914,29.2271179 C100.415038,43.0875718 109.853925,54.4293514 123.555935,54.8475814 C137.121822,54.3828814 148.020938,43.2765499 148.110655,29.558604"
                              className="svg-elem-19"></path>
                        <path fill="#3BB7DE"
                              d="M85.2067037,10.3739266 L78.112843,15.6591153 C79.3317619,17.3382315 80.3124658,19.1226798 81.0766104,20.962892 L89.0831134,17.8834796 C88.16119,15.0828872 86.8463662,12.5301349 85.1819541,10.3305546 L85.2067037,10.3739266 Z"
                              className="svg-elem-20"></path>
                    </g>
                    <polygon fill="#E05543" points="30.878 54.871 0 1.083 61.756 1.083"
                             className="svg-elem-21"></polygon>
                    <polygon fill="#02264D" points="30.511 54.871 9.961 1.083 61.756 1.083"
                             className="svg-elem-22"></polygon>
                    <polygon fill="#58BEC7" points="248.021 1.083 217.143 54.871 278.899 54.871"
                             className="svg-elem-23"></polygon>
                    <polygon fill="#02264D" points="247.653 1.083 227.104 54.871 278.899 54.871"
                             className="svg-elem-24"></polygon>
                    <g transform="translate(68.642)">
                        <path fill="#3BB7DE"
                              d="M36.263396,46.4380593 L43.3572567,41.1528706 C42.1383378,39.4737543 41.157634,37.6893061 40.3934894,35.8490938 L32.3869863,38.9285063 C33.3089098,41.7290986 34.6237335,44.281851 36.2881456,46.4814313 L36.263396,46.4380593 Z"
                              className="svg-elem-25"></path>
                        <g transform="translate(36.215)">
                            <mask id="visua-logo-f" fill="#fff">
                                <polygon points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"
                                         transform="matrix(1, 0, 0, 1, 0, 0)" className="svg-elem-26"></polygon>
                            </mask>
                            <path fill="#0C0A09"
                                  d="M48.987857,10.3708286 C45.0279173,5.04226784 39.419034,1.66854538 33.3955945,0.429345212 C29.0675042,-0.301782888 24.4857302,0.0761731635 20.0462666,1.77387739 C6.60412754,6.9134601 -0.44023392,22.1153482 4.18485189,35.845686 L4.17557078,35.851882 C4.93971539,37.6889963 5.92351291,39.4734445 7.14243184,41.1525608 L0.0485711353,46.4377495 C1.35102005,48.1943157 2.83909114,49.7371199 4.46019145,51.0630641 C11.2384945,56.3048808 20.4422605,57.9808991 29.1015349,54.6691366 C41.6186568,49.882726 48.5856757,36.3599541 45.7425627,23.4350963 C44.9258251,20.715052 43.6543133,18.0817516 41.8939963,15.6560173 L48.987857,10.3708286 Z"
                                  mask="url(#visua-logo-f)" className="svg-elem-27"></path>
                        </g>
                        <path fill="#02264D"
                              d="M85.2033006,10.3708286 C81.243361,5.04226784 75.6344776,1.66854538 69.6110381,0.429345212 C65.2829478,-0.301782888 60.7011738,0.0761731635 56.2617102,1.77387739 C42.8195712,6.9134601 35.7752097,22.1153482 40.4002955,35.845686 L40.3910144,35.851882 C41.155159,37.6889963 42.1389565,39.4734445 43.3578755,41.1525608 L36.2640148,46.4377495 C37.5664637,48.1943157 39.0545348,49.7371199 40.6756351,51.0630641 C47.4539381,56.3048808 56.6577042,57.9808991 65.3169785,54.6691366 C77.8341004,49.882726 84.8011193,36.3599541 81.9580063,23.4350963 C81.1412688,20.715052 79.8697569,18.0817516 78.1094399,15.6560173 L85.2033006,10.3708286 Z"
                              className="svg-elem-28"></path>
                        <g transform="translate(0 .922)">
                            <mask id="visua-logo-h" fill="#fff">
                                <polygon points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"
                                         transform="matrix(1, 0, 0, 1, 0, 0)" className="svg-elem-29"></polygon>
                            </mask>
                            <polygon fill="#0C0A09"
                                     points="8.136 .346 .068 53.514 .068 54.081 24.032 54.081 24.115 53.514 24.115 .346"
                                     mask="url(#visua-logo-h)" className="svg-elem-30"></polygon>
                        </g>
                        <polygon fill="#88BF40" points=".068 1.268 .068 54.436 8.136 1.268"
                                 className="svg-elem-31"></polygon>
                        <polygon fill="#02264D"
                                 points="8.136 1.268 .068 54.436 .068 55.003 24.032 55.003 24.115 54.436 24.115 1.268"
                                 className="svg-elem-32"></polygon>
                        <path fill="#F8C659"
                              d="M102.394079,29.2255689 L106.202428,1.26921313 L97.2306893,1.26921313 L97.2306893,29.2255689 C97.1378783,43.2966869 108.454643,54.7747784 122.503148,54.8677184 C122.85583,54.8677184 123.208512,54.8615224 123.555007,54.8460324 C109.852997,54.4278024 100.41411,43.0860228 102.394079,29.2255689"
                              className="svg-elem-33"></path>
                        <path fill="#02264D"
                              d="M148.110655,29.558604 L148.404557,1.26766413 L106.203356,1.26766413 L102.391914,29.2271179 C100.415038,43.0875718 109.853925,54.4293514 123.555935,54.8475814 C137.121822,54.3828814 148.020938,43.2765499 148.110655,29.558604"
                              className="svg-elem-34"></path>
                        <path fill="#3BB7DE"
                              d="M85.2067037,10.3739266 L78.112843,15.6591153 C79.3317619,17.3382315 80.3124658,19.1226798 81.0766104,20.962892 L89.0831134,17.8834796 C88.16119,15.0828872 86.8463662,12.5301349 85.1819541,10.3305546 L85.2067037,10.3739266 Z"
                              className="svg-elem-35"></path>
                    </g>
                </g>
            </g>
        </svg>
    )
}

const ApplicationLoader = ({error, errorMsg}) => {

    const LoadingView = (
        <>
            <Logo />
            <p className="loadingText">
                The application is loading
                <span>.</span><span>.</span><span>.</span>
            </p>
        </>
    )

    const FailView = (
        <>
            <SentimentVeryDissatisfiedIcon
                sx={{
                    fontSize: '4rem',
                    color: "error.main",
                }}
            />
            <p className="failText">
                {
                    errorMsg ? errorMsg: <> Critical Error. <br/> Impossible to start the application. </>
                }
            </p>
        </>
    )

    return (
        <div className={'ApplicationLoader'}>
            <div className={'ApplicationLoader--content'}>
                {error
                    ? FailView
                    : LoadingView
                }
            </div>
        </div>
    )
}

export default ApplicationLoader;
