import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions, Button,
} from "@mui/material";

function ConfirmDeleteModal(
    {
        open,
        handleClose,
        onConfirm,
    }
) {
    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
            >
                <DialogTitle>
                    Delete Item
                </DialogTitle>
                <DialogContent>
                    <p>
                        Are you sure you delete this item?
                    </p>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'error'}
                        onClick={onConfirm}
                    >
                        Yes
                    </Button>
                    <Button
                        color={'secondary'}
                        onClick={handleClose}
                    >
                        No
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

ConfirmDeleteModal.propTypes = {
    open: PropTypes.bool,
}

ConfirmDeleteModal.defaultProps = {}

export default ConfirmDeleteModal;
