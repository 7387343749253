import * as React from "react";
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import {Tab, Tabs, Avatar} from "@mui/material";
import dashboardIcon from './tabIcons/tab-dashboard.svg';
import exploreIcon from './tabIcons/tab-explore.svg';
import mediaIcon from './tabIcons/tab-media.svg';
import libraryIcon from './tabIcons/tab-your-library.svg';
import {Link, Route} from "react-router-dom";
import { styled } from '@mui/material/styles';
import dataPipeline from './tabIcons/noun-pipeline.svg';

const StyledTabs = styled((props) => (
    <Tabs
        {...props}
        TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
    />
))({
    '& .MuiTabs-indicator': {
        display: 'flex',
        justifyContent: 'center',
        backgroundColor: 'transparent',
    },
    '& .MuiTabs-indicatorSpan': {
        maxWidth: 40,
        width: '100%',
        backgroundColor: 'transparent',
    },
});



/**
 *  Visua Logo SVG
 */
const Logo = () => {
    return (
        <svg width="200" height="40" viewBox="0 0 285 60" xmlns="http://www.w3.org/2000/svg">
            <defs>
                <polygon id="visua-logo-a" points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"/>
                <polygon id="visua-logo-c" points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"/>
                <polygon id="visua-logo-e" points=".049 .076 48.988 .076 48.988 56.374 .049 56.374"/>
                <polygon id="visua-logo-g" points=".069 .347 24.115 .347 24.115 54.081 .069 54.081"/>
            </defs>
            <g fill="none" fillRule="evenodd" transform="matrix(1, 0, 0, 1, -719.403259, -763.872009)">
                <rect width="1440" height="4000"/>
                <g transform="matrix(1, 0, 0, 1, 723.043213, 765.577576)">
                    <polygon fill="#E05543" points="30.878 54.871 0 1.083 61.756 1.083"/>
                    <polygon fill="#02264D" points="30.511 54.871 9.961 1.083 61.756 1.083"/>
                    <polygon fill="#58BEC7" points="248.021 1.083 217.143 54.871 278.899 54.871"/>
                    <polygon fill="#02264D" points="247.653 1.083 227.104 54.871 278.899 54.871"/>
                    <g transform="translate(68.642)">
                        <path fill="#3BB7DE" d="M36.263396,46.4380593 L43.3572567,41.1528706 C42.1383378,39.4737543 41.157634,37.6893061 40.3934894,35.8490938 L32.3869863,38.9285063 C33.3089098,41.7290986 34.6237335,44.281851 36.2881456,46.4814313 L36.263396,46.4380593 Z"/>
                        <g transform="translate(36.215)">
                            <mask id="visua-logo-b" fill="#fff">
                                <polygon points=".049 .076 48.988 .076 48.988 56.374 .049 56.374" transform="matrix(1, 0, 0, 1, 0, 0)"/>
                            </mask>
                            <path fill="#0C0A09" d="M48.987857,10.3708286 C45.0279173,5.04226784 39.419034,1.66854538 33.3955945,0.429345212 C29.0675042,-0.301782888 24.4857302,0.0761731635 20.0462666,1.77387739 C6.60412754,6.9134601 -0.44023392,22.1153482 4.18485189,35.845686 L4.17557078,35.851882 C4.93971539,37.6889963 5.92351291,39.4734445 7.14243184,41.1525608 L0.0485711353,46.4377495 C1.35102005,48.1943157 2.83909114,49.7371199 4.46019145,51.0630641 C11.2384945,56.3048808 20.4422605,57.9808991 29.1015349,54.6691366 C41.6186568,49.882726 48.5856757,36.3599541 45.7425627,23.4350963 C44.9258251,20.715052 43.6543133,18.0817516 41.8939963,15.6560173 L48.987857,10.3708286 Z" mask="url(#visua-logo-b)"/>
                        </g>
                        <path fill="#02264D" d="M85.2033006,10.3708286 C81.243361,5.04226784 75.6344776,1.66854538 69.6110381,0.429345212 C65.2829478,-0.301782888 60.7011738,0.0761731635 56.2617102,1.77387739 C42.8195712,6.9134601 35.7752097,22.1153482 40.4002955,35.845686 L40.3910144,35.851882 C41.155159,37.6889963 42.1389565,39.4734445 43.3578755,41.1525608 L36.2640148,46.4377495 C37.5664637,48.1943157 39.0545348,49.7371199 40.6756351,51.0630641 C47.4539381,56.3048808 56.6577042,57.9808991 65.3169785,54.6691366 C77.8341004,49.882726 84.8011193,36.3599541 81.9580063,23.4350963 C81.1412688,20.715052 79.8697569,18.0817516 78.1094399,15.6560173 L85.2033006,10.3708286 Z"/>
                        <g transform="translate(0 .922)">
                            <mask id="visua-logo-d" fill="#fff">
                                <polygon points=".069 .347 24.115 .347 24.115 54.081 .069 54.081" transform="matrix(1, 0, 0, 1, 0, 0)"/>
                            </mask>
                            <polygon fill="#0C0A09" points="8.136 .346 .068 53.514 .068 54.081 24.032 54.081 24.115 53.514 24.115 .346" mask="url(#visua-logo-d)"/>
                        </g>
                        <polygon fill="#88BF40" points=".068 1.268 .068 54.436 8.136 1.268"/>
                        <polygon fill="#02264D" points="8.136 1.268 .068 54.436 .068 55.003 24.032 55.003 24.115 54.436 24.115 1.268"/>
                        <path fill="#F8C659" d="M102.394079,29.2255689 L106.202428,1.26921313 L97.2306893,1.26921313 L97.2306893,29.2255689 C97.1378783,43.2966869 108.454643,54.7747784 122.503148,54.8677184 C122.85583,54.8677184 123.208512,54.8615224 123.555007,54.8460324 C109.852997,54.4278024 100.41411,43.0860228 102.394079,29.2255689"/>
                        <path fill="#02264D" d="M148.110655,29.558604 L148.404557,1.26766413 L106.203356,1.26766413 L102.391914,29.2271179 C100.415038,43.0875718 109.853925,54.4293514 123.555935,54.8475814 C137.121822,54.3828814 148.020938,43.2765499 148.110655,29.558604"/>
                        <path fill="#3BB7DE" d="M85.2067037,10.3739266 L78.112843,15.6591153 C79.3317619,17.3382315 80.3124658,19.1226798 81.0766104,20.962892 L89.0831134,17.8834796 C88.16119,15.0828872 86.8463662,12.5301349 85.1819541,10.3305546 L85.2067037,10.3739266 Z"/>
                    </g>
                    <polygon fill="#E05543" points="30.878 54.871 0 1.083 61.756 1.083"/>
                    <polygon fill="#02264D" points="30.511 54.871 9.961 1.083 61.756 1.083"/>
                    <polygon fill="#58BEC7" points="248.021 1.083 217.143 54.871 278.899 54.871"/>
                    <polygon fill="#02264D" points="247.653 1.083 227.104 54.871 278.899 54.871"/>
                    <g transform="translate(68.642)">
                        <path fill="#3BB7DE" d="M36.263396,46.4380593 L43.3572567,41.1528706 C42.1383378,39.4737543 41.157634,37.6893061 40.3934894,35.8490938 L32.3869863,38.9285063 C33.3089098,41.7290986 34.6237335,44.281851 36.2881456,46.4814313 L36.263396,46.4380593 Z"/>
                        <g transform="translate(36.215)">
                            <mask id="visua-logo-f" fill="#fff">
                                <polygon points=".049 .076 48.988 .076 48.988 56.374 .049 56.374" transform="matrix(1, 0, 0, 1, 0, 0)"/>
                            </mask>
                            <path fill="#0C0A09" d="M48.987857,10.3708286 C45.0279173,5.04226784 39.419034,1.66854538 33.3955945,0.429345212 C29.0675042,-0.301782888 24.4857302,0.0761731635 20.0462666,1.77387739 C6.60412754,6.9134601 -0.44023392,22.1153482 4.18485189,35.845686 L4.17557078,35.851882 C4.93971539,37.6889963 5.92351291,39.4734445 7.14243184,41.1525608 L0.0485711353,46.4377495 C1.35102005,48.1943157 2.83909114,49.7371199 4.46019145,51.0630641 C11.2384945,56.3048808 20.4422605,57.9808991 29.1015349,54.6691366 C41.6186568,49.882726 48.5856757,36.3599541 45.7425627,23.4350963 C44.9258251,20.715052 43.6543133,18.0817516 41.8939963,15.6560173 L48.987857,10.3708286 Z" mask="url(#visua-logo-f)"/>
                        </g>
                        <path fill="#02264D" d="M85.2033006,10.3708286 C81.243361,5.04226784 75.6344776,1.66854538 69.6110381,0.429345212 C65.2829478,-0.301782888 60.7011738,0.0761731635 56.2617102,1.77387739 C42.8195712,6.9134601 35.7752097,22.1153482 40.4002955,35.845686 L40.3910144,35.851882 C41.155159,37.6889963 42.1389565,39.4734445 43.3578755,41.1525608 L36.2640148,46.4377495 C37.5664637,48.1943157 39.0545348,49.7371199 40.6756351,51.0630641 C47.4539381,56.3048808 56.6577042,57.9808991 65.3169785,54.6691366 C77.8341004,49.882726 84.8011193,36.3599541 81.9580063,23.4350963 C81.1412688,20.715052 79.8697569,18.0817516 78.1094399,15.6560173 L85.2033006,10.3708286 Z"/>
                        <g transform="translate(0 .922)">
                            <mask id="visua-logo-h" fill="#fff">
                                <polygon points=".069 .347 24.115 .347 24.115 54.081 .069 54.081" transform="matrix(1, 0, 0, 1, 0, 0)"/>
                            </mask>
                            <polygon fill="#0C0A09" points="8.136 .346 .068 53.514 .068 54.081 24.032 54.081 24.115 53.514 24.115 .346" mask="url(#visua-logo-h)"/>
                        </g>
                        <polygon fill="#88BF40" points=".068 1.268 .068 54.436 8.136 1.268"/>
                        <polygon fill="#02264D" points="8.136 1.268 .068 54.436 .068 55.003 24.032 55.003 24.115 54.436 24.115 1.268"/>
                        <path fill="#F8C659" d="M102.394079,29.2255689 L106.202428,1.26921313 L97.2306893,1.26921313 L97.2306893,29.2255689 C97.1378783,43.2966869 108.454643,54.7747784 122.503148,54.8677184 C122.85583,54.8677184 123.208512,54.8615224 123.555007,54.8460324 C109.852997,54.4278024 100.41411,43.0860228 102.394079,29.2255689"/>
                        <path fill="#02264D" d="M148.110655,29.558604 L148.404557,1.26766413 L106.203356,1.26766413 L102.391914,29.2271179 C100.415038,43.0875718 109.853925,54.4293514 123.555935,54.8475814 C137.121822,54.3828814 148.020938,43.2765499 148.110655,29.558604"/>
                        <path fill="#3BB7DE" d="M85.2067037,10.3739266 L78.112843,15.6591153 C79.3317619,17.3382315 80.3124658,19.1226798 81.0766104,20.962892 L89.0831134,17.8834796 C88.16119,15.0828872 86.8463662,12.5301349 85.1819541,10.3305546 L85.2067037,10.3739266 Z"/>
                    </g>
                </g>
            </g>
        </svg>
    )
}


const Header = (props) => {
    const {user} = props;
    console.log(user)

    /**
     * Tabs Information
     * @type {[{imgUrl: *, label: string, url: string}]}
     */
    const tabsConfig = [{
        imgUrl: dashboardIcon,
        label: 'Dashboard',
        value: '/',
        url: '/',
    }];


    let username = '';
    let privateTabs = [];

    try {
        const {
            developer,
            subscriptionMeta
        } = user;

        if (developer) {
            username = developer.name;
        }

        if (subscriptionMeta) {
            const {metadata} = subscriptionMeta;
            if (!!metadata) {
                if (!!metadata.privateTabs) {
                    privateTabs = metadata.privateTabs;
                }
            }
        }

        /**
         * New Tab Configuration
         */


        if (user.permission.includes("apiExplore")) {
            tabsConfig.push({
                imgUrl: exploreIcon,
                label: 'Explore',
                value: '/explore',
                url: '/explore',
            })
        }

        // Media Tabs

        let firstTabMedia = `/media/upload`;

        if (
            !user.isVideo && (!privateTabs.includes("detections"))
        ) {
            firstTabMedia =`/media/detections`
        }

        if (
            user.isVideo && (!privateTabs.includes("video-detections"))
        ) {
            firstTabMedia =`/media/video-detections`
        }

        tabsConfig.push({
            imgUrl: mediaIcon,
            label: 'Media',
            value: '/media',
            url: firstTabMedia,
        })


        //
        let hasBrandsPermission = !privateTabs.includes("brands")
            && !user.permission.includes("apiSimilarity");
        let hasObjectPermission = user.isVisualSearch;
        let hasTextPermission = user.isText;
        let firstTabLibrary = `/library/brands`;

        if (hasBrandsPermission || hasObjectPermission || hasTextPermission) {

            if (!hasBrandsPermission) {
                if (hasObjectPermission) {
                    firstTabLibrary = `/library/visuasearch`
                } else {
                    firstTabLibrary = `/library/Text`
                }
            }

            tabsConfig.push({
                imgUrl: libraryIcon,
                label: 'Library',
                value: '/library',
                url: firstTabLibrary,
            })
        }

        if (user.permission.includes('apiIann')) {
            tabsConfig.push({
                imgUrl: dataPipeline,
                label: 'Pipeline',
                value: '/pipeline',
                url: '/pipeline/progress',
            })
        }


    } catch (e) {
        console.error(e);
    }


    const UserTabs = tabsConfig.map( (config) => {
        return<Tab
            key={config.label}
            value={config.value}
            icon={<img
                alt={`${config.label} icon`}
                src={config.imgUrl}
                style={{
                    width: '100%',
                    minWidth: '18px',
                    height: '20px'
                }}
            />}
            component={Link}
            to={config.url}
            iconPosition="start"
            label={config.label}
            sx={{
                textTransform: "none",
                fontWeight: 600,
            }}
        />
    })




    return (
        <MuiAppBar
            position="static"
            sx={{
                backgroundColor: 'background.paper',
                color: 'primary.main'
            }}
        >
            <Toolbar
                sx={{
                    pr: '24px',
                }}
            >
                <Box
                    sx={{flexGrow: 1}}
                >
                    <Route
                        path="/"
                        render={
                        ({location}) => {
                            const {pathname} = location;
                            let secondSlashIndex = pathname.indexOf('/', 1);

                            let tabValue = (secondSlashIndex === -1)
                                ? pathname
                                : pathname.slice(0, secondSlashIndex);

                            return (
                                <StyledTabs
                                    value={tabValue}
                                >
                                    {UserTabs}
                                </StyledTabs>
                            )
                        }
                    }
                    />
                </Box>
                <Button
                    id="basic-button"
                    aria-haspopup="true"
                    sx={{
                        textTransform: 'lowercase',
                        mr: 2,
                        typography: 'body1'
                    }}
                    onClick={_ => window.location = "https://my.visua.com"}
                >
                    {username}
                </Button>
                <Box
                    sx={{
                        'svg': {
                            width: '100%',
                            height: '40px',
                        }
                    }}
                >
                    <a href="https://my.visua.com">
                        <Logo/>
                    </a>
                </Box>
            </Toolbar>
        </MuiAppBar>
    )
}

export default Header;
