import React, { useEffect, useRef } from "react";
import $ from "jquery";
import toastr from "toastr";
import "./PhotoModal.scss";
import moment from "moment";
import Detections from "./Detections";

function PhotoModal(/** @type {{ media: import("./DataService").FeedDetectionResult, onClosed?: () => void, isSharable?: Boolean }} */{ media, onClosed = undefined, isSharable }) {
  const photoModal = useRef(null);

  useEffect(() => {
    var canvas = $("#myCanvas"),
      tooltips = $('.modal-footer [data-toggle="tooltip"]'),
      mediaContainer = $(".Gallery-media");

    mediaContainer.on("mouseenter", function() {
      canvas.css("opacity", 1);
      tooltips.tooltip("show");
    });

    mediaContainer.on("mouseleave", function() {
      canvas.css("opacity", 0);
      tooltips.tooltip("hide");
    });

    $(photoModal.current).on("hidden.bs.modal", () => {
      tooltips.tooltip("dispose");
      if (onClosed) {
        onClosed();
      }
    });
  }, []);

  function onShareClick() {
    navigator.clipboard.writeText(window.location.href).then(function() {
      toastr.success("Link copied to clipboard!");
    });
  }

  return (
    <div
      className="modal fade"
      id="myModal"
      tabIndex={-1}
      role="dialog"
      aria-labelledby="myModalLabel"
      lg-photo-modal=""
      ref={photoModal}
    >
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="Gallery-media">
            {/* Overlay canvas */}
            <canvas id="myCanvas"></canvas>
            <img className="media-image" />
          </div>
          <div className="modal-footer">
            {media && (
              <div className="full-size-detections">
                {media.detections
                  .filter((detection) => detection.size === 'full')
                  .map((detection) => (
                    <div key={Math.random()} className="full-size-detection">
                      {detection.name} ({detection.confidenceALE})
                    </div>
                  ))
                }
              </div>
            )}
            {media && (
              <div className="tweet-text mr-auto">
                <span className="date">
                  {media.timeCreated && moment(media.timeCreated).format("lll")}
                </span>
                <span
                  className="thumb-img"
                  style={{
                    backgroundImage:
                      "url(" + media.detections[0].iconUrl + ")"
                  }}
                ></span>
                <span
                  className="thumb-img"
                  style={{
                    backgroundImage:
                      "url(" +
                      (media.detections[1] && media.detections[1].iconUrl) +
                      ")"
                  }}
                ></span>
                <span
                  className="thumb-img"
                  style={{
                    backgroundImage:
                      "url(" +
                      (media.detections[2] && media.detections[2].iconUrl) +
                      ")"
                  }}
                ></span>
              </div>
            )}
            {isSharable && (
              <button
                id="shareBtn"
                type="button"
                className="btn btn-outline-primary"
                onClick={onShareClick}
              >
                Share
              </button>
            )}
            <button
              type="button"
              className="btn btn-outline-secondary"
              data-dismiss="modal"
            >
              Close
            </button>
            <button
              id="tooltipAnchor0"
              type="button"
              className="btn btn-default"
              data-toggle="tooltip"
              data-placement="left"
            ></button>
            <button
              id="tooltipAnchor1"
              type="button"
              className="btn btn-default"
              data-toggle="tooltip"
              data-placement="left"
            ></button>
            <button
              id="tooltipAnchor2"
              type="button"
              className="btn btn-default"
              data-toggle="tooltip"
              data-placement="left"
            ></button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PhotoModal;
