import * as React from 'react';
import {
    TableRow,
    TableHead,
    TableContainer,
    TableCell,
    TableBody,
    Table,
    Skeleton,
    Tooltip,
} from '@mui/material';

function PlacementListTable(
    {
        rows,
        loading,
    }
) {
    return (
        <TableContainer sx={{
            overflow: 'auto',
            height: "calc(100vh - 72px - 32px - 60px - 50px)",
        }}>
            <Table sx={{ minWidth: 650 }} aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>Placement</TableCell>
                        <TableCell>Example Images</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        loading && [...Array(10).keys()].map((row) => (
                            <TableRow
                                key={row}
                            >
                                <TableCell width={'30%'} component="th" scope="row">
                                    <Skeleton variant={'text'}/>
                                </TableCell>
                                <TableCell>
                                    <Skeleton
                                        variant={'rectangular'}
                                        height={125}
                                        width={250}
                                    />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                    {!loading && rows.map((row) => (
                        <TableRow
                            key={row.id}
                        >
                            <TableCell width={'30%'} component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell>
                                <Tooltip title={
                                    <img src={row.thumbUrl} alt=""/>
                                } >
                                    <img src={row.thumbUrl} alt=""/>
                                </Tooltip>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </TableContainer>    )
}



export default PlacementListTable;
