import React, { useState, useEffect } from "react";
import { DataGrid, useGridApiContext} from '@mui/x-data-grid';
import {
    IconButton,
    Switch,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    Button
} from '@mui/material'
import moment from "moment";
import {Select, MenuItem} from "@mui/material";
import DataService from "../DataService";
import DeleteIcon from '@mui/icons-material/Delete';


function SelectThreshold(props) {
    const {value, change, row} = props;
    const [activeSelect, setActiveSelect] = useState(value)

    return (
        <Select
            sx={{
                ".MuiSelect-select": {
                    padding: "8px 14px",
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    borderBottom: "1px solid #484850",
                    borderRadius: "0"
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    borderBottom: "1px solid #484850",
                    borderRadius: "0"
                },
            }}
            id="demo-simple-select-standard"
            value={activeSelect}
            onChange={
                (event) => {
                    const value = event.target.value;
                    setActiveSelect(event.target.value);
                    const {id} = row;
                    DataService.putText(id, value).then((result) => {
                        if (result) {
                            change(result.items);
                        }
                    });
                }
            }
        >
            <MenuItem value={0.2}>0.2</MenuItem>
            <MenuItem value={0.4}>0.4</MenuItem>
            <MenuItem value={0.5}>0.5</MenuItem>
            <MenuItem value={0.6}>0.6</MenuItem>
            <MenuItem value={0.7}>0.7</MenuItem>
            <MenuItem value={0.8}>0.8</MenuItem>
            <MenuItem value={0.9}>0.9</MenuItem>
            <MenuItem value={1}>1</MenuItem>
        </Select>
    )
}

function ActivationSwitch(props) {
    const {status, row, change} = props;
    const [checked, setChecked] = useState(status)

    const handleChange = (value) => {
        setChecked(Boolean(value))
        const {id, threshold} = row;
        if (value) {
            DataService.putText(id, threshold).then((result) => {
                if (result) {
                    change(result.items);
                }
            });
        } else {
            DataService.deleteText(id).then((result) => {
                if (result) {
                    change(result.items);
                }
            });
        }
    }

    return (
        <Switch checked={checked} value={checked} onChange={
                (e) => {
                    handleChange(e.target.checked)
                }
            }
        />

    )
}


export default function TextTable(
    {
        items = [],
        setItems,
        loading,
        setLoading,
        change
    }
) {

    const [tableOption, setTableOption] = useState({
        pageSize: 50,
    })
    const [showWarning, setShowWarning] = useState('');

    const columns = [
        { field: 'id', headerName: 'ID', width: 90 },
        {
            field: 'text',
            headerName: 'Text library',
            minWidth: 200,
            flex: 1,
        },
        {
            field: 'brand',
            headerName: 'Brand',
            width: 200,
            renderCell: (params) => {
                return params.row?.brand?.name
            }
        },
        {
            field: 'threshold',
            headerName: 'Confidence',
            renderCell: (params) => {
                const { row } =params;
                const { threshold } = row;
                return (
                    <SelectThreshold value={threshold} change={change} {...params} />
                )
            },
            type: 'number',
            width: 150,
            sortable: false,
            align: 'center'
        },
        {
            field: 'dateEntered',
            headerName: 'Date Entered',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                const { row } =params;
                const { dateEntered } = row;
                return moment(dateEntered, 'YYYY-MM-DD').format("D/M/Y");
            }
        },
        {
            field: 'status',
            headerName: 'Toggle',
            renderCell: (params) => {
               const {row} = params;
               const {status} = row;
               return (
                   <ActivationSwitch status={status === 1} change={change} row={row}/>
               )
            }
        },
        {
            field: 'delete',
            headerName: 'Delete',
            renderCell: (params) => {
                const {row} = params;

                return (
                    <IconButton color={'error'}
                        onClick={() => setShowWarning(row.text)}
                    >
                        <DeleteIcon/>
                    </IconButton>
                );
            }
        }
    ];

    const deleteElem = () => {
        const idEl = items.find(el => el.text === showWarning)?.id;
        if (idEl) {
            setLoading(true);
            DataService
                .deleteText(idEl, true)
                .then((resp) => {
                    setItems(resp.items);
                    console.log(resp)
                })
                .finally(() => setLoading(false))
        }

    }

    return (
        <>
            <Dialog
                open={showWarning !== ''}
                onClose={() => setShowWarning('')}
            >
                <DialogTitle>
                    Warning
                </DialogTitle>
                <DialogContent>
                    Are you sure you want to delete the text "{showWarning}" from your library?
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'error'}
                        onClick={() => {
                            deleteElem();
                            setShowWarning('');
                        }}
                    >
                        Yes
                    </Button>
                    <Button
                        color={'secondary'}
                        onClick={() => setShowWarning('')}
                    >
                        Undo
                    </Button>
                </DialogActions>
            </Dialog>
            <DataGrid
                density={'compact'}
                autoHeight={false}
                rows={items}
                experimentalFeatures={{newEditingApi: true}}
                columns={columns}
                pageSize={tableOption.pageSize}
                rowsPerPageOptions={[10, 50, 100]}
                disableSelectionOnClick
                loading={loading}
                columnVisibilityModel={{
                    id: false,
                }}
                onPageSizeChange={(size) => {
                    setTableOption({
                        pageSize: size
                    })
                }}
            />

        </>

    );
}
