import React, {useEffect, useState} from "react";
import DataService from "../../DataService";
import {
    Alert,
} from '@mui/material'
import TextTableCard from "../../components/TextTable/TextTableCard";
import AddTextModal from "../../components/TextTable/AddTextModal";
const BLACK_LISTED_COMPANY = 3430;

export default function Text(
    {
        user,
    }
) {
    console.log({user})
    const isTest = user?.isTest;
    const isAddBlackListed = user?.developer?.companyId === 3430;
    const [listOfText, setListOfText] = useState([]);
    const [openAddModal, setOpenAddModal] = useState(false);
    const [loading, setLoading] = useState(false);
    useEffect(() => {
        setLoading(true)
        DataService.getText().then((listOfText) => {
            const {items} = listOfText;
            const objs = items ?? [];
            setListOfText(objs);
        }).then(_ => setLoading(false));
    }, []);

    const addNewText = (text, threshold) => {
        DataService.addText(text, parseFloat(threshold))
            .then((result) => {
                if (result) {
                    setListOfText(result.items);
                }
            });
    }


    const openModal = () => {
       return setOpenAddModal(true)
    }
    return (
        <div>
            {
                isTest &&
                <Alert
                    severity="info"
                    sx={{
                        m: 2,
                    }}
                >
                    This is a development account. All changes to the library will be applied to the production account
                    as well.
                </Alert>
            }
            <TextTableCard
                items={listOfText}
                setItems={setListOfText}
                openAddModal={openModal}
                loading={loading}
                setLoading={setLoading}
                isAddBlackListed={isAddBlackListed}
            />
            <AddTextModal
                open={openAddModal}
                save={addNewText}
                handleClose={_ => setOpenAddModal(false)}
            />
        </div>
    )
}
