import React, { useState, useEffect } from "react";
import {
    Grid,
    Button,
} from "@mui/material";
import {Toolbar} from 'visuaui';
import SearchInput from '../SearchInput'
import ToolbarSelect from "../ToolbarSelect";
import AddIcon from '@mui/icons-material/Add';

export default function TextTableToolbar(
    {
        openAddModal,
        selectFilter,
        searchCallback,
        isAddBlackListed
    }
){
    const SELECT_FILTER_OPTION = ['All', 'Active', 'Disabled'];

    return (
        <Toolbar
            removeBackground={true}
        >
            <Grid
                container={true}
                spacing={2}
                alignItems={'center'}
            >
               <Grid item>
                  <SearchInput
                      onChange={searchCallback}
                  />
               </Grid>
                <Grid item>
                    <ToolbarSelect
                        options={SELECT_FILTER_OPTION}
                        initialState={SELECT_FILTER_OPTION[0]}
                        onChange={selectFilter}
                    />
                </Grid>
                <Grid item marginLeft={'auto'}>
                    <Button
                        startIcon={<AddIcon />}
                        variant={'contained'}
                        color={'success'}
                        onClick={_ => openAddModal()}
                        disabled={isAddBlackListed}
                    >
                        Add
                    </Button>
                </Grid>
            </Grid>
        </Toolbar>
    )
}
