import React, {useEffect, useState} from "react";
import {
    Box,
} from '@mui/material'
import TableCard from "../components/ExploreCard/TableCard";
import DataService from "../DataService";
import moment from "moment";
import Papa from "papaparse";

export default function Explore(
    {
        history, metaTags, editors, user
    }
) {

    const [filters, setFilters] = useState({
        dateAll: true,
        date: [
            moment().subtract(1, "month").startOf("month"),
            moment().subtract(1, "month").endOf("month")
        ],
        reviewed: 'Not Reviewed',
        reviewer: [],
        searchText: '',
        searchField: 'campaignURL',
        confidence: 0.8,
    });
    const [rowState, setRowState] = useState({
        page: 1,
        pageSize: 50,
        rows: [],
        loading: true,
        totalRows: 0,
    })
    const [isExporting, setIsExporting] = useState(false);

    const changeFilterOption = (opt) => {
        setFilters((prev) => ({
                ...prev,
                ...opt,
            })
        )
    }

    const getDeveloperHash = () => {
        return filters.reviewer.map(el => el.developerHash).join(',')
    }

    const getExplorerFeed = (page, size = 100) => {
        return DataService.getFeed(
            {
                ...(
                    filters.confidence !== 'All'
                    && {  minScore: filters.confidence,}
                ),
                page: page,
                pageSize: size,
                explore: true,
                desc: true,
                ...(
                    !filters.dateAll
                    && { dateBegin: filters.date[0]}
                ),
                ...(
                    !filters.dateAll
                    && { dateEnd: filters.date[1]}
                ),
                ...(
                    filters.reviewed !== 'All'
                    && {reviewStatus: filters.reviewed === 'Reviewed' ? '1' : '0'}
                ),
                ...(
                    filters.searchText
                    && {filterValue: filters.searchText}
                ),
                ...(
                    filters.searchText
                    && {filterKey: filters.searchField}
                ),
                ...(
                    (filters.reviewer.length > 0 && filters.reviewed !== 'Not Reviewed')
                    && {developersHash: getDeveloperHash()}
                )
            }
        )
    }

    const exportFeed = () => {
        setIsExporting(true);
        const requestNum = Math.ceil(rowState.totalRows / 100)
        const requests = [...Array(requestNum).keys()]
            .map((index) => getExplorerFeed(index + 1))
        ;

        Promise
            .all(requests)
            .then((resp) => {
                const exportData = resp.map((el) => {
                    const { feed } = el;
                    const metaNames = metaTags.map(meta => meta.name);
                    return feed.map(el => {
                        const newEl = {
                            ...el,
                        };
                        metaNames.forEach(name => newEl[name] = el.meta[name])
                        return newEl;
                    })
                })
                    .flat()
                    .map((el) => {
                        const row = {
                            id: el.sessionId,
                            media: el.mediaUrl,
                            detections: el.detections,
                            date: el.timeCreated,
                        };
                        const metas = {};
                        const metaNames = metaTags.map(meta => meta.name);
                        metaNames.forEach(name => metas[name] = el.meta[name])
                        const baseRow = {
                            ...row,
                            ...metas,
                        }

                        return el.detections.map((det) => {
                            let name = '';
                            switch (det.type) {
                                case 'logo': name = det.name; break;
                                case 'object': name = det.name; break;
                                case 'text': name = det.text; break;
                            }

                            return {
                                detectionType: det.type,
                                detectionName: name,
                                confidence: det.confidence,
                                classId: det.visualClassId,
                                areaPercentage: det.areaPercentage,
                                ...baseRow,

                            }
                        })
                    })
                    .flat()
                ;

                const blob = new Blob([Papa.unparse(exportData)], {
                    type: "text/csv"
                });
                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", objectUrl);
                const fileName = `exporer-export-${moment().format("YYYY-MM-DD")}`;
                link.setAttribute("download", `${fileName}.csv`);
                link.click();
            })
            .finally(() => setIsExporting(false))
    }
    useEffect(_ => {
        setRowState((prevState) => ({
          ...prevState,
          loading: true,
        }));
        getExplorerFeed(rowState.page, rowState.pageSize)
        .then(resp => {
            const {feed, mediaCount} = resp;
            const metaNames = metaTags.map(meta => meta.name);

            const mappedElements = feed.map(el => {

                const newEl = {
                    ...el,
                };

                metaNames.forEach(name => newEl[name] = el.meta[name])
                return newEl;
            })

            setRowState((prevState) => ({
                ...prevState,
                rows: mappedElements,
                totalRows: mediaCount,
            }));
        })
            .then(_ => {
                setRowState((prevState) => ({
                    ...prevState,
                    loading: false,
                }));
            })

    }, [
        filters.dateAll,
        filters.date,
        filters.confidence,
        filters.reviewed,
        filters.reviewer,
        filters.searchField,
        filters.searchText,
        rowState.pageSize,
        rowState.page,
    ])

    const handleChangeReviewers = (newConfidence) => {
        setFilters(prev => ({
            ...prev,
            reviewer: newConfidence
        }))
    }
    const handleChangeConfidence = (newConfidence) => {
        setFilters(prev => ({
            ...prev,
            confidence: newConfidence
        }))
    }
    const handleChangeText = (newConfidence) => {
        setFilters(prev => ({
            ...prev,
            searchText: newConfidence
        }))
    }
    const handleChangeTextField = (newConfidence) => {
        setFilters(prev => ({
            ...prev,
            searchField: newConfidence
        }))
    }
    const handleReviewStatus = (status) => {
        setFilters(prev => ({
            ...prev,
            reviewed: status
        }))
    }

    return (
        <Box sx={{
            m: 2
        }}>
            <TableCard
                editors={editors}
                filters={filters}
                changeConfidence={handleChangeConfidence}
                changeText={handleChangeText}
                changeTextField={handleChangeTextField}
                changeReviewStatus={handleReviewStatus}
                changeReviewer={handleChangeReviewers}
                changeFilterOption={changeFilterOption}
                rowState={rowState}
                setRowState={setRowState}
                metaCols={metaTags}
                exportFeed={exportFeed}
                isExporting={isExporting}
            />
        </Box>
    )
}
