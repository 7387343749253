import React, {useState, useEffect} from "react";
import {ControlledJsonEditor} from "./ControlledJsonEditor.js";
import "./Workflow.scss";
import infoIcon from "./layout/circle-info.svg";
import DataService from "./DataService";
import {
    Grid,
    Button,
    Typography,
    Card, CardContent, CardActions
} from '@mui/material';
import {Toolbar} from "visuaui";

function Workflow({history}) {
    const initializer = [];
    const [settings, setSettings] = useState(initializer);
    const [isValid, setIsValid] = useState(true)

    useEffect(() => {
        DataService.getWorkflow().then(data => {
            setSettings(data.workflows.rules);
        })
    }, [])

    function handleSettingsSave() {
        DataService.postWorkflow(settings);
    }

    function handleEdits(e) {
        setSettings(e);
    }

    function onValidationError(errors) {
        if (!!errors.length) {
            setIsValid(false);
        } else {
            setIsValid(true);
        }
    }

    return (
        <Card raised={true}>
            <Toolbar removeBackground>
                <Typography
                    variant={'h6'}
                    component={'h2'}
                    sx={{
                        color: 'secondary.main'
                    }}
                >
                    Workflow
                </Typography>
            </Toolbar>
            <CardContent>
                <div className=" workflow-editor">
                    <ControlledJsonEditor
                        value={settings}
                        onChange={handleEdits}
                        errorsHandler={onValidationError}
                    />
                    {!isValid && (
                        <div className="workflow-editor-errors">
                            Invalid json format
                        </div>
                    )}
                </div>
            </CardContent>
            <CardActions>
                <Button
                    variant={'contained'}
                    onClick={handleSettingsSave}
                    disabled={!isValid}
                    sx={{mt:2, ml: 'auto'}}
                >
                    Save Changes
                </Button>
            </CardActions>

        </Card>
    );
}

export default Workflow;
