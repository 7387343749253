import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete, {autocompleteClasses} from '@mui/material/Autocomplete';
import useMediaQuery from '@mui/material/useMediaQuery';
import ListSubheader from '@mui/material/ListSubheader';
import {Tooltip} from "@mui/material";
import Popper from '@mui/material/Popper';
import {styled, useTheme} from '@mui/material/styles';
import {VariableSizeList} from 'react-window';
import Typography from '@mui/material/Typography';
import {useState} from "react";
import Label from './Label'
const LISTBOX_PADDING = 8; // px
const MAX_BRANDS_SELECTED = 50;

function renderRow(props) {
    const {data, index, style} = props;
    const dataSet = data[index];
    const inlineStyle = {
        ...style,
    };

    if (dataSet.hasOwnProperty('group')) {
        return (
            <ListSubheader
                key={dataSet.key}
                component="div"
                style={inlineStyle}
                sx={{
                    fontWeight: 'bold',
                    borderBottom: '1px solid #E5E9ED',
                }}
            >
                {dataSet.group}
            </ListSubheader>
        );
    }

    return (
        <Tooltip
            placement={'right'}
            title={
                <img src={dataSet[1].iconUrl} alt=""/>
            }
        >
            <Typography component="li" {...dataSet[0]} noWrap style={inlineStyle}>
                {dataSet[1].label}
            </Typography>
        </Tooltip>
    );
}

const OuterElementContext = React.createContext({});

const OuterElementType = React.forwardRef((props, ref) => {
    const outerProps = React.useContext(OuterElementContext);
    return <div ref={ref} {...props} {...outerProps} />;
});

function useResetCache(data) {
    const ref = React.useRef(null);
    React.useEffect(() => {
        if (ref.current != null) {
            ref.current.resetAfterIndex(0, true);
        }
    }, [data]);
    return ref;
}

// Adapter for react-window
const ListboxComponent = React.forwardRef(function ListboxComponent(props, ref) {
    const {children, ...other} = props;
    const itemData = [];
    children.forEach((item) => {
        itemData.push(item);
        itemData.push(...(item.children || []));
    });

    const theme = useTheme();
    const smUp = useMediaQuery(theme.breakpoints.up('sm'), {
        noSsr: true,
    });

    const itemCount = itemData.length;
    const itemSize = smUp ? 36 : 48;

    const getChildSize = (child) => {
        if (child.hasOwnProperty('group')) {
            return 48;
        }

        return itemSize;
    };

    const getHeight = () => {
        if (itemCount > 8) {
            return 8 * itemSize;
        }
        return itemData.map(getChildSize).reduce((a, b) => a + b, 0);
    };

    const gridRef = useResetCache(itemCount);

    return (
        <div ref={ref}>
            <OuterElementContext.Provider value={other}>
                <VariableSizeList
                    itemData={itemData}
                    height={getHeight() + 2 * LISTBOX_PADDING}
                    width="100%"
                    ref={gridRef}
                    outerElementType={OuterElementType}
                    innerElementType="ul"
                    itemSize={(index) => getChildSize(itemData[index])}
                    overscanCount={5}
                    itemCount={itemCount}
                >
                    {renderRow}
                </VariableSizeList>
            </OuterElementContext.Provider>
        </div>
    );
});


const StyledPopper = styled(Popper)({
    [`& .${autocompleteClasses.listbox}`]: {
        boxSizing: 'border-box',
        '& ul': {
            padding: 0,
            margin: 0,
        },
    },
});

export default function AutocompleteCheckboxBrands(
    {
        brands,
        id = "autocomplete-brands",
        onChange,
        onClose,
        disabled = false,
        ...other
    }
) {
    const [selectedLength, setSelectedLength] = useState(0);
    const [selectedIds, setSelectedIds] = useState([])
    return (
        <Autocomplete
            id={id}
            size={'small'}
            getOptionDisabled={(option) => {
                if (selectedLength >= MAX_BRANDS_SELECTED) {
                    return ! selectedIds.includes(option.id)
                }
            }

            }
            disabled={disabled}
            disableListWrap
            disableCloseOnSelect
            multiple
            PopperComponent={StyledPopper}
            ListboxComponent={ListboxComponent}
            options={brands}
            groupBy={(option) => option.label[0].toUpperCase()}
            renderInput={
                (params) =>
                    <TextField
                        size={'small'}
                        {...params}
                        placeholder="Type brand name"
                    />
            }
            renderTags={(value, getTagProps) => {
                const numTags = value.length;
                const brandTxt = numTags === 1? 'brand' : 'brands'

                return (
                    <Typography sx={{
                        marginBottom: 0,
                        fontSize: '12px',
                        color: '#02264D'
                    }}>
                        {`${numTags} ${brandTxt} selected`}
                    </Typography>
                );
            }}
            renderOption={
                (props, option) => [props, option]
            }
            renderGroup={(params) => params}
            onChange={(e, selectedBrands, changeType) => {
                const brandsIds = selectedBrands.map(x => {
                    const {id} = x;
                    if (id) return id
                    else return x
                });
                setSelectedIds(brandsIds);
                setSelectedLength(selectedBrands.length);
                if (onChange) onChange(brandsIds, changeType)
            }}
            onClose={_ => onClose? onClose(selectedIds) : undefined}
            sx={{
                width: 280,
                borderColor: '#8092A6',
                ".MuiSelect-select": {
                    padding: "8px 14px",
                    border: 0,
                },
                ".MuiAutocomplete-inputFocused": {
                    border: 0,
                },
                "& .MuiOutlinedInput-notchedOutline": {
                    border: "0 !important",
                    borderBottom: "1px solid #8092A6 !important",
                    borderRadius: "0"
                },
                "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                    borderBottom: "1px solid #8092A6",
                    borderRadius: "0"
                }, }
        }
            {...other}
        />
    );
}

