import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Table,
    TableHead,
    TableRow,
    TableCell,
    TableBody,
    Tooltip,
    IconButton,
    Stack,
} from '@mui/material'
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";

function AddImageSearchTable(
    {
        rows,
        handleRemoveItem,
    }
) {
    console.log(rows)
    return (
        <>
            <Table
                sx={{minWidth: 650, maxWidth: 1024}} aria-label="Brands Added Table" size="small">
                <TableHead>
                    <TableRow>
                        <TableCell
                            style={{width: "10%"}}
                        >
                            #
                        </TableCell>
                        <TableCell
                            style={{width: "40%"}}
                        >
                            Image Name
                        </TableCell>
                        <TableCell
                            style={{width: "30%"}}
                        >
                            Image Url / File Name
                        </TableCell>

                        <TableCell
                            style={{width: "5%"}}
                        >
                        </TableCell>
                        <TableCell
                            align={'center'}
                            style={{width: "5%"}}
                        >
                            Remove
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {rows.map((row, index) => (
                        <TableRow
                            key={index}
                        >
                            <TableCell
                                style={{width: "10%"}}
                            >
                                {index + 1}
                            </TableCell>
                            <TableCell
                                style={{width: "40%"}}
                            >
                                <Tooltip
                                    title={(row.name.length > 45) ? row.name : ''}
                                >
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'clip',
                                            width: '100%'
                                        }}
                                    >
                                        {row.name.slice(0, 45)}{(row.name.length > 45) ? "..." : ''}
                                    </div>
                                </Tooltip>
                            </TableCell>
                            <TableCell
                                style={{width: "30%"}}
                            >
                                {
                                    row.url.length > 0
                                    &&
                                    <Tooltip
                                        title={(row.url.length > 40) ? row.url : ''}
                                    >
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'clip',
                                                width: '100%'
                                            }}
                                        >
                                            {row.url.slice(0, 40)}{(row.url.length > 40) ? "..." : ''}
                                        </div>
                                    </Tooltip>
                                }

                                {
                                    row.fileName && row.fileName.length > 0
                                    &&
                                    <Tooltip
                                        title={(row.fileName.length > 40) ? row.url : ''}
                                    >
                                        <div
                                            style={{
                                                whiteSpace: 'nowrap',
                                                overflow: 'hidden',
                                                textOverflow: 'clip',
                                                width: '100%'
                                            }}
                                        >
                                            {row.fileName.slice(0, 40)}{(row.fileName.length > 40) ? "..." : ''}
                                        </div>
                                    </Tooltip>
                                }

                            </TableCell>
                            <TableCell>
                                <Stack direction="row">
                                    {
                                        (row.meta)
                                        && <LocalOfferIcon
                                            sx={{fontSize: '14px', color: '#8092A6'}}
                                        />
                                    }

                                    {
                                        (row.exampleUrls && row.exampleUrls.length > 0)
                                        && <ImageIcon
                                            sx={{fontSize: '14px', color: '#8092A6'}}
                                        />
                                    }

                                </Stack>
                            </TableCell>
                            <TableCell
                                align={'center'}
                                style={{width: "5%"}}
                            >
                                <IconButton onClick={_ => handleRemoveItem(row)}>
                                    <DeleteIcon/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}

AddImageSearchTable.propTypes = {}

AddImageSearchTable.defaultProps = {}

export default AddImageSearchTable;
