import React, {useState, useEffect} from "react";
import {DataGrid, useGridApiContext} from '@mui/x-data-grid';
import {Switch, Grid, Button, Box, Tooltip} from '@mui/material'
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import LinearProgress from '@mui/material/LinearProgress';
import CircularProgress from '@mui/material/CircularProgress';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import "./BandTable.css";

const SwitchActivationCell = (
    {
        params,
        activateBrand,
    }
) => {
    const {row} = params;
    const {logoVersionArray} = row;
    const totalLogosNumber = logoVersionArray.length;
    const checkedLogosNumber = logoVersionArray.filter(logo => logo.status === 1).length;
    //const isLoading = logoVersionArray.filter(logo => logo.status === -1).length > 0;
    const [status, setStatus] = useState(checkedLogosNumber >= 1);
    const [isLoading, setIsLoading] = useState(
        logoVersionArray.filter(logo => logo.status === -1).length > 0
    );

    let color = "default"
    if (checkedLogosNumber === totalLogosNumber) {
        color = "primary"
    }
    if (
        (checkedLogosNumber < totalLogosNumber)
        && (checkedLogosNumber > 0)
    ) {
        color = "warning"
    }


    return (
        <>
            {isLoading
                ? <CircularProgress/>
                : <Switch
                    checked={status}
                    color={color}
                    onChange={(event) => {
                        const check = event.target.checked;
                        activateBrand(check, row);
                        if (check) {
                            setIsLoading(true)
                        } else {
                            setStatus(false)
                        }
                    }}
                />
            }
        </>
    )

}


export default function BrandTable(
    {
        items,
        openModalBrand,
        loading,
        activateBrand,
    }
) {

    const columns = [
        {
            field: 'id',
            headerName: 'id',
            sortable: false,
        },
        {
            field: 'computedName',
            headerName: 'Brand',
            width: 350,
            renderCell: ({row}) => {
                const {computedName} = row;
                return (
                    <Button
                        fullWidth={true}
                        sx={{
                            textTransform: 'none',
                        }}
                        className={'d-flex'}
                        onClick={_ => {
                            openModalBrand(row, -1)
                        }}
                    >
                        <Grid container justifyContent={'space-between'}>
                            <Grid item>
                                {computedName}
                            </Grid>
                            <Grid item alignSelf={'flex-end'}>
                                <OpenInNewIcon/>
                            </Grid>
                        </Grid>
                    </Button>
                )
            }
        },
        {
            field: 'logoVersionArray',
            headerName: 'Logo Images',
            sortable: false,
            renderCell: (params) => {
                const {row} = params;
                const {logoVersionArray, id} = row;
                const images = logoVersionArray.map((logo, index) => {
                    const statusColor = logo.status === 1 ? "green" : "red";
                    const {meta} = logo;
                    const showLoading = logo.status === -1
                    let showTagIcon = false;
                    if (!!meta && !showLoading) {
                        const metaLength = Object.keys(meta).length;
                        if (metaLength > 1) showTagIcon = true;
                        if (metaLength === 1) {
                            if (!meta['.name']) showTagIcon = true;
                        }
                    }

                    return (
                        <div
                            className={'BrandTableImgContainer'}
                            key={`${logo.name}-${index}`}
                            onClick={_ => {
                                console.log({row, index})
                                openModalBrand(row, index)
                            }}
                        >
                            <img
                                src={logo.iconUrl}
                                alt={logo.name}
                            />
                            {
                                showLoading
                                    ? <CircularProgress
                                        size={15}
                                        sx={{
                                            position: 'absolute',
                                            right: '5px',
                                            bottom: '5px',
                                        }}
                                    />
                                    : <div
                                        className={`statusCircle ${statusColor}`}
                                        onClick={ev => {
                                            ev.preventDefault();
                                            ev.stopPropagation();
                                            activateBrand(
                                                logo.status !== 1,
                                                row,
                                                logo,
                                            )
                                        }
                                        }
                                    />

                            }

                            {
                                showTagIcon && <LocalOfferIcon color={'primary'} sx={{
                                    position: "absolute",
                                    bottom: "5px",
                                    right: 0,
                                    fontSize: "11px",
                                    left: "20px",
                                }}/>
                            }

                        </div>
                    )
                })

                return (
                    <div className={'BrandTable--logoRow'}>
                        {images}
                    </div>
                )
            },
            flex: 1,
        },
        {
            field: 'status',
            headerName: 'Toggle',
            width: 150,
            sortable: false,
            renderCell: (params) => {
                return (
                    <SwitchActivationCell
                        params={params}
                        activateBrand={activateBrand}
                    />
                )
            }
        },
    ]
    const [pageSize, setPageSize] = React.useState(50);

    return (
        <div style={{width: "100%", height: "calc(15 * 80px)"}}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-columnHeaders': {
                        position: "sticky",
                        // Display header above grid data, but below any popups
                    },
                    '& .MuiDataGrid-virtualScroller': {
                        // Undo the margins that were added to push the rows below the previously fixed header
                        marginTop: "0 !important"
                    },
                    '& .MuiDataGrid-main': {
                        // Not sure why it is hidden by default, but it prevented the header from sticking
                        overflow: "visible"
                    }
                }}
                initialState={{
                    sorting: {
                        sortModel: [{field: 'computedName', sort: 'asc'}],
                    },
                }}
                rows={items}
                columns={columns}
                pageSize={pageSize}
                onPageSizeChange={(newPageSize) => setPageSize(newPageSize)}
                rowsPerPageOptions={[10, 50, 100]}
                disableSelectionOnClick
                getRowId={(row) => row.tableId}
                loading={loading}
                columnVisibilityModel={{
                    id: false,
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                rowHeight={80}
            />
        </div>
    )
}
