import React from 'react';
import ReactDOM from 'react-dom';
// @ts-ignore - Import declaration conflicts with local declaration???
import toastr from "toastr"
import "toastr/build/toastr.css";

// Global import of Font Awesome
import "font-awesome/css/font-awesome.css";
import "c3/c3.css";
import "daterangepicker/daterangepicker.css";

import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

toastr.options.positionClass = 'toast-bottom-right';
ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
