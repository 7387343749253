import React, {useReducer, useState} from "react";
import PropTypes from 'prop-types';
import {
    Box,
    Button,
    Card,
    CardContent,
    Collapse,
    Grid,
    IconButton,
    Skeleton,
    Switch,
    TextField,
    Typography,
} from '@mui/material'
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import InsertPhotoIcon from "@mui/icons-material/InsertPhoto";
import AddIcon from "@mui/icons-material/Add";
import AnimatedList from "./animatedList/AnimatedList";
import AnimatedDoubleList from "./animatedList/AnimatedDoubleList";
import {niceBytes} from "../Helper/SharedFunctions";

function CollapseExamplesCard(
    {
        isAFile,
        setIsAFile,
        examples,
        setExamples,
    }
) {

    const [exampleUrlText, setExampleUrlText] = useState('');
    const [openExamplesCard, setOpenExamplesCard] = useState(false);
    const [isLoadingTheFile, setIsLoadingTheFile] = useState(false);

    function onExampleFileChange(fileList) {
        if (fileList.length === 0) {
            return;
        }
        setIsLoadingTheFile(true);
        const reader = new FileReader();
        const fileListArray = Array.from(fileList);
        fileListArray.forEach((file) => {
            reader.onloadend = function () {
                const newFile = {
                    base64: reader.result,
                    name: file.name,
                    size: file.size,
                };
                setExamples(
                    [
                        ...examples,
                        newFile
                    ]
                );
            };
            reader.readAsDataURL(file);
        })
        setIsLoadingTheFile(false);
    }

    return (
        <Card
            sx={{
                my: 1,
            }}
        >
            <Grid
                container
                alignItems={'center'}
                sx={{
                    p: 1,
                }}

            >
                <Grid
                    item
                    xs={5}
                    onClick={_ => {
                        setOpenExamplesCard(
                            !openExamplesCard
                        )
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Example Images
                    </Typography>
                </Grid>
                {
                    openExamplesCard
                        ?
                        <Grid
                            item
                            sx={{
                                ml: 'auto',
                            }}
                        >
                            <Grid container>
                                <Grid item xs={12}>
                                    <Grid
                                        component="label"
                                        container
                                        alignItems="center"
                                        justifyContent={'center'}
                                        sx={{mb: 0}}
                                    >
                                        <Grid
                                            item
                                        >
                                            <Typography
                                                variant={'body1'}
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    color: !isAFile ? 'primary.main' : "text.disabled"
                                                }}
                                            >
                                                URL
                                            </Typography>
                                        </Grid>
                                        <Grid
                                            item
                                        >
                                            <Switch
                                                size={'small'}
                                                checked={isAFile}
                                                onChange={event => {
                                                    setExamples([])
                                                    const {checked} = event.target;
                                                    setIsAFile(checked)
                                                }}
                                            />
                                        </Grid>
                                        <Grid
                                            item
                                        >
                                            <Typography
                                                variant={'body1'}
                                                sx={{
                                                    fontSize: '0.8rem',
                                                    color: isAFile ? 'primary.main' : "text.disabled"
                                                }}
                                            >
                                                Files
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                        : null
                }
                <Grid
                    item
                    sx={{ml: openExamplesCard ? 0 : 'auto'}}
                >
                    <IconButton
                        onClick={_ => {
                            setOpenExamplesCard(
                                !openExamplesCard
                            )
                        }}
                        sx={{
                            ml: 'auto'
                        }}
                    >
                        {
                            openExamplesCard
                                ? <KeyboardArrowUpIcon/>
                                : <KeyboardArrowDownIcon/>
                        }
                    </IconButton>
                </Grid>
            </Grid>
            <Collapse in={openExamplesCard}>
                <CardContent
                    sx={{pt: 0}}
                >
                    <Grid container>
                        {
                            isAFile?
                                <>
                                    <Grid
                                        item
                                        xs={12}
                                        textAlign={'center'}

                                    >
                                        <label
                                            htmlFor="contained-button-file"
                                        >
                                            <input
                                                style={{display: 'none'}}
                                                accept="image/*"
                                                id="contained-button-file"
                                                type="file"
                                                onChange={e => {
                                                    onExampleFileChange(e.target.files)
                                                    e.target.value = '';
                                                }}
                                            />
                                            <Button
                                                type="submit"
                                                variant="contained"
                                                component="span"
                                                startIcon={
                                                    <InsertPhotoIcon/>
                                                }
                                                disabled={isLoadingTheFile}
                                            >
                                                {(isLoadingTheFile)
                                                    ? <Skeleton
                                                        variant={'text'}
                                                        children={
                                                            <span>Upload</span>
                                                        }
                                                    />
                                                    : 'Upload'
                                                }
                                            </Button>
                                        </label>

                                    </Grid>
                                </>
                                :
                                <>
                                    <Grid item xs={11}>
                                        <TextField
                                            id="standard-basic"
                                            label="Example Image URL*"
                                            placeholder={'E.g. https://coca-cola.com/logo.png'}
                                            variant="standard"
                                            fullWidth
                                            value={exampleUrlText}
                                            onChange={
                                                e => {
                                                    return setExampleUrlText(e.target?.value)
                                                }
                                            }
                                        />
                                    </Grid>
                                    <Grid
                                        item
                                        xs={1}
                                        textAlign={'center'}
                                    >
                                        <IconButton
                                            color={'success'}
                                            disabled={exampleUrlText.length === 0}
                                            onClick={_ => {
                                                setExamples(
                                                    [
                                                        ...examples,
                                                        exampleUrlText
                                                    ]
                                                );
                                                setExampleUrlText('');
                                            }}
                                        >
                                            <AddIcon/>
                                        </IconButton>
                                    </Grid>
                                </>
                        }
                    </Grid>
                    {

                        !isAFile
                            ? <AnimatedList
                                items={examples}
                                removeListItem={
                                    (item) => {
                                        setExamples(
                                            examples.filter((i) => i !== item)
                                        );
                                    }
                                }
                            />
                            : (
                                    <AnimatedDoubleList
                                        items={examples.map(el => el.name)}
                                        subtitles={examples.map(el => niceBytes(el.size))}
                                        removeListItem={(item) => {
                                            setExamples(
                                                examples.filter((i) => i.name !== item)
                                            );
                                        }}
                                    />
                            )
                    }
                </CardContent>
            </Collapse>
        </Card>

    )
}

CollapseExamplesCard.propTypes = {
    isAFile: PropTypes.bool,
    setIsAFile: PropTypes.func,
    examples: PropTypes.array,
    setExamples: PropTypes.func,
}

CollapseExamplesCard.defaultProps = {}

export default CollapseExamplesCard;
