import * as React from 'react';
import {
    Box,
    Dialog,
    Tooltip,
    Grid, IconButton,
    Typography, Skeleton,
} from '@mui/material';
import './DetectionImageModal.css'
import {styled} from '@mui/system';
import Chip from '@mui/material/Chip';
import CloseIcon from "@mui/icons-material/Close";
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import moment from "moment";
import BoundContainer from "../BoundingBox/BoundingBox";
import {useState, useEffect} from "react";

const StyleChip = styled(
    (props) => (
        <Chip
            {...props}
        />
    )
)({
    fontSize: '12px',
    fontWeight: 'bold',
    margin: 0,
    marginRight: '5px',
});


const IconBrand = () => {
    return (
        <svg
            width="15px"
            height="14px"
            viewBox="0 0 15 14"
            version="1.1"
            xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M6.61399049,12.2158028 L5.00191268,13.0633222 C4.32101197,13.4212929 3.4788403,13.1595064 3.12086961,12.4786057 C2.97832381,12.2074675 2.92913479,11.8969002 2.98091758,11.5949833 L3.28879705,9.79990831 C3.39473168,9.182262 3.18995937,8.55203762 2.74121306,8.11461808 L1.43701471,6.84333889 C0.886154462,6.30638286 0.874882869,5.42453352 1.4118389,4.87367328 C1.62565761,4.65431784 1.90582438,4.51156574 2.20896614,4.46751666 L4.01132394,4.20561874 C4.63147608,4.11550536 5.16757696,3.72600527 5.44491743,3.1640521 L6.25095634,1.53083889 C6.59140669,0.841011121 7.42661213,0.557784769 8.1164399,0.898235125 C8.39113293,1.03380424 8.61347454,1.25614585 8.74904366,1.53083889 L9.55508257,3.1640521 C9.83242304,3.72600527 10.3685239,4.11550536 10.9886761,4.20561874 L12.7910339,4.46751666 C13.552304,4.57813569 14.0797609,5.28494151 13.9691419,6.04621164 C13.9250928,6.34935341 13.7823407,6.62952018 13.5629853,6.84333889 L12.2587869,8.11461808 C11.8100406,8.55203762 11.6052683,9.182262 11.711203,9.79990831 L12.0190824,11.5949833 C12.1491229,12.3531774 11.6399038,13.0732338 10.8817097,13.2032743 C10.5797928,13.2550571 10.2692256,13.205868 9.99808732,13.0633222 L8.38600951,12.2158028 C7.83132856,11.9241897 7.16867144,11.9241897 6.61399049,12.2158028 Z" id="Star-Copy-3" stroke="#E5503F" strokeWidth="1.7"></path>
            </g>
        </svg>
    )
}


const IconText = () => {
    return (
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g  stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g id="Group-14" fillRule="nonzero">
                    <path d="M10.7230232,13.9319741 C11.3122012,13.9319741 11.8831304,13.8131807 12.4358106,13.5755939 L12.4358106,13.5755939 L12.6078714,12.0880939 L11.5442227,12.1655678 C11.2418127,12.1655678 11.0906077,12.0209498 11.0906077,11.7317137 C11.0906077,11.5044567 11.1323194,11.2152206 11.2157429,10.8640053 L11.2157429,10.8640053 L11.93527,7.765047 L13.562027,7.765047 L14,5.99864075 L12.3576011,5.99864075 L12.8112161,4.01530742 L10.7152023,4.01530742 L10.2615874,5.99864075 L9.46385076,5.99864075 L8.99459394,7.765047 L9.83925622,7.765047 L9.15101288,10.6780678 C8.99459394,11.3185192 8.91638448,11.8634194 8.91638448,12.3127684 C8.91638448,12.7621173 9.07019643,13.1443222 9.37782035,13.4593829 C9.68544426,13.7744437 10.1338452,13.9319741 10.7230232,13.9319741 Z" id="t-copy" fill="#96A4B4"></path>
                    <path d="M6.49475928,14 C6.87825935,14 7.18753361,13.9618529 7.42258204,13.8855586 C7.65763047,13.8092643 7.82154582,13.6789282 7.9143281,13.4945504 C8.00711037,13.3101726 8.06277974,13.1480472 8.08133619,13.0081744 C8.09989265,12.8683015 8.10917088,12.6521344 8.10917088,12.359673 L8.10917088,2.76566757 L11.6534538,2.76566757 C12.0616958,2.76566757 12.3771555,2.70844687 12.599833,2.59400545 C12.7482846,2.51771117 12.8534379,2.38101726 12.9152927,2.18392371 C12.9771476,1.98683015 13.008075,1.72297911 13.008075,1.39237057 C13.008075,1.06176203 12.9771476,0.794732062 12.9152927,0.591280654 C12.8534379,0.387829246 12.7297282,0.238419619 12.5441636,0.143051771 C12.3585991,0.0476839237 12.0678813,0 11.6720103,0 L1.35462122,0 C0.94637921,0 0.630919473,0.0572207084 0.408242012,0.171662125 C0.259790371,0.247956403 0.154637126,0.384650318 0.0927822755,0.581743869 C0.0309274252,0.778837421 0,1.04268847 0,1.373297 C0,1.70390554 0.0309274252,1.97093551 0.0927822755,2.17438692 C0.154637126,2.37783833 0.278346826,2.52724796 0.463911377,2.6226158 C0.649475928,2.71798365 0.940193725,2.76566757 1.33606477,2.76566757 L4.89890414,2.76566757 L4.89890414,12.3405995 C4.89890414,12.6330609 4.90818237,12.8524069 4.92673883,12.9986376 C4.94529528,13.1448683 5.00096465,13.3101726 5.09374692,13.4945504 C5.1865292,13.6789282 5.34735181,13.8092643 5.57621476,13.8855586 C5.8050777,13.9618529 6.11125921,14 6.49475928,14 Z" id="T-Copy-3" fill="#02264D"></path>
                </g>
            </g>
        </svg>
    )
}

const IconObject = () => {
    return (
        <svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
            <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <path d="M7,0 C7.15585938,0 7.31171875,0.0212717285 7.46296387,0.0637113647 L7.6125,0.113203125 L13.43125,2.33269531 C13.7350694,2.44863281 13.9481481,2.72336661 13.9917481,3.04416693 L14,3.16640625 L14,10.8335937 C14,11.1641493 13.820679,11.4622975 13.5421193,11.6163647 L13.4331641,11.667168 L7.61441406,13.8866602 C7.41644531,13.9621836 7.2078125,14 7,14 C6.84414062,14 6.68828125,13.9784668 6.53657471,13.9359772 L6.38640625,13.8865234 L0.56765625,11.6670313 C0.264152778,11.5513368 0.0516925154,11.2759819 0.00822608025,10.9556166 L0,10.8335937 L0,3.16640625 C0,2.83585069 0.178737654,2.53727045 0.457110082,2.3834913 L0.566015625,2.33283203 L6.38476562,0.113339844 C6.584375,0.0378164063 6.7921875,0 7,0 Z M1.799,5.187 L1.8,10.21 L6.1,11.849 L6.1,6.838 L1.799,5.187 Z M12.199,5.189 L7.899,6.839 L7.899,11.849 L12.2,10.211 L12.199,5.189 Z M6.999,1.806 L2.49,3.525 L7.001,5.255 L11.511,3.526 L6.999,1.806 Z" id="Combined-Shape" fill="#F1BE48" fillRule="nonzero"></path>
            </g>
        </svg>
    )
}

export default function DetectionImageModal(
    {
        open,
        handleClose,
        feed,
        copyToClip,
    }) {
    const [activeDetection, setActiveDetection] = useState(false);
    const [currentDetection, setCurrentDetection] = useState(null)

    let imgUrl = '';
    let date;
    let feedDetections = [
        [], // Logo
        [], // Object
        [], // Text
    ];

    let rowColors = ['#F2F4F6', "#FFF", "#F2F4F6"];
    let imgScalar = 1;
    let height = 500;


    useEffect(_ => {
        if (open) {
            setActiveDetection(false);
            setCurrentDetection(null);
        }
    }, [open])


    if (feed) {
        const {
            mediaUrl,
            detections,
            timeCreated,
            mediaInfo,
        } = feed;
        imgUrl = mediaUrl;
        imgScalar = 500 / mediaInfo.width;
        height = mediaInfo.height * imgScalar;

        if (timeCreated) {
            date = moment(timeCreated).format('MMM Do hh:mm');
        }
        detections.forEach(detection => {
            const {
                type,
            } = detection;
            switch (type) {
                case 'logo':
                    feedDetections[0].push(detection);
                    break;
                case 'object':
                    feedDetections[1].push(detection);
                    break;
                case 'text':
                    feedDetections[2].push(detection);
                    break;
            }
        })
    }

    if (feedDetections[0].length === 0) {
        if (feedDetections[1].length !== 0) {
            rowColors[1] = "#F2F4F6";
            rowColors[2] = "#FFF";
        } else {
            rowColors[2] = "#F2F4F6";
        }
    } else {
        if (feedDetections[1].length !== 0) {
            rowColors[1] = "#FFF";
            rowColors[2] = "#F2F4F6";
        } else {
            rowColors[2] = "#FFF";
        }
    }

    return (
        <Dialog
            fullWidth={true}
            maxWidth={'xl'}
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box>
                <Grid container>
                    <Grid item xs={6} sx={{background: '#f2f2f2', p: 2, textAlign: 'center'}}>

                        <BoundContainer
                            src={imgUrl}
                            width={500}
                            height={height}
                            activeDetection={activeDetection}
                            detection={currentDetection}
                            scalar={imgScalar}
                        />
                        {/*
                        <img
                            src={imgUrl}
                            alt=""
                            style={{
                                width: '500px',
                                position: 'sticky',
                                top: 0,
                            }}
                        />
                        */}
                    </Grid>
                    <Grid item xs={6}>
                        <Grid
                            container={true}
                            justifyContent={'space-between'}
                            alignItems={'center'}
                            sx={{
                                p: 3,
                            }}
                        >
                            <Grid item>
                                <Typography
                                    variant="body1"
                                    component="h2"
                                    color={'info'}
                                    sx={{
                                        color: '#8092A6',
                                    }}
                                >
                                    {date}
                                </Typography>
                            </Grid>
                            <Grid item>
                                <Tooltip
                                    title={'Copy Url to clipboard'}
                                >
                                    <IconButton
                                        onClick={_ => copyToClip()}
                                    >
                                        <ContentCopyIcon/>
                                    </IconButton>
                                </Tooltip>

                                <IconButton
                                    onClick={_ => handleClose()}
                                >
                                    <CloseIcon/>
                                </IconButton>
                            </Grid>
                        </Grid>
                        {
                            feedDetections[0].length > 0 &&
                            <Box
                                sx={{
                                    backgroundColor: rowColors[0],
                                    p: 2,
                                }}
                            >
                                <Grid container={true}>
                                    <Grid item xs={1}>
                                        <IconBrand />
                                    </Grid>
                                    <Grid item xs={11} className={''}>
                                        {
                                            feedDetections[0].map((el, index) => {
                                                return (
                                                    <div
                                                        style={{
                                                            float: 'left',
                                                            marginRight: '5px',
                                                            marginBottom: '5px',
                                                            display: "flex",
                                                            flexDirection: "column",
                                                            justifyContent: "center",
                                                            alignItems: "center",
                                                        }}
                                                        key={index}
                                                        onMouseEnter={_ => {
                                                            setActiveDetection(true);
                                                            setCurrentDetection(el);
                                                        }}
                                                        onMouseLeave={_ => {
                                                            setActiveDetection(false);
                                                        }}
                                                    >
                                                        <div style={{
                                                            background: '#f2f2f2',
                                                            padding: '5px',
                                                            width: '60px',
                                                            height: '60px',
                                                            display: 'flex',
                                                            justifyContent: 'center',
                                                            alignItems: 'center',
                                                        }}>
                                                            <img width={'100%'} src={el.iconUrl} alt=""/>
                                                        </div>
                                                        <p style={{
                                                            fontSize: '10px',
                                                            color: '#d32f2f',
                                                            marginBottom: 0
                                                        }}>
                                                            {`${el.name} ${el.confidence}`}
                                                        </p>
                                                    </div>
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>

                            </Box>
                        }

                        {
                            feedDetections[1].length > 0 &&
                            <Box
                                sx={{
                                    backgroundColor: rowColors[1],
                                    p: 2,
                                }}
                            >
                                <Grid container={true}>
                                    <Grid item xs={1}>
                                        <IconObject />
                                    </Grid>
                                    <Grid item xs={11}>
                                        {
                                            feedDetections[1].map((el, index) => {
                                                return (
                                                    <StyleChip
                                                        color={'warning'}
                                                        size={'small'}
                                                        label={`${el.name} ${el.confidence}`}
                                                        key={`${index}`}
                                                        onMouseEnter={_ => {
                                                            if(el.confidence < 1) {
                                                                setActiveDetection(true);
                                                                setCurrentDetection(el);
                                                            }
                                                        }}
                                                        onMouseLeave={_ => {
                                                            setActiveDetection(false);
                                                        }}
                                                    />
                                                )
                                            })
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        }

                        {
                            feedDetections[2].length > 0 &&
                            <Box
                                sx={{
                                    backgroundColor: rowColors[2],
                                    p: 2,
                                }}
                            >
                                <Grid container={true}>
                                    <Grid item xs={1}>
                                        <IconText/>
                                    </Grid>
                                    <Grid item xs={11}>
                                        {
                                            feedDetections[2].map((el, index) =>  (
                                                    <StyleChip
                                                        color={'secondary'}
                                                        sx={{m: "2px",}}
                                                        size={'small'}
                                                        label={`${el.text} ${el.confidence}`}
                                                        key={`${index}`}
                                                        onMouseEnter={_ => {
                                                            setActiveDetection(true);
                                                            setCurrentDetection(el);
                                                        }}
                                                        onMouseLeave={_ => {
                                                            setActiveDetection(false);
                                                        }}
                                                    />
                                                )
                                            )
                                        }
                                    </Grid>
                                </Grid>
                            </Box>
                        }

                    </Grid>
                </Grid>
            </Box>
        </Dialog>
    );
}
