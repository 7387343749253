import * as React from 'react';
import {DataGrid} from '@mui/x-data-grid';
import Box from '@mui/material/Box';
import {useState, useEffect} from "react";
import {Typography} from "@mui/material";
import ErrorIcon from "@mui/icons-material/Error";

function PreviewCSVTable(
    {
        cols = [],
        data = [],
        error,
        parent,
        errorText,
    }
) {
    const [height, setHeight] = useState(600);
    useEffect(() => {
        if (parent && parent.current) {
            setHeight(parent.current.clientHeight)
        } else {
            setHeight(800)
        }
    })

    return (
        <Box sx={{height: height - 20, width: '100%'}}>
            {
                !error ?
                    <DataGrid
                        rows={data}
                        columns={cols}
                        pageSize={100}
                        rowsPerPageOptions={[100]}
                        disableSelectionOnClick
                        sx={{
                            '& .MuiDataGrid-cell': {
                                lineHeight: 'unset !important',
                                maxHeight: 'none !important',
                                whiteSpace: 'normal',
                                fontSize: '12px',
                                wordBreak: "break-all",
                                alignItems: 'baseline',
                                paddingTop: '15px',
                                paddingBottom: '15px',
                            },
                        }}
                    />
                    : <Box
                        sx={{
                            textAlign: 'center',
                        }}
                    >
                        <ErrorIcon
                            color={'error'}
                            sx={{
                                mt: 4,
                                fontSize: '4rem',
                            }}
                        />
                        <Typography
                            variant={'h4'}
                            color={'error.main'}
                        >
                            Uploaded File Missing Required Elements.
                        </Typography>
                        <Typography
                            variant={'h6'}
                            color={'error.main'}
                        >
                            Please Close This Window, Correct The Source File, And Re-Upload.
                        </Typography>
                        {
                            !!errorText &&
                            <Typography
                                variant={'body1'}
                                color={'error.main'}
                            >
                                {errorText}

                            </Typography>
                        }

                    </Box>
            }

        </Box>
    )
}


export default PreviewCSVTable;
