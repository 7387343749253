import React, {useEffect, useState} from "react";
import Autocomplete from "@mui/material/Autocomplete";
import {Avatar, Button, IconButton, TextField, Tooltip} from "@mui/material";
import {Autorenew, BugReport, OpenInNew} from "@material-ui/icons";
import {DataGridPro} from '@mui/x-data-grid-pro';
import {toast} from 'react-toastify';
import SearchBar from "./search-bar";
import DataService from "../DataService";
import {useLocalStorage} from "usehooks-ts";
import {isAxiosError} from 'axios';

const PRIORITY_COLOR_MAP = new Map();
PRIORITY_COLOR_MAP.set('5', {color: '#FF0003', blcTxt: false});
PRIORITY_COLOR_MAP.set('4', {color: '#FE5B01', blcTxt: false});
PRIORITY_COLOR_MAP.set('3', {color: '#FCCE03', blcTxt: true});
PRIORITY_COLOR_MAP.set('2', {color: '#CC33AA', blcTxt: false});
PRIORITY_COLOR_MAP.set('1', {color: '#5F13FF', blcTxt: false});
PRIORITY_COLOR_MAP.set('0', {color: '#A3795C', blcTxt: false});
PRIORITY_COLOR_MAP.set('-1', {color: '#D9D9D9', blcTxt: true});
PRIORITY_COLOR_MAP.set('-2', {color: '#B6B6B6', blcTxt: true});
PRIORITY_COLOR_MAP.set('-3', {color: '#8E8E8E', blcTxt: false});
PRIORITY_COLOR_MAP.set('-4', {color: '#5B5B5B', blcTxt: false});
PRIORITY_COLOR_MAP.set('-5', {color: '#1F1F1F', blcTxt: false});

const INITIAL_SORT_MODEL = [
    {
        field: 'project',
        sort: 'asc',
    },
    {
        field: 'location',
        sort: 'asc',
    },
];

export default function PipelineTable(
    {
        rows = [],
        error,
        isLoading,
        updateData,
        type,
    }
) {
    const [searchText, setSearchText] = useState('');
    const [isUpdating, setIsUpdating] = useState(false);
    const [stadiumFilter, setStadiumFilter] = useLocalStorage(`stadiumFilter-${type}`, [])
    const [projectFilter, setProjectFilter] = useLocalStorage(`projectFilter-${type}`, [])
    const [stageFilter, setStageFilter] = useLocalStorage(`stageFilter-${type}`, [])
    const [sortModel, setSortModel] = useLocalStorage(`iannDataTableSortingModel-${type}`, INITIAL_SORT_MODEL);
    const projectFilterOptions = Array
        .from(new Set(rows.map((el) => el?.project)))
        .map(
            (el, index) => ({id: index, label: el}),
        )
        .sort(
            (a, b) => {
                if (typeof a.label === 'string') {
                    return a?.label?.localeCompare(b?.label)
                }
                return 0
            }
        ) ?? [];
    const stadiumFilterOptions = Array
        .from(
            new Set(
                rows
                    .filter((el) => !!projectFilter?.find(
                        (filterP) => filterP.label === el.project,
                    ))
                    .map((el) => el.location),
            ),
        )
        .map(
            (el, index) => ({
                id: index,
                label: el,
            }),
        )
        .sort(
            (a, b) => a.label.localeCompare(b.label)
        )

    const stageFilterOptions = Array
        .from(new Set(rows.map((el) => el?.stage)))
        .map(
            (el, index) => ({id: index, label: el}),
        )
        .sort(
            (a, b) => {
                if (typeof a.label === 'string') {
                    return a?.label?.localeCompare(b?.label)
                }
                return 0
            }
        );

    const columns = [
        {
            field: 'priority',
            headerName: 'Priority',
            editable: true,
            type: 'singleSelect',
            width: 70,
            valueOptions: [-5, -4, -3, -2, -1, 0, 1, 2, 3, 4, 5],
            valueGetter: (params) => params.row.priority,
            renderCell: (params) => {
                const val = params.row.priority;
                let color = '#FBC02D';
                let txt = '#fff';
                const getColor = PRIORITY_COLOR_MAP.get(String(val));
                if (getColor) {
                    color = getColor.color;
                    if (getColor.blcTxt) txt = '#000';
                }
                return (
                    <Avatar
                        sx={{
                            width: 24,
                            height: 24,
                            fontSize: '.9rem',
                            bgcolor: color,
                            color: txt,
                        }}
                    >
                        {params.row.priority}
                    </Avatar>
                );
            },
        },
        {
            field: 'project',
            headerName: 'Project',
            width: 200,
        },
        {
            field: 'location',
            headerName: 'Location',
            width: 200,
            renderCell: ((params) => {
                const {row} = params;
                const {
                    project, location, sessionId, batch,
                } = row;
                const str = `${project} - ${location} - ${sessionId} - ${batch ?? ''}`;
                return (
                    <Button
                        onClick={() => {
                            navigator.clipboard
                                .writeText(str)
                                .then(() => toast.info('Project, location, mediaId, and sessionId copied to clipboard'))
                                .catch(() => toast.error('Error in accessing the clipboard'));
                        }}
                        className="text-primary cursor-pointer decoration-primary decoration-1 underline hover:text-secondary"
                        sx={{
                            textTransform: 'initial',
                        }}
                    >
                        {location}
                    </Button>
                );
            }),
        },
        {
            field: 'batch',
            headerName: 'Batch–seq',
            width: 85,
        },
        {
            field: 'sessionId',
            headerName: 'Session ID',
            width: 120,
            renderCell: (params) => {
                const {row} = params;
                const {sessionId} = row;
                return (
                    <Tooltip title={(
                        <div>
                            <Button
                                target="_blank"
                                href={`https://s3-eu-west-1.amazonaws.com/s3.logograb.com/pub/rendered-media/${sessionId}/output.csv`}
                            >
                                CSV
                            </Button>
                        </div>
                    )}
                    >
            <span
                className="text-success cursor-alias decoration-primary decoration-1 underline hover:text-secondary"
            >
              {sessionId}
            </span>
                    </Tooltip>
                );
            },
        },
        {
            field: 'stage',
            headerName: 'Stage',
        },
        {
            field: 'creationData',
            headerName: 'Date submitted (UTC)',
            width: 160,
            type: 'date',
            valueGetter: (params) => new Date(params.row.creationData),
            renderCell: (params) => {
                const d = params.row.creationData
                let yourDate = new Date(d);
                return yourDate.toISOString().split('T')[0]
            },
        },
        {
            field: 'fixtureName',
            headerName: 'Fixture Name',
            width: 350,
        },
        {
            field: 'broadcaster',
            headerName: 'Broadcaster',
            width: 140,
        },
        {
            field: 'fixtureTime',
            headerName: 'Fixture Time',
        },
        {
            field: 'homeTeam',
            headerName: 'Home Team',
            width: 160,
        },
        {
            field: 'visitingTeam',
            headerName: 'Visiting Team',
            width: 160,
        },
        {
            field: 'frameRate',
            headerName: 'Frame Rate',
            type: 'number',
        },
        {
            field: 'resolution',
            headerName: 'Resolution',
        },
        {
            field: 'hours',
            headerName: 'Hours',
            type: 'number',
            width: 80,
        },
        {
            field: 'seasonStage',
            headerName: 'Season Stage',
            width: 140,
        },
        {
            field: 'fixtureType',
            headerName: 'Fixture Type',
        },
        {
            field: 'market',
            headerName: 'Market',
        },
        {
            field: 'spotRate',
            headerName: 'Spot Rate',
        },
        {
            field: 'mediaUrl',
            headerName: 'Media Url',
            renderCell: params => (
                <IconButton
                    onClick={() => window.open(params.row.mediaUrl, '_blank')}
                    onContextMenu={(e) => {
                        e.preventDefault();
                        if (params.row.mediaUrl.length > 0) {
                            navigator.clipboard.writeText(params.row.mediaUrl)
                                .then(() => toast('Media Url copied to clipboard'))
                                .catch(() => toast.error('Something went wrong'));
                        }
                    }}
                >
                    <OpenInNew/>
                </IconButton>
            )
        },
    ]

    const getRowsToRender = () => {
        if (searchText.length === 0) {
            return rows
                .filter((el) => {
                    if (!!projectFilter && projectFilter?.length > 0) {
                        return !!projectFilter.find(
                            (filterP) => filterP.label === el.project,
                        );
                    }
                    return true;
                })
                .filter((el) => {
                    if (!!stadiumFilter && stadiumFilter?.length > 0) {
                        return !!stadiumFilter.find(
                            (filterP) => filterP.label === el.location,
                        );
                    }
                    return true;
                })
                .filter((el) => {
                    if (!!stageFilter && stageFilter?.length > 0) {
                        return !!stageFilter.find(
                            (filterP) => filterP.label === el.stage,
                        );
                    }
                    return true;
                });
        } else {
            return rows.filter((el) => (
                String(el.mediaId).indexOf(searchText) !== -1
                || el?.sessionId?.indexOf(searchText) !== -1
            ));
        }

    }

    return (
        <div style={{
            margin: '16px',
            padding: '8px',
            borderRadius: '0.25rem',
            overflow: 'hidden',
            boxShadow: '0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)',
            backgroundColor: 'white'
        }}>
            {
                error && (
                    <div>
                        {
                            isAxiosError(error) ? (
                                <section>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '2rem 1.5rem',
                                        margin: '0 auto'
                                    }}>
                                        <div>
                                            <p
                                                style={{
                                                    fontSize: '0.875rem',
                                                    fontWeight: '500',
                                                    color: '#1DB8E0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <BugReport/>
                                                Network Error
                                            </p>
                                            <h1 style={{
                                                marginTop: '0.75rem',
                                                fontSize: '1.5rem',
                                                fontWeight: 'semibold',
                                                color: 'rgb(211, 47, 47)' // Replace with actual color value
                                            }}>
                                                The dashboard is offline. Something went wrong.
                                            </h1>
                                            <p style={{marginTop: '1rem'}}>
                                                <strong>{error.code}</strong>: {' '}
                                                {error.message}
                                            </p>
                                        </div>
                                    </div>
                                </section>
                            ) : (
                                <section>
                                    <div style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        padding: '2rem 1.5rem',
                                        margin: '0 auto'
                                    }}>
                                        <div>
                                            <p
                                                style={{
                                                    fontSize: '0.875rem',
                                                    fontWeight: '500',
                                                    color: '#1DB8E0',
                                                    display: 'flex',
                                                    alignItems: 'center',
                                                }}
                                            >
                                                <BugReport/> Unknown Error
                                            </p>
                                            <h1 style={{
                                                marginTop: '0.75rem',
                                                fontSize: '1.5rem',
                                                fontWeight: 'semibold',
                                                color: 'rgb(211, 47, 47)' // Replace with actual color value
                                            }}>

                                                The dashboard is offline. Something went wrong.
                                            </h1>
                                            {
                                                error?.message && (
                                                    <p style={{marginTop: '1rem'}}>
                                                        {error?.message}
                                                    </p>
                                                )
                                            }
                                        </div>
                                    </div>
                                </section>
                            )
                        }

                    </div>
                )
            }
            {
                !error && (
                    <>
                        <header style={{
                            padding: '8px',
                            display: 'flex',
                            alignItems: 'center',
                            gap: '16px',
                            width: '100%',
                        }}>
                            <SearchBar
                                value={searchText}
                                onSearch={(txt) => setSearchText(txt)}
                                placeholder={'Search by session id'}
                            />
                            <Autocomplete
                                style={{
                                    minWidth: '250px'
                                }}
                                sx={{
                                    ' .MuiInputBase-root': {
                                        height: '40px',
                                    },
                                }}
                                multiple
                                onChange={(_, val) => {
                                    setProjectFilter(val);
                                    if (val.length === 0) {
                                        setStadiumFilter([]);
                                    }
                                }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Projects" variant="standard"/>
                                )}
                                value={projectFilter}
                                options={projectFilterOptions}
                            />
                            <Autocomplete
                                style={{
                                    minWidth: '250px'
                                }}
                                sx={{
                                    '.MuiInputBase-root': {
                                        height: '40px',
                                    },
                                }}
                                disabled={!(!!projectFilter && projectFilter?.length > 0)}
                                multiple
                                onChange={(_, val) => setStadiumFilter(val)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Stadium" variant="standard"/>
                                )}
                                value={stadiumFilter}
                                options={
                                    stadiumFilterOptions
                                }
                            />
                            <Autocomplete
                                style={{
                                    minWidth: '250px'
                                }}
                                sx={{
                                    '.MuiInputBase-root': {
                                        height: '40px',
                                    },
                                }}
                                multiple
                                onChange={(_, val) => setStageFilter(val)}
                                renderInput={(params) => (
                                    <TextField {...params} label="Stage" variant="standard"/>
                                )}
                                value={stageFilter}
                                options={
                                    stageFilterOptions
                                }
                            />
                            <div
                                style={{
                                    marginLeft: 'auto',
                                }}
                            />
                            <Tooltip title={'Update Data'}>
                                <span>
                                    <IconButton
                                        disabled={isLoading}
                                        style={{
                                            marginRight: '8px',
                                        }}
                                        size={'small'}
                                        onClick={() => updateData()}
                                    >
                                        <Autorenew/>
                                    </IconButton>
                                </span>
                            </Tooltip>
                        </header>
                        <div
                            style={{
                                height: 'calc(100vh - 245px)',
                                minHeight: '340px',
                            }}
                        >
                            <DataGridPro
                                disableRowSelectionOnClick
                                autoHeight={false}
                                columns={columns}
                                rows={getRowsToRender()}
                                loading={isLoading || isUpdating}
                                getRowHeight={() => 'auto'}
                                pinnedColumns={{left: ['priority', 'project', 'location', 'batch']}}
                                processRowUpdate={async (updatedRow, oldRow) => {
                                    if (updatedRow.priority !== oldRow.priority) {
                                        setIsUpdating(true);
                                        await DataService
                                            .changeIannPriority(updatedRow.sessionId, updatedRow.priority);
                                        setIsUpdating(false);
                                        updateData();
                                        return updatedRow;
                                    }
                                    return oldRow;
                                }}
                                onProcessRowUpdateError={(error) => {
                                    let msg = 'I got an error while updating the column.';
                                    if (error.message) {
                                        msg += ` Reason: ${error.message}`;
                                    }
                                    toast.error(msg);
                                }}
                                sortModel={sortModel}
                                onSortModelChange={setSortModel}

                            />
                        </div>
                    </>
                )
            }


        </div>
    )
}
