import * as React from 'react';
import PropTypes from 'prop-types';
import {
    Dialog, DialogTitle, DialogContent, DialogActions, Button, IconButton, Grid, TextField, Tooltip,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import TagTable from "./BrandsTable/TagTable";
import {useEffect, useState} from "react";
import ErrorIcon from '@mui/icons-material/Error';

function VisuaSearchTagModal(
    {
        open,
        handleClose,
        handleSave,
        item,
    }) {
    const TOOLTIP_HEL_TEXT = 'Provide a name and value to create a new tag';
    const [tags, setTags] = useState({});
    const [disableAddTagBtn, setDisableAddTagBtn] = useState(true);
    const [tooltipText, setTooltipText] = useState(TOOLTIP_HEL_TEXT)
    const [showTooltip, setShowTooltip] = useState(false);

    const [newTag, setNewTag] = React.useState({
        tag: '', value: '',
    });

    const handleChangeTagName = (event) => {
        setNewTag((prevState) => ({
            ...prevState, tag: event.target.value,
        }));
    }

    const handleChangeTagValue = (event) => {
        setNewTag((prevState) => ({
            ...prevState, value: event.target.value,
        }));
    }

    const handleAddTag = () => {
        const tagsCopy = {...tags};
        tagsCopy[newTag.tag] = newTag.value;
        setTags(tagsCopy);
        setNewTag({
            tag: '', value: '',
        })
    }

    const removeTag = (item) => {
        const newTags = {...tags};
        delete newTags[item]
        setTags(newTags);
    };

    useEffect(_ => {
        if (open) {
            if (item) {
                const {meta} = item;
                if (meta && typeof meta === 'object') {
                    setTags(meta);
                } else {
                    setTags({})
                }
            }
        } else {
            setShowTooltip(false);
            setTooltipText(TOOLTIP_HEL_TEXT);
            setNewTag({
                tag: '',
                value: '',
            })
        }

    }, [item, open])

    useEffect(_ => {
        setDisableAddTagBtn(true);
        setShowTooltip(true);

        if (newTag.tag.length === 0 && newTag.value.length === 0) {
            setShowTooltip(false);
            setTooltipText(TOOLTIP_HEL_TEXT);
            return;
        }
        const tagName = newTag.tag;
        const tagValue = newTag.value;
        if (tagName.length > 0) {

            if (tagName === '.name') {
                setTooltipText(".name is not a valid key");
                return;
            }

            if (Object.keys(tags).includes(tagName)) {
                setTooltipText('The tag name must be unique');
                return;
            }
        }
        if (tagName.length > 0 && tagValue.length > 0) {
            setShowTooltip(false);
            setDisableAddTagBtn(false)
            setTooltipText('');
        } else {
            setShowTooltip(true);
            setDisableAddTagBtn(true)
            setTooltipText(TOOLTIP_HEL_TEXT);
        }

    }, [newTag])

    return (
        <>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth={'md'}
                fullWidth
            >
                <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span>Edit Item</span>
                    <IconButton
                        onClick={handleClose}
                        sx={{ml: 'auto'}}
                    >
                        <CloseIcon/>
                    </IconButton>
                </DialogTitle>
                <DialogContent>
                    <Grid container={true}>
                        <Grid container={true} alignItems={'center'} spacing={2}>
                            <Grid item xs={3}>
                                <TextField
                                    value={newTag.tag}
                                    id="tagNameInput"
                                    label="Tag Name"
                                    variant={'standard'}
                                    fullWidth
                                    onChange={handleChangeTagName}

                                />
                            </Grid>
                            <Grid item xs={6}>
                                <TextField
                                    value={newTag.value}
                                    id="tagValueInput"
                                    label="Tag Value"
                                    variant={'standard'}
                                    fullWidth
                                    onChange={handleChangeTagValue}
                                />
                            </Grid>
                            <Grid item xs={3} textAlign={'right'} marginLeft={'auto'}>
                                <Tooltip
                                    title={
                                        <span>
                                       <ErrorIcon sx={{mr: 1}}/>
                                            {tooltipText}
                                    </span>
                                    }
                                    open={showTooltip}
                                >
                                    <span>
                                        <Button
                                            fullWidth={true}
                                            variant={'contained'}
                                            onClick={handleAddTag}
                                            disabled={disableAddTagBtn}
                                        >
                                            Add New Tag
                                        </Button>
                                    </span>
                                </Tooltip>
                            </Grid>
                        </Grid>

                        <Grid item xs={12}>
                            <TagTable rows={tags} handleRemoveItem={removeTag}/>
                        </Grid>

                    </Grid>
                </DialogContent>
                <DialogActions>
                    <Button
                        color={'success'}
                        onClick={_ => handleSave(tags)}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>
        </>)
}

VisuaSearchTagModal.propTypes = {
    open: PropTypes.bool.isRequired, handleClose: PropTypes.func.isRequired, item: PropTypes.shape({
        id: PropTypes.number,
        name: PropTypes.string,
        visuaClassId: PropTypes.number,
        meta: PropTypes.shape({}),
        mediaUrl: PropTypes.string,
        timeCreated: PropTypes.string,
    }),
}


export default VisuaSearchTagModal;
