import React, {useState, useEffect} from "react";
import {
    alpha,
    Card,
    CardContent,
    Grid,
    Button,
    FormControlLabel,
    Switch, Select, MenuItem
} from "@mui/material";
import BrandTableToolbar from "./BrandTableToolbar";
import BrandTable from "./BrandTable";


export default function BrandTableCard(
    {
        loading,
        items,
        openModalBrand,
        openActivationModal,
        openAddModal,
        downloadCSV,
        activateBrand,
    }
) {

    const SELECT_FILTER_OPTION = ['All brands', 'Active Brands', 'Inactive Brands'];

    const [renderedItems, setRenderItems] = useState([]);
    const [filterOptions, setFilterOptions] = useState({
        text: '',
        select: SELECT_FILTER_OPTION[1],
    })

    const searchText = (text) => {
        setFilterOptions(
            prevState => {
                return {
                    ...prevState,
                    text,
                }
            }
        )
    }

    const activationFilter = (status) => {
        setFilterOptions(
            prevState => {
                return {
                    ...prevState,
                    select: status,
                }
            }
        )
    }

    const filterMainFunction = () => {
        // don't run filter function if there are
        // no elements yet
        if (items.length === 0) {
            return;
        }

        let filteredElements = [];
        const {
            select,
            text,
        } = filterOptions;

        // Select Filter
        switch (select) {
            case SELECT_FILTER_OPTION[1]:
                filteredElements = items.filter(
                    item =>
                        item.logoVersionArray.filter(
                            logo => logo.status === 1
                        ).length > 0
                )
                break;
            case SELECT_FILTER_OPTION[2]:
                filteredElements = items.filter(
                    item =>
                        item.logoVersionArray.filter(
                            logo => logo.status === 1
                        ).length === 0
                )
                break;
            default:
                filteredElements = items;
                break;
        }

        // Text Filter
        if (text.length !== 0) {
            filteredElements = filteredElements.filter(el => el.computedName.toLowerCase().includes(text.toLowerCase()))
        }

        setRenderItems(filteredElements);
    }

    useEffect(
        _ => {
            console.log('trigger')
            filterMainFunction();

        },
        [filterOptions, items]
    )

    return (
        <Card
            raised={true}
            sx={{
                p: 1,
            }}
        >
            <BrandTableToolbar
                filterOptions={filterOptions}
                searchCallback={searchText}
                openActivationModal={openActivationModal}
                openAddModal={openAddModal}
                selectFilter={activationFilter}
                downloadCSV={downloadCSV}
            />
            <CardContent>
                <BrandTable
                    openModalBrand={openModalBrand}
                    items={renderedItems}
                    loading={loading}
                    activateBrand={activateBrand}
                />
            </CardContent>
        </Card>
    )
}
