import React, {useEffect, useState} from "react";
import ReactDOM from "react-dom";
import $ from "jquery";
import {useDateRangePicker} from "./CustomHooks";
import DataService from "./DataService";
import PhotoModal from "./PhotoModal";
import toastr from "toastr";
import {
    Button,
    Card,
    Grid,
    Pagination,
    Skeleton, Tooltip,
    Typography,
} from "@mui/material";
import DetectionImageList from "./components/DetectionImageList";
import DetectionImageModal
    from "./components/DetectionImageModal/DetectionImageModal";
import DownloadIcon from '@mui/icons-material/Download';
import DateRangeIcon from '@mui/icons-material/DateRange';
import ToolbarSelect from "./components/ToolbarSelect";
import AutocompleteCheckboxBrands
    from "./components/AutocompleteCheckboxBrands";
import SearchWithBtn from "./components/SearchWithBtn";
import Toast from "./components/Toast";
import Label from "./components/Label";
import SearchImage from "./components/SearchImage";
import HelpIcon from "@mui/icons-material/Help";
import ProgressiveImage from "./components/ProgressiveImage/ProgressiveImage";
function Detections({settings, location, user, history}) {
    const params = new URLSearchParams(location.search);

    const [brandList, setBrandList] = useState([]);
    const [brandIds, setBrandIds] = useState(
        params.get("brand")
            ? params.get("brand").split(",")
            : settings.brandIds || []
    );
    const [minScore, setMinScore] = useState(settings.minScore || 0.6);
    // Initializer could be done inline - but I need type inference (hack for TS)
    /** @type {import("./DataService").FeedDetectionResult[]} */
    const initializer = [];
    const [feedResults, setFeedResults] = useState(initializer);
    const [pageSize] = useState(50);
    const [mediaCount, setMediaCount] = useState(0);
    const [pageCount, setPageCount] = useState(0);
    const [dataPresent, setDataPresent] = useState(true);
    const [currentPage, setCurrentPage] = useState(0);
    const [modalMedia, setModalMedia] = useState();
    const [loading, setLoading] = useState(true);
    const [showModal, setShowModal] = useState(false);
    const [toast, setToast] = useState({
        open: false,
    })

    const handleCloseToast = () => {
        setToast({
            open: false,
            message: '',
        })
    }

    const showModalDetection = (media) => {
        history.push({
            pathname: "/media/detections",
            search: `?sessionId=${
                media.sessionId
            }&brand=${brandIds}&startDate=${dateBegin.format(
                "YYYY-MM-DD"
            )}&endDate=${dateEnd.format("YYYY-MM-DD")}`
        });
        setModalMedia(media)
        setShowModal(true);
    }

    const hideModal = () => {
        history.push({
            pathname: "/media/detections",
        });
        setShowModal(false);
    }
    /**
     * Modal Code end
     */
    const {
        startDate: dateBegin,
        endDate: dateEnd,
        selectedDateRange
    } = useDateRangePicker(params, settings);

    useEffect(() => {
        DataService.getActiveBrands().then(
            function (brandData) {
                const {brands} = brandData;
                const newBrandList = [];
                brands.forEach(brand => {
                    if (brand && brand.id) {
                        // special case, we're dealing with a single brand
                        newBrandList.push(brand);
                    } else {
                        Array.prototype.push.apply(newBrandList, brand);
                    }

                    // special case, we want to display only whitelisted brands
                    if (user.metadata && user.metadata.white_list) {
                        var whiteList = user.metadata.white_list.map(function (id) {
                            return "bra_" + id;
                        });

                        var filteredBrands = newBrandList.filter(function (brand) {
                            return whiteList.indexOf(brand.id) + 1;
                        });

                        // empty the list, need to be careful not to create new ref
                        newBrandList.length = 0;

                        Array.prototype.push.apply(newBrandList, filteredBrands);
                    }
                })

                const cleanedList = newBrandList.map(brand => {
                    return {
                        ...brand,
                        name: brand.name.trim(),
                        label: brand.name.trim()
                    }
                }).sort(
                    (a, b) => (a.name > b.name ? 1 : ((b.name > a.name) ? -1 : 0))
                )

                setBrandList(cleanedList);
            }
        ).catch(e => console.error(e));
    }, [
        user.detectionSetting.id,
        user.detectionSetting.meta.categories,
        user.metadata
    ]);

    // Activate
    useEffect(() => {
        setLoading(true);


        DataService.getFeed({
            dateBegin,
            dateEnd,
            brandIds: brandIds.map(x => String(x)),
            minScore,
            page: currentPage + 1,
            pageSize
        })
            .then(data => {
                const {
                    feed,
                    mediaCount
                } = data;

                const count = mediaCount?? 0;
                setMediaCount(count);
                setPageCount(Math.ceil(count / pageSize));
                setDataPresent(count > 0);

                setFeedResults(feed);
                setLoading(false)
            })
            .catch(e => {
                console.error(e)
            });

        const sessionId = params.get("sessionId");

        if (sessionId) {
            onSessionSearchClick(sessionId)
        }

    }, [currentPage, brandIds, dateBegin, dateEnd, minScore, pageSize]);

    useEffect(() => {
        DataService.updateDeveloperMeta({
            minScore,
            brandIds
        });
    }, [minScore, brandIds]);


    function onBrandsSelect(brands) {
        setBrandIds(brands);
        setCurrentPage(0);
    }

    function onMinScoreChange(value) {
        setMinScore(value);
        setCurrentPage(0);
    }

    function onSessionSearchClick(sessionId) {
        DataService.detect(sessionId).then(({data}) => {
            if (data) {
                const feedData = data.data;
                if (feedData) {
                    showModalDetection(feedData)
                }
            }
        });
    }

    async function onCsvDownloadClick() {
        toastr.success(`Generated report for 0/${mediaCount} listings (0%)`, "", {
            timeOut: 0
        });

        const chunkSize = 75;
        /**
         * @type {import("./DataService").FeedDetectionResult[]}
         */
        let results = [];
        console.log({brandIds})
        for (let index = 1; index <= Math.ceil(mediaCount / chunkSize); index++) {
            const chunkedResults = await DataService.getFeed({
                dateBegin,
                dateEnd,
                brandIds: brandIds.map((el) => String(el)),
                minScore,
                pageSize: chunkSize,
                page: index
            });
            const percentage = (
                (index / Math.ceil(mediaCount / chunkSize)) *
                100
            ).toFixed(0);
            $(".toast-message").text(
                `Generated report for ${Math.min(
                    index * chunkSize,
                    mediaCount
                )}/${mediaCount} listings (${percentage}%)`
            );

            results = results.concat(chunkedResults.feed);
        }

        toastr.remove();

        let csvContent = "";
        var header = [
            "Request Hash",
            "Media URL",
            "Confidence",
            "Brand",
            "Brand ID",
            "Coords",
            "Area"
        ];
        if (user.isDetectMultipleScraping) {
            header.push("Original URL")
        }

        const csvData = results.reduce((acc, record) => {
            record.detections.forEach(detection => {
                var line = [
                    record.sessionId,
                    record.mediaUrl,
                    detection.confidence,
                    detection.name,
                    detection.id,
                    detection.coordinates,
                    detection.area
                ]
                if (user.isDetectMultipleScraping) {
                    if (record.meta && record.meta.originalUrl) {
                        line.push(record.meta.originalUrl.trim())
                    } else {
                        line.push("")
                    }
                }
                acc.push(line.map(val => `"${val}"`).join(","))
            });
            return acc;
        }, []);

        csvContent += `${header.join(",")}\n`;
        csvContent += csvData.join("\n");

        const blob = new Blob([csvContent], {
            type: "text/csv"
        });
        const objectUrl = URL.createObjectURL(blob);

        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);

        const fileName = `${dateBegin.format("YYYY-MM-DD")}-${dateEnd.format(
            "YYYY-MM-DD"
        )}`;

        link.setAttribute("download", `${fileName}.csv`);
        link.click();
    }

    const copyDetectionModalLinkToClip = () => {
        const currentUrl = window.location.href;

        navigator.clipboard
            .writeText(currentUrl)
            .then(() =>
                setToast({
                    open: true,
                    message: 'Modal Url successful copied to Clipboard'
                })
            )
            .catch(err => {
                setToast({
                    open: true,
                    message: 'Error: impossible send url to clipboard',
                })
            });


    }

    return (
        <>
            <DetectionImageModal
                open={showModal}
                copyToClip={copyDetectionModalLinkToClip}
                handleClose={hideModal}
                feed={modalMedia}
            />
            <Card
                raised={true}
                sx={{
                    p: 1,
                    mb: 5,
                }}
            >
                <Grid
                    container
                    spacing={2}
                    alignItems={'center'}
                    sx={{
                        p: 1,
                    }}
                >
                    <Grid item>
                        <Label>
                            Date range
                        </Label>
                    </Grid>
                    <Grid item>
                        <div className="form-control"
                             lg-range-selector=""
                             style={{
                                 borderColor: '#8092A6',
                                 color: '#02264D'
                             }}
                        >
                            {selectedDateRange}
                        </div>
                    </Grid>
                    <Grid item>
                        <Label>
                            Brands
                        </Label>
                    </Grid>
                    <Grid item>
                        <AutocompleteCheckboxBrands
                            brands={brandList}
                            value={brandIds}
                            onChange={(list) => {
                                onBrandsSelect(list)
                            }}
                        />
                    </Grid>
                    <Grid item>
                        <Label>
                            Confidence
                        </Label>
                    </Grid>
                    <Grid item>
                        <ToolbarSelect
                            initialState={minScore}
                            onChange={onMinScoreChange}
                            options={
                                [
                                    "ALL",
                                    "0.4",
                                    "0.6",
                                    "0.8",
                                    "1.0",
                                ]
                            }
                        />
                    </Grid>
                    <Grid
                        item
                        sx={{
                            marginLeft: 'auto'
                        }}
                    >
                        <SearchImage
                            placeholder={"View session ID"}
                            onChange={onSessionSearchClick}
                        />
                    </Grid>
                    <Grid item>
                        <Button
                            color={'info'}
                            variant={'contained'}
                            startIcon={<DownloadIcon/>}
                            onClick={onCsvDownloadClick}
                        >
                            CSV
                        </Button>
                    </Grid>
                </Grid>
                <Grid container>
                    <Grid item xs={12} sx={{
                        p: 1,
                        mb: 1,
                        backgroundColor: "#DEE3E8",
                        fontSize: '14px',
                        display: 'flex',
                    }}>
                        <div>
                            {
                                loading
                                    ? <Skeleton variant={'text'} width={100}/>
                                    : <span> {Number(mediaCount).toLocaleString()} <b>Media</b> </span>
                            }
                        </div>
                        <div className="ml-auto">
                            <Tooltip
                                title="Media with detections"
                                placement={'left'}
                            >
                                <HelpIcon sx={{color: '#8092A6'}}/>
                            </Tooltip>
                        </div>
                    </Grid>
                    <Grid item xs={12}>
                        <DetectionImageList
                            loading={loading}
                            onClick={showModalDetection}
                            feeds={feedResults.filter(item => !!item.detections.length)}
                        />
                        {
                            (Object.keys(feedResults.filter(item => !!item.detections.length)).length === 0 && !loading)
                            && <Grid container justifyContent={'center'}
                                     alignItems={'center'}>
                                <Grid item xs={8} sx={{my: 4}}>
                                    <Typography
                                        variant={'h3'}
                                    >
                                        Uh oh...
                                    </Typography>
                                    <Typography
                                        variant={'h6'}
                                    >
                                        Sorry! We couldn't find any data using the
                                        filters selected. Maybe
                                        try something else?
                                    </Typography>
                                </Grid>
                            </Grid>

                        }
                    </Grid>
                    {pageCount > 1 &&
                        <Grid container justifyContent={'center'}
                              alignItems={'center'}>
                            <Pagination
                                variant="outlined"
                                page={currentPage + 1}
                                onChange={(e, page) => {
                                    setCurrentPage(page - 1);
                                }}
                                count={pageCount}
                            />
                        </Grid>
                    }
                </Grid>

            </Card>
            <Toast
                open={toast.open}
                handleClose={handleCloseToast}
                message={toast.message}
            />
        </>
    );
}

export default Detections;
