import React, {useEffect, useState} from "react";
import Papa from 'papaparse';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Grid,
    IconButton,
    Typography,
    CircularProgress,
} from '@mui/material'
import AddBrandsSummaryTable from "../BrandsTable/AddBrandsSummaryTable";
import CloseIcon from '@mui/icons-material/Close';
import AddBrandsModalForm from "./AddBrandsModalForm";
import toastr from "toastr";
import Box from "@mui/material/Box";
import DataService from "../../DataService";
import {
    cleanAndParseCSV
} from "../../Helper/SharedFunctions";

export default function AddBrandsModal(
    {
        open = false,
        saveNewBrands,
        handleClose,
        brandsInUserLibrary = [],
    }
) {
    const [fileCsv, setFileCsv] = useState(null);
    const [mainContent, setMainContent] = useState([]);
    const [directActivation, setDirectActivation] = useState(false);
    const [error, setError] = useState(false);

    useEffect(_ => {
        if (open) {
            setMainContent([]);
            setDirectActivation(false);
            setFileCsv(null);
        }
    }, [open])


    const handleUploadCSV = (files) => {
        if (files.length === 0) {
            return;
        }
        setFileCsv(files[0])
        Papa.parse(files[0], {
            download: true,
            complete: function (input) {
                parseBrandCSV(input.data);
            }
        })
    }

    const parseBrandCSV = (csvLines) => {
        const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

        const brands = [];
        const headerArray = csvLines[0]
            .filter(el => el.length !== 0)
            .map(
                el => el
                    .replace(/[^A-Z0-9]/ig, "")
                    .toLowerCase()
            );
        const data = csvLines.slice(1).filter(row => row.filter(el => el.length !== 0).length !== 0);
        const indexName = headerArray.indexOf('name');
        const indexUrl = headerArray.indexOf('url');
        const indexesExampleUrls = indexOfAll(headerArray, 'exampleurl');
        const indexTagName = headerArray.indexOf('tag');
        const indexTagValue = headerArray.indexOf('value');
        const indexBrandId = headerArray.indexOf('brandid');

        const hasExampleUrl = indexesExampleUrls.length > 0;
        const hasMetas = indexTagName !== -1 && indexTagValue !== -1;

        data.forEach((fields, index) => {

            const brand = {
                name: fields[indexName].trim(),
                url: fields[indexUrl].trim()
            };

            const urls = [];
            if (hasExampleUrl) {
                indexesExampleUrls.forEach(exampleUrl => {
                    if (fields[exampleUrl]) {
                        urls.push(fields[exampleUrl].trim())
                    }
                })
                brand.exampleUrls = urls;
            }

            if (hasMetas) {
                const tag = fields[indexTagName] && fields[indexTagName].trim();
                const value = fields[indexTagValue] && fields[indexTagValue].trim();
                if (tag || value) {
                    if (tag && value) {
                        if (!brand.meta) {
                            brand.meta = {};
                        }
                        brand.meta[tag] = value;
                    } else {
                        toastr.error(`Either tag or value is missing on line: ${index + 1}`)
                    }
                }
            }

            if (indexBrandId !== -1) {
                let str = fields[indexBrandId].trim();
                if (!!str) {
                    str = str.replace(/^0+/, "") || "0";
                    const n = Math.floor(Number(str));
                    if (
                        n !== Infinity
                        && String(n) === str
                        && n >= 0
                    ) {
                        brand.brandId = parseInt(str, 10)
                    }
                }
            }

            brands.push(brand);
        })

        setMainContent(brands)

    }

    const onSubmit = async () => {
        const brandIdRows = [
            ...new Set(
                mainContent
                    .filter(el =>
                        !!el.brandId
                        && brandsInUserLibrary.includes(el.brandId)
                        && (!el.exampleUrls || el.exampleUrls?.length === 0)
                    )
            )
        ];

        let brandToSendToHuman = mainContent.filter(el => {
            return !el.hasOwnProperty('brandId')
                || !brandsInUserLibrary.includes(el.brandId)
                || !(!el.exampleUrls || el.exampleUrls?.length === 0)
        })


        if (brandIdRows.length !== 0) {
            setDirectActivation(true)
            const Promises = brandIdRows.map(brandIdRow => DataService.enableCsvBrand(brandIdRow))
            const results = await Promise.all(Promises);
            brandIdRows.forEach((brandIdRow, index) => {

                if (!results[index]) {
                    let addToHuman = mainContent.filter(
                        row =>
                            !!row.brandId
                            && row.brandId === brandIdRow.id
                    )
                    addToHuman = addToHuman.map(row =>{
                        if (!!row.brandId)  delete row.brandId
                        return row
                    })
                    brandToSendToHuman = [
                        ...brandToSendToHuman,
                        ...addToHuman,
                    ]
                }

            })
        }



        saveNewBrands(
            brandToSendToHuman.map(({brandId, ...keepAttrs}) => keepAttrs),
            fileCsv
        );
        handleClose();
    }

    return (
        <Dialog
            maxWidth={'xl'}
            fullWidth
            open={open}
            onClose={handleClose}
        >
            <DialogTitle
                sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    py: 1,
                }}
            >
                <span>Add Brands</span>
                <IconButton
                    onClick={_ => handleClose()}
                    sx={{ml: 'auto'}}
                >
                    <CloseIcon/>
                </IconButton>
            </DialogTitle>
            <DialogContent>
                <Grid
                    container={true}
                >
                   <Grid
                    item
                    xs={4}

                   >
                       <AddBrandsModalForm
                           addToMainList={
                                brand => {
                                    setMainContent([
                                        ...mainContent,
                                        brand
                               ]);
                           }}
                       />
                   </Grid>
                    <Grid
                        item
                        xs={8}
                        sx={{
                            px: 2,
                        }}
                    >
                        {
                            directActivation?
                                <Box
                                    display={'flex'}
                                    justifyContent={'center'}
                                    alignItems={'center'}
                                    flexDirection={'column'}
                                    height={'100%'}
                                >
                                    <CircularProgress />
                                    <Typography
                                        variant={'h4'}
                                        sx={{mt: 1,}}
                                    >
                                        Direct Brands Activation...
                                    </Typography>
                                </Box>
                                :
                                <AddBrandsSummaryTable
                                    type={'Brand'}
                                    rows={mainContent}
                                    handleRemoveItem={(itemIndex) => {
                                        const itemCopy = [...mainContent];
                                        itemCopy.splice(itemIndex, 1);
                                        setMainContent(itemCopy);
                                    }}
                                    brandIdsInUserLibrary={brandsInUserLibrary}
                                />
                        }

                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions
                sx={{
                    borderTop: '1px solid #ccc',
                    py: 2,
                    px: 3,
                }}
            >
                <Grid container>
                    <Grid
                        item
                        xs={10}
                    >
                        <Grid
                            container
                            alignItems={'center'}
                        >
                            <Grid
                                item
                                sx={{mr: 1,}}
                            >
                                <label
                                    htmlFor="csvFileUpload"
                                >
                                    <input
                                        style={{display: 'none'}}
                                        accept="text/csv"
                                        id="csvFileUpload"
                                        type="file"
                                        disabled={directActivation}
                                        onChange={e => {
                                            handleUploadCSV(e.target.files)
                                            e.target.value = '';
                                        }}
                                    />
                                    <Button
                                        variant="contained"
                                        component="span"
                                        color={'info'}
                                        disabled={directActivation}
                                    >
                                        Upload CSV
                                    </Button>
                                </label>
                            </Grid>
                            <Grid
                                item
                                xs={10}
                                sx={{fontSize: '10px'}}
                            >
                                If submitting a large batch of Brands use the
                                'Upload CSV' feature. Please create the file in the following CSV
                                format: <a href="files/visua_brand_request.csv" download>Download template.</a> <br/>
                                The fields <span style={{fontFamily: 'monospace'}}>exampleUrl,tag,value</span> are optionals
                                and you can omit them.
                            </Grid>
                        </Grid>
                    </Grid>
                    <Grid item xs={2} textAlign={'right'}>
                        <Button
                            variant={'contained'}
                            sx={{ml: 'auto'}}
                            disabled={
                                mainContent.length === 0
                                || directActivation
                            }
                            onClick={_ => {
                                onSubmit()
                            }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </DialogActions>
        </Dialog>
    )

}
