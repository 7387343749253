import React, { useEffect, useRef } from 'react';
import { JsonEditor } from 'jsoneditor-react';

export const ControlledJsonEditor = ({ value, onChange, errorsHandler }) => {
    const jsonEditorRef = useRef();

    useEffect(
        () => {
            const editor = jsonEditorRef && jsonEditorRef.current && jsonEditorRef.current.jsonEditor;
            if (editor && value) { editor.update(value); }
        },
        [jsonEditorRef, value]
    )


    return (
        <JsonEditor
            ref={jsonEditorRef}
            value={value}
            onChange={onChange}
            mode="text"
            navigationBar={false}
            search={false}
            sortObjectKeys={false}
            statusBar={false}
            onValidationError={errorsHandler}
            htmlElementProps={{ style: { height: 500, background: 'rgb(29, 184, 224)'} }}
        />
    );
}
