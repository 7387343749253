import PropTypes from 'prop-types';
import React, {useEffect, useState} from 'react';
import {styled} from "@mui/system";
import Image from '@mui/icons-material/Image'
import {
    InputBase,
    Tooltip,
    alpha,
    IconButton,
} from'@mui/material';

const Search = styled('div')(({theme}) => ({
    position: 'relative',
    borderRadius: 0,
    backgroundColor: alpha('#fff', 0.15),
    '&:hover': {
        backgroundColor: alpha('#fff', 0.25),
    },
    marginLeft: "0 !important",
    width: '100%',
    [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(3),
        width: 'auto',
    },
}));

const SearchIconWrapper = styled('div')(({theme}) => ({
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    right: 0,
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
}));

const StyledInputBase = styled(InputBase)(({theme}) => ({
    color: 'inherit',
    '& .MuiInputBase-input': {
        padding: theme.spacing(1, 1, 1, 0),
        // vertical padding + font size from searchIcon
        paddingLeft: '5px',
        width: '100%',
        [theme.breakpoints.up('md')]: {
            width: '18ch',
        },
    },
    borderBottom: '1px solid #8092A6'
}));

function SearchImage(
    {
        placeholder = "Search…",
        onChange,
    }) {
    const [searchTerm, setSearchTerm] = useState('')
    const [openTooltip, setOpenTooltip] = useState(false);

    const startImageSearch = () => {
        onChange(searchTerm)
        setSearchTerm('');
    }
    const handleEnter = (e) => {
        const {code} = e;
        if (code && code === 'Enter') {
            startImageSearch();
        }
    }

    useEffect(() => {
            setOpenTooltip(searchTerm.length > 0);
    }, [searchTerm])



    return (
        <Search>

            <StyledInputBase
                placeholder={placeholder}
                inputProps={{
                    'aria-label': placeholder
                }}
                value={searchTerm}
                onChange={e => {
                    setSearchTerm(e.target.value)
                }}
                onKeyDown={handleEnter}
            />
            <Tooltip
                placement={'top'}
                title={
                    <span>To view, press <b>ENTER</b> or click the button</span>
                }
                open={openTooltip}
            >
                <IconButton
                    onClick={_ => startImageSearch()}
                >
                    <Image sx={{
                        color: 'rgb(30, 184, 224)'
                    }}/>
                </IconButton>
            </Tooltip>
        </Search>
    )
}

SearchImage.propTypes = {}

SearchImage.defaultProps = {}

export default SearchImage;
