import * as React from 'react';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Chip from '@mui/material/Chip';
import {styled} from '@mui/system';
import Skeleton from '@mui/material/Skeleton';
import Image from 'material-ui-image';
import {ImageListItemBar} from "@mui/material";
import Box from "@mui/material/Box";
import ProgressiveImage from "./ProgressiveImage/ProgressiveImage";
const StyleChip = styled(
    (props) => (
        <Chip
            {...props}
        />
    )
)({
    fontSize: '10px',
    marginRight: '4px',
    marginBottom: '4px'
});


function LoadingState() {
    return (
        <ImageList cols={5} gap={10}>
            {[...Array(50).keys()].map((item) => (
                <ImageListItem
                    key={item}
                    style={{
                        backgroundColor: '#f2f2f2',
                        padding: '3px'
                    }}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Skeleton height={200} variant={'rectangular'}/>
                </ImageListItem>
            ))}
        </ImageList>
    )
}

export default function DetectionImageList(props) {
    const {feeds, onClick, loading} = props;
    const items = [];

    feeds.forEach((feed, index) => {
        let category = [0, 0, 0];

        feed.detections.forEach(detection => {
            switch (detection.type) {
                case 'logo':
                    category[0]++;
                    break;
                case 'text':
                    category[1]++;
                    break;
                case 'object':
                    category[2]++;
                    break;
                default:
                    console.error('unkonwn detection type');
                    break;
            }
        })

        let title = [];


        feed.detections.slice(0, 3).forEach((elem, index) => {
            const {name, text, type} = elem;
            let color = 'default';
            let string = '';
            switch (type) {
                case 'logo':
                    category[0]--;
                    string = name;
                    color = 'error';
                    break;
                case 'text':
                    category[1]--;
                    string = text;
                    color = 'secondary';
                    break;
                case 'object':
                    category[2]--;
                    string = name;
                    color = 'warning';
                    break;
                default:
                    console.error('unknown detection type');
                    break;
            }


            title.push(<StyleChip color={color} size={'small'} label={string}
                                  key={`${name}_${index}`}/>)
        })

        const subtitle = [];
        // if logo > 0
        if (category[0]) {
            let text = `${category[0]} Logos`;
            if (category[0] === 1) {
                text = `${category[0]} Logo`;
            }
            subtitle.push(<StyleChip key={'logo'} color={'error'} size={'small'}
                                     label={text}/>)
        }
        if (category[1]) {
            let text = `${category[1]} texts`;
            if (category[0] === 1) {
                text = `${category[1]} text`;
            }
            subtitle.push(<StyleChip key={'text'} color={'secondary'} size={'small'}
                                     label={text}/>)
        }
        if (category[2]) {
            let text = `${category[2]} objects`;
            if (category[0] === 1) {
                text = `${category[2]} object`;
            }
            subtitle.push(<StyleChip key={'object'} color={'warning'} size={'small'}
                                     label={text}/>)
        }


        items.push({
            id: feed.sessionId,
            src: feed.mediaUrl,
            alt: `detection number ${index}`,
            title,
            subtitle,
            ...feed
        })
    })


    return (
        loading
            ? <LoadingState/>
            : (
                <ImageList
                    cols={4}
                    gap={5}
                    rowHeight={300}
                >
                    {items.map((item) => (
                        <ImageListItem
                            key={item.id}
                            style={{
                                backgroundColor: '#f2f2f2',
                                padding: '3px'
                            }}
                            onClick={() => onClick(item)}
                            sx={{
                                display: 'block',
                                overflow: 'hidden',
                                cursor: 'pointer'
                            }}
                        >
                                <img
                                    style={{
                                        width: '100%',
                                    }}
                                    src={item.src}
                                    alt={item.alt}
                                    loading="lazy"
                                />

                            <ImageListItemBar
                                title={item.title}
                                subtitle={item.subtitle}
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            />
                        </ImageListItem>
                    ))}
                </ImageList>
            )
    );
}
