import * as React from 'react';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import {Alert, AlertTitle, FormControl, IconButton, InputLabel, Select} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import MenuItem from "@mui/material/MenuItem";
import {useEffect, useState} from "react";

export default function AddTextModal(
    {
        open,
        handleClose,
        save,
    }) {
    const MAX_INPUT_CHAR = 255;
    const [confidence, setConfidence] = useState(1);
    const [text, setText] = useState('');

    useEffect(_ => {
       if (open) {
           setConfidence(1);
           setText('');
       }
    }, [open])

    useEffect(() => {
       const calcConfidence = () => {
           const tlen = text.length;
           if (tlen < 10) return 1;
           if (tlen <= 19) return 0.9;
           if (tlen <= 29) return 0.8;
           return 0.7
       }

       setConfidence(
           calcConfidence()
       )
    }, [text])

    const onAdd = () => {
        save(text, confidence);
        handleClose();
    }

    return (
            <Dialog
                open={open}
                onClose={handleClose}
                fullWidth={true}
                maxWidth={'sm'}

            >
                <DialogTitle sx={{display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                    <span>Add Text</span>
                    <IconButton
                        onClick={handleClose}
                        sx={{ml: 'auto'}}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent sx={{
                    p: 2,
                }}>
                    <Alert severity="warning">
                        We strongly recommend that you do not change the suggested threshold
                    </Alert>
                    <TextField
                        sx={{mt: 2}}
                        value={text}
                        id="TextToAdd"
                        label={`Enter text here. Maximum ${MAX_INPUT_CHAR} ASCII characters`}
                        multiline
                        type="text"
                        rows={4}
                        aria-valuemax={255}
                        fullWidth={true}
                        inputProps={{ maxLength: MAX_INPUT_CHAR }}
                        onChange={e => {
                            const txt = e.target.value;
                            setText(txt);
                        }}
                    />
                    <FormControl sx={{mt: 2, width: '100px'}}>
                        <InputLabel id="textConfidence-select-label">Confidence</InputLabel>
                        <Select
                            labelId="textConfidence-select-label"
                            id="textConfidence-select"
                            value={confidence}
                            label="Confidence"
                            onChange={
                                (e) => {
                                   setConfidence(e.target.value)
                                }
                            }
                        >
                            <MenuItem value={0.2}>0.2</MenuItem>
                            <MenuItem value={0.4}>0.4</MenuItem>
                            <MenuItem value={0.5}>0.5</MenuItem>
                            <MenuItem value={0.6}>0.6</MenuItem>
                            <MenuItem value={0.7}>0.7</MenuItem>
                            <MenuItem value={0.8}>0.8</MenuItem>
                            <MenuItem value={0.9}>0.9</MenuItem>
                            <MenuItem value={1}>1</MenuItem>
                        </Select>
                    </FormControl>

                </DialogContent>
                <DialogActions>
                    <Button
                        color={'success'}
                        variant={'contained'}
                        onClick={onAdd}
                        disabled={text.length === 0}
                    >
                        Add
                    </Button>
                </DialogActions>
            </Dialog>
    );
}
