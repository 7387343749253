import * as React from 'react';
import PropTypes from 'prop-types';
import ImageList from "@mui/material/ImageList";
import ImageListItem from "@mui/material/ImageListItem";
import Skeleton from "@mui/material/Skeleton";
import Image from 'material-ui-image';
import {Button, IconButton, ImageListItemBar} from "@mui/material";
import DeleteIcon from '@mui/icons-material/Delete';
import LocalOfferIcon from '@mui/icons-material/LocalOffer';


function LoadingState() {
    return (
        <ImageList cols={5} gap={10}>
            {[...Array(50).keys()].map((item) => (
                <ImageListItem
                    key={item}
                    style={{
                        backgroundColor: '#f2f2f2',
                        padding: '3px'
                    }}
                    sx={{
                        cursor: 'pointer'
                    }}
                >
                    <Skeleton height={200} variant={'rectangular'}/>
                </ImageListItem>
            ))}
        </ImageList>
    )
}

function LoadedState({items, onDeleteClick, onTagClick}) {
    return (
        <ImageList cols={5} gap={10}>
            {
                items.map((item) => (
                        <ImageListItem
                            key={item.id}
                            variant="masonry"
                        >
                            <Image
                                src={item.mediaUrl}
                                alt={item.name}
                            />
                            <ImageListItemBar
                                actionIcon={
                                <>
                                    <IconButton
                                        sx={{ color: 'error.main' }}
                                        onClick={_ => onDeleteClick(item)}
                                    >
                                        <DeleteIcon/>
                                    </IconButton>
                                    <IconButton
                                        sx={{ color: 'primary.main' }}
                                        onClick={_ => onTagClick(item)}
                                    >
                                        <LocalOfferIcon/>
                                    </IconButton>
                                </>
                                }

                            />
                        </ImageListItem>
                    )
                )
            }

        </ImageList>

    )
}


function SearchVisuaImageList(
    {
        loading,
        items,
        onDeleteClick,
        onTagClick,
    }
) {
    return (
        <>
            {
                loading
                    ? <LoadingState/>
                    : (
                        <LoadedState
                            items={items}
                            onDeleteClick={onDeleteClick}
                            onTagClick={onTagClick}
                        />
                    )
            }
        </>
    )
}

SearchVisuaImageList.propTypes = {
    loading: PropTypes.bool,
    items: PropTypes.array,
}

SearchVisuaImageList.defaultProps = {
    loading: false,
}

export default SearchVisuaImageList;
