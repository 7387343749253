import * as React from 'react';
import {
    Stack,
    Button,
    Card,
    CardContent,
    CardHeader,
    Grid,
    TextField,
    Tooltip,
    Typography,
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell,
} from "@mui/material";
import Box from "@mui/material/Box";
import InfoIcon from "@mui/icons-material/Info";
import {useState} from "react";
import { styled } from '@mui/material/styles';
import { tooltipClasses } from '@mui/material/Tooltip';
const Input = styled('input')({
    display: 'none',
});
const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
        backgroundColor: theme.palette.common.white,
        color: 'rgba(0, 0, 0, 0.87)',
        boxShadow: theme.shadows[1],
        fontSize: 11,
        mt: 2,
        maxWidth: 'none',
    },
}));

function UploadMainView(
    {
        onFileChange,
        sendMedia,
        mandatoryFields,

    }
) {
    const [urlsTextArea, setUrlsTextArea] = useState('');

    const downloadExampleCsv = () => {
        const headers = mandatoryFields.map(el => el.name);
        headers.unshift('mediaUrl');
        const csvData = [];

        for (let i = 1; i < 4; i++) {
            const objRow = [];

            headers.map(el => {
                objRow.push(`${el}${i}`);
            })

            csvData.push(objRow);
        }
        let csvContent = `${headers.join(",")}\n`;
        csvContent += csvData.join("\n");
        const blob = new Blob([csvContent], {
            type: "text/csv"
        });
        const objectUrl = URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.setAttribute("href", objectUrl);
        link.setAttribute("download", `csvExample.csv`);
        link.click();
    }

    return (
        <>
            <Grid
                container
                spacing={4}
                sx={{py: 2}}
            >

                <Grid
                    item
                    md={4}
                    xs={6}
                >
                    <Card
                        raised={true}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column'
                        }}
                    >
                        <CardHeader
                            title='Upload CSV file'
                        />
                        <CardContent
                            sx={{
                                mt: 'auto',
                            }}
                        >
                            {
                                !!mandatoryFields &&
                                <LightTooltip
                                    placement={'right-end'}
                                    arrow
                                    open={false}
                                    sx={{
                                        '& .MuiTooltip-tooltip': {
                                            maxWidth: 'none'
                                        }
                                    }
                                    }
                                    title={
                                    <Box
                                        sx={{
                                            mx: 1,
                                            px: 2,
                                            background: '#fff'
                                        }}

                                    >
                                    <Table>

                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Column Name</TableCell>
                                                <TableCell>Mandatory?</TableCell>
                                                <TableCell>Validation?</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                                {
                                                    mandatoryFields.map(el =>
                                                        <TableRow key={el.name}>
                                                            <TableCell>{el.name}</TableCell>
                                                            <TableCell>{!!el.mandatory ? 'Yes' : 'No'}</TableCell>
                                                            <TableCell>{!!el.validation? "Yes" : "No"}</TableCell>
                                                        </TableRow>
                                                )
                                                }
                                        </TableBody>

                                    </Table>
                                    </Box>
                                }
                                >
                                    <Typography
                                        sx={
                                            {
                                                mb: 1,
                                                color: 'primary.main',
                                                cursor: 'pointer',
                                                textDecoration: 'underline',
                                            }
                                        }
                                        onClick={_ => {
                                            downloadExampleCsv()
                                        }}
                                    >
                                        Download Example CSV
                                    </Typography>
                                </LightTooltip>
                            }

                            <label htmlFor="uploadCSV" style={{width: '100%'}}>
                                <Input
                                    accept=".csv"
                                    id="uploadCSV"
                                    name={'file'}
                                    type="file"
                                    onChange={e => {
                                        onFileChange(e.target.files)
                                        e.target.value = '';
                                    }}
                                />
                                <Button
                                    fullWidth={true}
                                    variant="contained"
                                    component="span"
                                >
                                    Select file
                                </Button>
                            </label>
                        </CardContent>
                    </Card>
                </Grid>
                <Grid
                    md={5}
                    xs={6}
                    item
                    sx={{
                        ml: 1,
                    }}
                >
                    <Box>
                        <Box
                            sx={{
                                display: 'flex',
                                alignItems: 'center',
                            }}
                        >
                            <InfoIcon sx={{
                                mr: 2,
                                fontSize: '2rem',
                                color: 'info.main'
                            }}/>
                            <Typography variant={'h6'}>
                                CSV Upload Instructions
                            </Typography>
                        </Box>

                        <Typography variant={'body1'} as={'div'}>
                            <p>
                                This function allows you to upload a list of media files along with
                                corresponding meta tags that will be processed and passed back to you via the VISUA API.
                            </p>
                            <p>
                                <strong>

                                    Please ensure that your CSV source file contains a header row containing
                                    the meta tag headings written exactly as you wish to receive them back.
                                    Where you have mandated a key set of tags, these will be validated as
                                    being present and an error will be shown post-upload
                                    if they are not present or misspelled.
                                </strong>
                            </p>
                            <p>
                                You can also add any number of meta tag columns as required.
                            </p>
                            <p>
                                If you do not require project-specific tags to be associated with the media,
                                you can use this function to simply upload a list of media URLs to be processed.
                            </p>

                        </Typography>

                    </Box>
                </Grid>
            </Grid>
            {
                !mandatoryFields &&
                <Grid
                    container
                    spacing={4}
                    sx={{py: 2}}
                >
                    <Grid item xs={4}>
                        <Card
                            raised={true}
                        >
                            <CardHeader
                                title={
                                    <>
                                        <Grid container={true} justifyContent={'space-between'}>
                                            <Grid item={true}>
                                                Paste URL list
                                            </Grid>
                                        </Grid>
                                    </>
                                }
                            />
                            <CardContent>
                                <TextField
                                    id="outlined-multiline-static"
                                    label="List of Urls"
                                    placeholder={'Create a list of media URLs. E.g.\n https://superbowl.com/screen_1.jpg\n https://superbowl.com/screen_2.jpg\n https://superbowl.com/screen_3.jpg'}
                                    multiline
                                    rows={4}
                                    sx={{
                                        width: '100%',
                                        height: '100px',
                                        minHeight: '100px',
                                        mb: 2,
                                    }}
                                    value={urlsTextArea}
                                    onChange={
                                        (e) => {
                                            setUrlsTextArea(e.target.value)
                                        }
                                    }
                                />
                                <Button
                                    fullWidth={true}
                                    variant="contained"
                                    onClick={
                                        () => {
                                            const Promise = sendMedia(urlsTextArea)
                                            Promise.then(_ => {
                                                setUrlsTextArea('');
                                            })
                                        }
                                    }

                                    sx={{mt: 4}}
                                >
                                    Submit media list
                                </Button>
                            </CardContent>
                        </Card>
                    </Grid>
                    <Grid
                        item
                        xs={5}
                        sx={{
                            ml: 1,
                        }}
                    >
                        <Box>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                }}
                            >
                                <InfoIcon sx={{
                                    mr: 2,
                                    fontSize: '2rem',
                                    color: 'info.main'
                                }}/>
                                <Typography variant={'h6'}>
                                    Paste URL Instructions
                                </Typography>
                            </Box>
                            <Typography variant={'body1'}>
                                Where you only have a small number of media URLs to process you can simply paste them in
                                this window with one URL per line.
                                <strong>
                                    This dialog does NOT accept any data other than media URLs,
                                    so if you have tags to submit, please use the Upload CSV function.
                                </strong>
                            </Typography>
                        </Box>
                    </Grid>
                </Grid>
            }
        </>
    )
}


export default UploadMainView;
