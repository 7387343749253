import Table from "@mui/material/Table";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import TableCell from "@mui/material/TableCell";
import TableBody from "@mui/material/TableBody";
import {IconButton} from "@mui/material";
import DeleteIcon from "@mui/icons-material/Delete";
import * as React from "react";
import {useEffect, useState} from "react";

const TagTable = ({rows = {}, handleRemoveItem}) => {
    const [filteredRows, setFilteredRows] = useState(rows);

    useEffect(_ => {
        const copiedRows = {...rows};
        delete copiedRows['.name'];
        setFilteredRows(copiedRows);

    }, [rows])

    return (
        <Table sx={{minWidth: 650}} aria-label="Tag table">
            <TableHead>
                <TableRow>
                    <TableCell
                        style={{width: "10%"}}
                    >
                        #
                    </TableCell>
                    <TableCell
                        style={{width: "20%"}}
                    >
                        Tag
                    </TableCell>
                    <TableCell
                    >
                        Value
                    </TableCell>
                    <TableCell
                        align={'center'}
                        style={{width: "10%"}}
                    >
                        Remove
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {Object.keys(filteredRows).map((key, index) => (
                    <TableRow
                        key={index}
                    >
                        <TableCell
                            style={{width: "15%"}}
                        >
                            {index + 1}
                        </TableCell>
                        <TableCell>{key}</TableCell>
                        <TableCell>{rows[key]}</TableCell>
                        <TableCell
                            align={'center'}
                            style={{width: "15%"}}
                        >
                            <IconButton onClick={_ => handleRemoveItem(key)}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    );
}

export default TagTable;
