import * as React from 'react';
import {
    Typography,
    Alert,
} from '@mui/material';

import moment from "moment-timezone";
import {useEffect, useState} from "react";
import AddBrandModal from "../../components/BrandsTable/AddBrandModal";
import DataService from "../../DataService";
import toastr from "toastr";
import EditBrandModal from "../../components/BrandsTable/EditBrandModal";
import ActivationLogModal from "../../components/ActivationLogModal";
import BrandTableCard from "../../components/BrandsTable/BrandTableCard";
import AddBrandsModal from "../../components/AddBrandsModal/AddBrandsModal";
const UPDATE_TIME_MS = 40000; // 40seconds
const CHUCK_UPLOAD_SIZE = 100;

function Brands(
    {
        isTest,
        user,
    }
) {

    const [openAddBrand, setOpenAddBrand] = useState(false);

    const [modalEditBrand, setModalEditBrand] = useState(false);
    const [modalEditBrandItem, setModalEditBrandItem] = useState(null);
    const [modalImageIndex, setModalImageIndex] = useState(-1);

    const [openActivationLog, setOpenActivationLog] = useState(false);
    const [logItems, setLogItems] = useState([]);
    const [isLoadingLog, setIsLoadingLog] = useState(false);

    const [brandItems, setBrandItems] = useState([]);
    const [renderedItems, setRenderedItems] = useState([]);
    const [isLoadingBrandItems, setIsLoadingBrandItems] = useState(true);
    const [brandIdsRequest, setBrandIdsRequest] = useState({

    })
    const [isRefreshNeeded, setIsRefreshNeeded] = useState(false);
    let refreshInterval;

    const dateLastDeployed =
        user.detectionSetting.dateReduced
            ? (
                moment.utc(
                    user
                        .detectionSetting
                        .dateReduced
                )
                    .subtract(3, 'hour')
                    .tz(
                        moment.tz.guess()
                    )
                    .format("YYYY/MM/DD HH:mm:ss Z"))
            : "never";

    const setUpdateBrandItems = (brands) => {
        const computedBrands = [];
        brands.forEach((brand) => {
            const {logoVersionArray, name} = brand;
            let computedName = name ?? '';

            const elementsWithDotName = logoVersionArray.filter(
                el => !!el.meta && !!el.meta['.name']
            );

            if (
                elementsWithDotName.length === 0
            ) {
                return computedBrands.push(
                    {
                        ...brand,
                        tableId: brand.id,
                        computedName,
                    }
                )
            }


            const groupByDotName = elementsWithDotName.reduce(
                (prev, currEl) => {
                    const property = currEl.meta['.name'];
                    if (!!prev[property]) {
                        prev[property].logoVersionArray.push(currEl);
                    } else {
                        prev[property] =
                            {
                                ...brand,
                                tableId: `Computed-${brand.id}_${property}`,
                                computedName: property,
                                logoVersionArray: [currEl],
                            }
                        ;
                    }
                    return prev;
                }, {}
            );

            const DotNameGroupsAsArray = Object.keys(groupByDotName);
            DotNameGroupsAsArray.forEach(
                key => {
                    computedBrands.push(groupByDotName[key])
                }
            )

            // If logoVersion without dotName, add to originalBrands
            const elementsWithoutDotName = logoVersionArray.filter(
                el => {
                    const hasMeta = el.hasOwnProperty('meta');
                    if (!hasMeta) return true;

                    return !el.meta['.name'];
                }
            );

            if (elementsWithoutDotName.length > 0) {
                computedBrands.push(
                    {
                        ...brand,
                        tableId: brand.id,
                        computedName,
                        logoVersionArray: elementsWithoutDotName,
                    }
                )
            }

        })


        setRenderedItems(computedBrands);
        return computedBrands;
    }

    useEffect(() => {
        const getBrands = () => {
            return DataService.getAvailableBrands()
                .then(({brands}) => {
                    setBrandItems(brands);

                    for (let i = 0; i < brands.length; i++) {
                        const logoLoading = brands[i].logoVersionArray.filter(logoV => logoV.status === -1).length !== 0;
                        if (logoLoading) {
                            setIsRefreshNeeded(true);
                            return true;
                        }
                    }
                    setIsRefreshNeeded(false);
                    return false;
                })
        }

        getBrands()
            .then(reload => {
                if (!reload) clearTimeout(refreshInterval)
            })
            .catch(e => {
                toastr.error('Failed to get brands');
                console.error(e)
            })
            .finally(_ => {
                setIsLoadingBrandItems(false);
            })

        refreshInterval = setInterval(
            _ => {

            }, UPDATE_TIME_MS
        )

        return () => clearInterval(refreshInterval)
    }, []);

    useEffect(_ => {
        setUpdateBrandItems(brandItems);
    }, [brandItems])

    const openModalBrand = (item, imgIndex) => {
        const selectedItem = renderedItems.find(el => item.id === el.id);
        if (!!selectedItem) {
            setModalEditBrandItem(item)
            setModalImageIndex(imgIndex)
            setModalEditBrand(true);
        }

    }

    const onCSVExport = () => {
        DataService
            .getActiveBrands()
            .then(({brands}) => {
                const header = ["Brand ID", "Brand Name", "Logo URL"];
                let csvContent = `${header.join(",")}\n`;

                const csvData = brands.map(record => {
                    return [
                        record.id,
                        record.name,
                        record.logoVersionArray[0].iconUrl
                    ].join(",");
                });

                csvContent += csvData.join("\n");

                const blob = new Blob([csvContent], {
                    type: "text/csv"
                });

                const objectUrl = URL.createObjectURL(blob);
                const link = document.createElement("a");
                link.setAttribute("href", objectUrl);
                const fileName = `brands-export-${moment().format("YYYY-MM-DD")}`;
                link.setAttribute("download", `${fileName}.csv`);
                link.click();
            });
    }

    function chunkArray(array, chunkSize) {
        let result = [];
        for (let i = 0; i < array.length; i += chunkSize) {
            result.push(array.slice(i, i + chunkSize));
        }
        return result;
    }

    const addNewBrands = async (brands, csvFile) => {
        if (brands.length > 400) {
            return DataService
                .sendBrandEmail(user, csvFile, brands.length)
                .then(_ => {
                    toastr.success(
                        "Request successfully created"
                    );
                })
                .catch(e => {
                    console.error(e);
                    toastr.error(
                        "Failed to add new Brands"
                    );
                })
        }

        const activeBrandPromises = [];
        chunkArray(brands, 100)
            .forEach(
                chuck => {
                    const Promise = DataService.activateBrands(chuck);
                    activeBrandPromises.push(Promise);
                }
            )

        Promise.all(activeBrandPromises)
            .then(_ => {
                toastr.success(
                    "Request successfully created"
                );
            })
            .catch(e => {
                console.error(e);
                toastr.error(
                    "Failed to add new Brands"
                );
            })
    }

    const getActivationLogItems = () => {
        setIsLoadingLog(true);
        DataService
            .getBrandRequestGroupStatus()
            .then(logs => {
                const {
                    brandsActivateStatus
                } = logs;
                if (!!brandsActivateStatus) {
                    setLogItems(brandsActivateStatus);
                    setOpenActivationLog(true);
                } else {
                    return Promise.reject();
                }
            })
            .catch(e => {
                console.error(e);
                toastr.error(
                    "Failed to get Activation Log Items"
                );
            })
            .finally(_ => {
                setIsLoadingLog(false);
            });
    }

    const onSwitchBrandState = (enable, brand, logoVersion) => {
        const {
            tableId
        } = brand;
        if (String(tableId).startsWith('Computed-')) {
            if (!!logoVersion) return onToggleBrandState(enable, brand, logoVersion);
            brand.logoVersionArray.forEach(logo =>
                onToggleBrandState(
                    enable,
                    brand,
                    logo,
                )
            )
        } else {
            onToggleBrandState(enable, brand, logoVersion)
        }
    }

    const onToggleBrandState = (enable, brand, logoVersion) => {
        const brandId = brand.id;
        const indexBrand = brandItems.findIndex(
            el => el.id === brandId
        );
        let newLogoVersions;
        let promise;

        if (indexBrand === -1) {
            return toastr.error(
                'Id brand not found in local brand collection'
            );
        }
        const itemsCopy = [...brandItems];

        if (!!logoVersion) {
            newLogoVersions =
                brandItems[indexBrand]
                    .logoVersionArray
                    .filter(el => el.id !== logoVersion.id);

            if (enable) {
                promise = DataService.enableLogoVersion(brandId, logoVersion.id).then(
                    (resp) => {
                        logoVersion.status = -1;
                        // Add meta if inherit
                        let lvsResp = resp.brands.find(b => b.id === brandId).logoVersionArray
                        let lvResp = lvsResp.find(l => l.id === logoVersion.id)
                        logoVersion.meta = lvResp.meta

                        newLogoVersions.push(logoVersion);

                    }
                );
            } else {
                promise = DataService.disableLogoVersion(brandId, logoVersion.id).then(
                    () => {
                        logoVersion.status = 0;
                        newLogoVersions.push(logoVersion);

                    }
                );
            }
        } else {
            if (enable) {
                promise =
                    DataService
                        .enableBrand(brand.id)
                        .then((resp) => {
                            const {
                                brands
                            } = resp;

                            const logos = brands
                                .find(b => b.id === brand.id)
                                .logoVersionArray

                            brand.logoVersionArray.forEach(logo => {
                                logo.status = -1;
                                // Add meta if inherit
                                const lvResp = logos.find(l => l.id === logo.id)
                                logo.meta = lvResp.meta
                            });

                            itemsCopy[indexBrand] = brand
                            setBrandItems(itemsCopy);
                        });
            } else {
                promise = DataService.disableBrand(brand.id).then(() => {
                    brand.logoVersionArray.forEach(logo => {
                        logo.status = 0;
                    });
                    itemsCopy[indexBrand] = brand
                    setBrandItems(itemsCopy);
                });
            }
        }


        promise
            .then(() => {
                if (!!logoVersion) {
                    const brand = itemsCopy[indexBrand];
                    brand.logoVersionArray = newLogoVersions;
                    itemsCopy[indexBrand] = brand;
                    setBrandItems(itemsCopy);

                }
                toastr.success(
                    "Logo version changes will take effect within 24 hours"
                );
            })
            .catch(e => {
                console.error(e);
                toastr.error(
                    "Failed to change items status"
                );
            })
    }

    const putMeta = (meta, brandId, selectedLogoVersion) => {

        const indexBrand = brandItems.findIndex(
            el => el.id === brandId
        );

        const itemsCopy = [...brandItems];

        /***************************
         * LOGO VERSION EDIT META  *
         ***************************/
        if (!!selectedLogoVersion) {
            const selectedItem = brandItems[indexBrand];
            const newLogoVersionIndex = selectedItem.logoVersionArray.findIndex(
                el => el.id === selectedLogoVersion.id)
            ;
            if (newLogoVersionIndex !== -1) {
                DataService.putLogoVersionMeta(brandId, selectedLogoVersion.id, meta)
                    .then(() => {
                        toastr.success(
                            'Thank you. The new tag has been applied to the logo version'
                        );
                    })
                    .catch(err => {
                        console.error(err);
                        toastr.error(
                            'Server call failed. Try again?'
                        );
                    });
                const brand = itemsCopy[indexBrand];
                brand.logoVersionArray[newLogoVersionIndex].meta = meta;
                itemsCopy[indexBrand] = brand;
                setBrandItems(itemsCopy);
            } else {
                toastr.error(
                    'Failed to get correct logo version Id. Operation failed'
                );
            }


        } else {
            /***************************
             * BRAND LEVEL EDIT META  *
             ***************************/
            DataService.putBrandMeta(brandId, meta)
                .then(() => {
                    toastr.success(
                        'Thank you. The new tag has been applied to all logo versions'
                    );
                })
                .then(_ => {
                    // Merge row data with
                    itemsCopy[indexBrand].logoVersionArray.forEach(el => {
                        el.meta = meta;
                    });

                    setBrandItems(itemsCopy);
                })
                .catch(err => {
                    console.error(err);
                    toastr.success(
                        'Server call failed. Try again?'
                    );
                });
        }

    }

    return (
        <div className={'brands--page'}>
            {
                isTest &&
                <Alert
                    severity="info"
                    sx={{
                        m: 2,
                    }}
                >
                    This is a development account. All changes to the library will be applied to the production account
                    as well.
                </Alert>
            }
            <Typography variant="subtitle2" gutterBottom component="h3" align={'right'}>
                Last deployed: {dateLastDeployed}
            </Typography>
            <BrandTableCard
                openAddModal={() => setOpenAddBrand(true)}
                openActivationModal={getActivationLogItems}
                openModalBrand={openModalBrand}
                activateBrand={onSwitchBrandState}
                items={renderedItems}
                loading={isLoadingBrandItems}
                downloadCSV={onCSVExport}
            />
            <EditBrandModal
                open={modalEditBrand}
                item={modalEditBrandItem}
                imgIndex={modalImageIndex}
                handleClose={_ => setModalEditBrand(false)}
                activateBrand={onToggleBrandState}
                toggleLogoVersionState={onToggleBrandState}
                updateMeta={putMeta}
                updateItemData={(updateData) => {
                    const indexBrand = brandItems.findIndex(
                        el => el.id === updateData.id
                    );
                    if (indexBrand === -1) {
                        return toastr.error(
                            'Id brand not found in local brand collection'
                        );
                    } else {
                        const itemsCopy = [...brandItems];
                        itemsCopy[indexBrand] = updateData;
                        setBrandItems(itemsCopy);
                        setModalEditBrandItem(updateData);
                    }

                }}
                showTextTab={user.isText}
            />
            <ActivationLogModal
                open={openActivationLog}
                items={logItems}
                handleClose={_ => setOpenActivationLog(false)}
            />
            <AddBrandsModal
                open={openAddBrand}
                handleClose={_ => setOpenAddBrand(false)}
                saveNewBrands={addNewBrands}
                brandsInUserLibrary={brandItems.map(b => b.id)}
            />
        </div>
    )
}

Brands.propTypes = {}

Brands.defaultProps = {}

export default Brands;
