import * as React from 'react';
import PropTypes from 'prop-types';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import DrawerContent from "./DrawerContent";
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import {IconButton} from "@mui/material";
import Badge from '@mui/material/Badge';

function FilterMenu(
    {
        editors,
        handleReviewStatus,
        handleReviewer,
        filters,
        changeFilterOption,
    }) {

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
        setAnchorEl(null);
    };

    return (
        <div>
            <IconButton
                id="basic-button"
                aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                aria-expanded={open ? 'true' : undefined}
                onClick={handleClick}
            >
                <Badge
                    variant="dot"
                    color="error"
                    invisible={
                        (filters.reviewed === 'Not Reviewed')
                        && (filters.dateAll)
                        && filters.reviewer.length === 0
                    }
                >
                    <FilterAltIcon color={'primary'}/>
                </Badge>
            </IconButton>
            <Menu
                id="filterMenu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                    'aria-labelledby': 'filterMenu',
                }}
            >
                <DrawerContent
                    editors={editors}
                    filters={filters}
                    handleReviewStatus={handleReviewStatus}
                    handleReviewer={handleReviewer}
                    changeFilterOption={changeFilterOption}
                />
            </Menu>
        </div>
    )
}

FilterMenu.propTypes = {}

FilterMenu.defaultProps = {}

export default FilterMenu;
