import React, {useEffect, useState} from "react";
import "./App.css";
import Header from './layout/Header';
import {HashRouter, Route, Switch} from "react-router-dom";
import DataService, {axios} from "./DataService";
import Explore from "./pages/Explore";
import MediaContainer from "./pages/MediaContainer";
import LibraryContainer from "./pages/LibraryContainer";
import MSummary from './pages/Summary';
import {ThemeProvider, useTheme} from '@mui/material/styles';
import visuaTheme from './theme';
import Page404 from "./pages/Page404";
import ApplicationLoader from "./components/ApplicationLoader/ApplicationLoader";
import PipelineContainer from "./pages/pipeline-container";
import { LicenseInfo } from '@mui/x-license-pro';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

LicenseInfo.setLicenseKey(
    '25c7e7101ecebd5d21fb882ce02e263dTz02NDg1NCxFPTE3MTM1OTY5NDQxODgsUz1wcm8sTE09c3Vic2NyaXB0aW9uLEtWPTI=',
);
function App() {
    const [user, setUser] = useState(null);
    const [fatalError, setFatalError] = useState(false);
    const [fatalErrorMsg, setFatalErrorMsg] = useState(null);

    const fetchUser = async (updates = null) => {
        const user = await DataService.getMe();
        try {
            // isVisualSearch
            user.isVisualSearch = user.projectTypes.some(pt => pt.name === 'visual_search');

            // isVideo
            const responsePars = user.detectionSetting.meta.response_pars
            console.log(responsePars)
            if (typeof responsePars === 'string') {
                user.isVideo = responsePars.includes("video")
            } else if (typeof responsePars.response_type === 'string') {
                user.isVideo = responsePars.response_type.includes("video")
            }

            // isTest
            user.isTest = !!user.developer.developerId
            user.isDetectMultipleScraping = user.permission.some(p => p === 'apiDetectMultipleUrl')
            user.isText = user.projectTypes.some(e => e.name === "text_detection")
        } catch {
            setFatalError(true);
        }

        if (!!updates) {
            user.developer.meta.firehose.apiMonitor.dateRange.begin = updates.begin;
            user.developer.meta.firehose.apiMonitor.dateRange.end = updates.end;
            user.developer.meta.firehose.apiMonitor.minScore = updates.minScore;
        }
        setUser(user);
    };

    // Load user profile
    useEffect(() => {
        fetchUser()
            .catch(err => {
                console.error(err);
                setFatalError(true);
                setUser(null);
            })
    }, []);

    // Check Cookie to warn user when they switch project in another tab
    useEffect(() => {
        function getCookie(name) {
            const nameEQ = name + "=";
            const ca = document.cookie.split(';');
            for (let i = 0; i < ca.length; i++) {
                let c = ca[i];
                while (c.charAt(0) === ' ') c = c.substring(1, c.length);
                if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
            }
            return null;
        }

        function listenCookieChange(callback, interval = 1000) {
            let lastCookie = getCookie("lastSwitchProject");
            setInterval(() => {
                let cookie = getCookie("lastSwitchProject");
                if (cookie !== lastCookie) {
                    try {
                        callback({oldValue: lastCookie, newValue: cookie});
                    } finally {
                        lastCookie = cookie;
                    }
                }
            }, interval);
        }

        listenCookieChange(_ => {
            setFatalError(true);
            setUser(null);
            setFatalErrorMsg('A change of user or project was detected. Please refresh the page.')
            }, 1000);
    }, [])


    return (

        user ? (
            <ThemeProvider theme={visuaTheme}>
                <ToastContainer
                    position="bottom-right"
                    autoClose={5000}
                    hideProgressBar={false}
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="light"
                />
                <HashRouter>
                    <Header user={user}/>
                    <section id="content" className="content">
                        <div
                            className="shuffle-animation"
                        >
                            <Switch>
                                <Route
                                    exact
                                    path="/"
                                    render={routeProps => (
                                        <MSummary
                                            {...routeProps}
                                            settings={user.developer.meta.firehose.apiMonitor}
                                            isVideo={user.isVideo}
                                            isVisualSearch={user.isVisualSearch}
                                        />
                                    )}
                                />
                                <Route
                                    path="/media"
                                    render={routerProps => (
                                        <MediaContainer
                                            {...routerProps}
                                            user={user}
                                        />
                                    )
                                    }
                                />
                                <Route
                                    path="/library"
                                    render={
                                        routerProps => (
                                            <LibraryContainer
                                                {...routerProps}
                                                user={user}
                                            />
                                        )
                                    }
                                />
                                <Route
                                    path="/explore"
                                    render={routerProps =>
                                        <Explore
                                            {...routerProps}
                                            metaTags={user.subscriptionMeta.apiExplore.keys}
                                            editors={user.subscriptionMeta.apiExplore.developers}
                                        />
                                    }
                                />
                                <Route
                                    path={"/pipeline"}
                                    render={() => <PipelineContainer />}
                                />
                                <Route
                                    render={routerProps => (
                                        <Page404/>
                                    )}
                                />
                            </Switch>
                        </div>
                    </section>
                </HashRouter>
            </ThemeProvider>
        ) : (
            <ApplicationLoader
                error={fatalError}
                errorMsg={fatalErrorMsg}
            />
        )
    );
}

export default App;
