import React from "react";
import {
    Container,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import {Toolbar} from "visuaui";
import {Link, NavLink, Redirect, Route, useRouteMatch} from "react-router-dom";
import VisuaSearch from "./Library/VisuaSearch";
import Text from './Library/Text'
import Brands from "./Library/Brands";
import Placement from "./Library/Placement";

export default function LibraryContainer({user})  {
    let { url } = useRouteMatch();
    let tabsConfig = [];
    /**
     * @todo: Move this first tab Logic to Header?
     * @type {string}
     */

    const {
        permission,
        projectTypes
    } = user;

    if (
        !!projectTypes && !!projectTypes.find(el => el.name === "logo_detection")
    ) {
        tabsConfig.push({
            label: 'Brands',
            url: `${url}/brands`,
        })
    }

    if (user.isVisualSearch) {
        tabsConfig.push({
            label: 'Visua Search',
            url: `${url}/visuasearch`,
        })
    }

    if (user.isText) {
        tabsConfig.push({
            label: 'Text',
            url: `${url}/Text`,
        })
    }

    if (permission.includes('apiLogoObject')) {
        tabsConfig.push({
            label: 'Placements',
            url: `${url}/Placement`
        })
    }

    const UserTabs = tabsConfig.map( (config) => {
        return<Tab
            key={config.label}
            label={config.label}
            component={Link}
            to={config.url}
            value={config.url}
            sx={{
                textTransform: 'none',
                "&.Mui-selected": {
                    fontWeight: 'bold !important',
                }
            }}
        />
    })

    return (
        <>
            <Toolbar>
                <Route
                    path="/"
                    render={({location}) => (
                        <Box sx={{borderBottom: 1, borderColor: 'divider'}}>
                            <Tabs value={location.pathname} aria-label="Second Level tabs for Library">
                                {UserTabs}
                            </Tabs>
                        </Box>
                    )}
                />
            </Toolbar>
            <Container
                maxWidth={'xl'}
                sx={{
                    pt:2,
                }}
            >
                <Route
                    path={`${url}/brands`}
                    render={routerProps => (
                        <Brands
                            isTest={user.isTest} user={user}
                        />
                    )}
                />
                <Route
                    path={`${url}/visuasearch`}
                    render={routerProps => (
                        <VisuaSearch {...routerProps}/>
                    )}
                />
                <Route
                    path={`${url}/text`}
                    render={routerProps => (
                        <Text
                            {...routerProps}
                            user={user}
                        />
                    )}
                />
                <Route
                    path={`${url}/Placement`}
                    render={routerProps => (
                        <Placement
                            {...routerProps}
                        />
                    )}
                />
            </Container>
        </>
    )
}
