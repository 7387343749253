import React, {useEffect, useState} from "react";
import Collapse from '@mui/material/Collapse';
import IconButton from '@mui/material/IconButton';
import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import DeleteIcon from '@mui/icons-material/Delete';
import {TransitionGroup} from 'react-transition-group';
import {Box} from "@mui/system";

function RenderItem({item,  handleRemove}) {
    return (
        <ListItem
            secondaryAction={
                <IconButton
                    edge="end"
                    aria-label="delete"
                    title="Delete"
                    onClick={() => handleRemove(item)}
                >
                    <DeleteIcon/>
                </IconButton>
            }
        >
            <ListItemText
                primary={item}
            />
        </ListItem>
    );
}

export default function AnimatedList({
                                         items = [],
                                         removeListItem,
                                     }) {

    return (
        <div>
            <Box sx={{mt: 1}}>
                <List>
                    <TransitionGroup>
                        {items.map((item, index) => (
                            <Collapse key={index}>
                                <RenderItem
                                    item={item}
                                    handleRemove={removeListItem}
                                />
                            </Collapse>
                        ))}
                    </TransitionGroup>
                </List>
            </Box>
        </div>
    );
}