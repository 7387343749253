import * as React from 'react';
import PropTypes from 'prop-types';
import {IconButton, Stack, Table, TableBody, TableCell, TableHead, TableRow, Tooltip} from "@mui/material";
import LocalOfferIcon from "@mui/icons-material/LocalOffer";
import LinkIcon from "@mui/icons-material/Link";
import ImageIcon from "@mui/icons-material/Image";
import DeleteIcon from "@mui/icons-material/Delete";

function AddPlacementsSummaryTable(
    {
        rows,
        handleRemoveItem,
    }
) {
    console.log(rows);
    return (
        <Table
            sx={{minWidth: 650}}
            aria-label="Placement  Summary Table"
            size="small"
        >
            <TableHead>
                <TableRow>
                    <TableCell
                        style={{width: "30%"}}
                    >
                        Placement Name
                    </TableCell>
                    <TableCell
                        style={{width: "30%"}}
                    >
                        Description
                    </TableCell>
                    <TableCell
                        style={{width: "25%"}}
                    >
                        File Names/Urls
                    </TableCell>
                    <TableCell
                        style={{width: "5%"}}
                    >
                        Tags
                    </TableCell>

                    <TableCell
                        align={'center'}
                        style={{width: "5%"}}
                    >
                        Remove
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {rows.map((row, index) => (
                    <TableRow
                        key={index}
                    >
                        <TableCell
                            style={{width: "30%"}}
                        >
                            <Tooltip
                                title={(row.name.length > 40) ? row.name : ''}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'clip',
                                        width: '100%'
                                    }}
                                >
                                    {row.name.slice(0, 40)}{(row.name.length > 40) ? "..." : ''}
                                </div>
                            </Tooltip>
                        </TableCell>
                        <TableCell
                            style={{width: "30%"}}
                        >
                            <Tooltip
                                title={(row.description.length > 40) ? row.description : ''}
                            >
                                <div
                                    style={{
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'clip',
                                        width: '100%'
                                    }}
                                >
                                    {row.description.slice(0, 40)}{(row.description.length > 40) ? "..." : ''}
                                </div>
                            </Tooltip>
                        </TableCell>

                        <TableCell  style={{width: "25%"}}>
                            {
                                (
                                    (row.exampleUrls && row.exampleUrls.length > 0)
                                )
                                &&
                                <Tooltip
                                    title={`The placement has ${row.exampleUrls.length} example url/s`}
                                >
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'clip',
                                            width: '100%'
                                        }}
                                    >
                                        <LinkIcon
                                            sx={{fontSize: '14px', color: '#8092A6', mr: 1}}
                                        />

                                        {row.exampleUrls[0].slice(0, 25)}
                                        {(row.exampleUrls[0].length > 25) ? "..." : ''}
                                    </div>
                                </Tooltip>
                            }

                            {
                                (
                                    (row.exampleImagesBase64 && row.exampleImagesBase64.length > 0)
                                )
                                &&
                                <Tooltip
                                    title={`The placement has ${row.exampleImagesBase64.length} example images`}
                                >
                                    <div
                                        style={{
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'clip',
                                            width: '100%'
                                        }}
                                    >
                                        <ImageIcon
                                            sx={{fontSize: '14px', color: '#8092A6', mr: 1}}
                                        />
                                        {row.exampleImagesName[0].slice(0, 25)}
                                        {(row.exampleImagesName[0].length > 25) ? "..." : ''}
                                </div>
                                </Tooltip>
                            }
                        </TableCell>
                        <TableCell   style={{width: "5%"}}>
                            {
                                (row.meta)
                                &&
                                <Tooltip
                                    title={`The placement has ${Object.keys(row.meta).length} tag/s`}
                                >
                                    <LocalOfferIcon
                                        sx={{fontSize: '14px', color: '#8092A6'}}
                                    />
                                </Tooltip>
                            }
                        </TableCell>
                        <TableCell
                            align={'center'}
                            style={{width: "5%"}}
                        >
                            <IconButton onClick={_ => handleRemoveItem(row)}>
                                <DeleteIcon/>
                            </IconButton>
                        </TableCell>
                    </TableRow>
                ))}
            </TableBody>
        </Table>
    )
}

AddPlacementsSummaryTable.propTypes = {}

AddPlacementsSummaryTable.defaultProps = {}

export default AddPlacementsSummaryTable;
