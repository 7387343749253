import * as React from 'react';
import {
    Button, FormControl,
    Grid, IconButton, InputLabel, MenuItem, Select,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    TextField,
    Typography,
    Switch, Alert,
} from "@mui/material";
import ToolbarSelect from "./ToolbarSelect";
import DeleteIcon from "@mui/icons-material/Delete";
import {useEffect, useState} from "react";
import DataService from "../DataService";
import TextTable from "./TextTable";
function BrandTextManager(
    {
        brand = {},
        setItems,
    }
) {
    const TextListInit = brand?.items?? []
    const [addTextValue, setAddTextValue] = useState('');
    const [threshold, setThreshold] = useState(1)
    const [textList, setListOfText] = useState(TextListInit);

    const handleChange = (event) => {
        setThreshold(event.target.value);
    };
    const addNewTextToBrand = () => {
        DataService.addTextToBrand(addTextValue, threshold, brand.id)
            .then(res => {
                if(res) {
                    const textToRender = res?.items.filter(el => el.brandId === brand.id)
                    setListOfText(textToRender)
                    setThreshold(0.8);
                    setAddTextValue('');
                }
            })
            .catch(err => console.error(err))
    }

    const localDataUpdate = (result) => {
        if (result) {
            const textToRender = result?.items.filter(el => el.brandId === brand.id)
            setListOfText(textToRender)
            setItems(textToRender)
        }
    }
    useEffect(() => {
        setListOfText(brand?.items?? [])
    }, [brand])
    useEffect(() => {
        const calcConfidence = () => {
            const tlen = addTextValue.length;
            if (tlen < 10) return 1;
            if (tlen <= 19) return 0.9;
            if (tlen <= 29) return 0.8;
            return 0.7
        }

        setThreshold(
            calcConfidence()
        )
    }, [addTextValue])
    console.log(textList)

    return (
        <Grid container={true}>
            <Grid container={true}>
                <Grid item xs={12}>
                    <Alert severity="warning">
                        We strongly recommend that you do not change the suggested threshold
                    </Alert>
                </Grid>
            </Grid>
            <Grid
                container={true}
                alignItems={'center'}
                spacing={2}
                sx={{mb: 2}}
            >
                <Grid item xs={7}>
                    <TextField
                        id="textValue"
                        label="Add Text String"
                        variant={'standard'}
                        fullWidth
                        value={addTextValue}
                        onChange={
                            e => {
                                setAddTextValue(e.target.value)
                            }
                        }
                    />
                </Grid>
                <Grid item xs={2}>
                    <FormControl variant="standard" sx={{ m: 1, minWidth: 120, width: '100%' }}>
                        <InputLabel >Threshold</InputLabel>
                        <Select
                            labelId="demo-simple-select-standard-label"
                            id="demo-simple-select-standard"
                            value={threshold}
                            onChange={handleChange}
                            label="threshold"
                            fullWidth={true}
                        >
                            {
                                [
                                    0.2,
                                    0.4,
                                    0.5,
                                    0.6,
                                    0.7,
                                    0.8,
                                    0.9,
                                    1,
                                ].map(option => (
                                    <MenuItem
                                        key={option}
                                        value={option}
                                    >
                                        {option}
                                    </MenuItem>
                                ))
                            }
                        </Select>
                    </FormControl>
                </Grid>
                <Grid item xs={3} textAlign={'right'} marginLeft={'auto'}>
                    <Button
                        fullWidth={true}
                        variant={'contained'}
                        onClick={() => addNewTextToBrand()}
                        disabled={addTextValue.length < 2}
                    >
                        Add Text
                    </Button>
                </Grid>
            </Grid>

            <Grid container={true}>
                <Grid item xs={12}
                      sx={{
                          maxHeight: '500px',
                          overflow: 'auto',
                      }}
                >
                    {
                        textList.length > 0 ?
                        <Table>
                            <TableHead
                                sx={{
                                    position: 'sticky',
                                    top: 0,
                                    background: '#fff',
                                    zIndex: 2,
                                }}
                            >
                                <TableRow>
                                    <TableCell>
                                        Text
                                    </TableCell>
                                    <TableCell
                                        style={{width: "20%"}}
                                    >
                                        Threshold
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        style={{width: "10%"}}
                                    >
                                        Toggle
                                    </TableCell>
                                    <TableCell
                                        align={'center'}
                                        style={{width: "10%"}}
                                    >
                                        Delete
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {
                                    textList.sort((a, b) => (a.text > b.text) ? 1 : -1).map( (item, index) => (
                                        <TableRow
                                            key={index}
                                        >
                                            <TableCell>{item.text}</TableCell>
                                            <TableCell
                                                style={{width: "10%"}}
                                            >
                                                <Select
                                                    labelId="demo-simple-select-standard-label"
                                                    id="demo-simple-select-standard"
                                                    value={item.threshold}
                                                    onChange={(event) => {
                                                        const {
                                                            value
                                                        } = event?.target;
                                                        DataService.putText(item.id, value)
                                                            .then((result) => {
                                                                localDataUpdate(result)
                                                            });
                                                    }}
                                                    label="threshold"
                                                    fullWidth={true}
                                                    variant={'standard'}
                                                >
                                                    {
                                                        [
                                                            0.2,
                                                            0.4,
                                                            0.5,
                                                            0.6,
                                                            0.7,
                                                            0.8,
                                                            0.9,
                                                            1,
                                                        ].map(option => (
                                                            <MenuItem
                                                                key={option}
                                                                value={option}
                                                                sx={{
                                                                    textAlign: 'center',
                                                                }}
                                                            >
                                                                {option}
                                                            </MenuItem>
                                                        ))
                                                    }
                                                </Select>
                                            </TableCell>
                                            <TableCell
                                                align={'center'}
                                                style={{width: "10%"}}
                                            >
                                                <Switch
                                                    checked={item.status === 1}
                                                    onChange={e => {
                                                        const value = e.target.checked;
                                                        const {id, threshold} = item;
                                                        if (value) {
                                                            DataService.putText(id, threshold).then((result) => {
                                                                localDataUpdate(result)
                                                            });
                                                        } else {
                                                            DataService.deleteText(id).then((result) => {
                                                                localDataUpdate(result)
                                                            });
                                                        }
                                                    }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{width: "10%"}}
                                            >
                                                <IconButton
                                                    color={'error'}
                                                    onClick={() => {
                                                        DataService
                                                            .deleteText(item.id, true)
                                                            .then((result) => {
                                                                localDataUpdate(result)
                                                            });
                                                    }}
                                                >
                                                    <DeleteIcon/>
                                                </IconButton>
                                            </TableCell>
                                        </TableRow>
                                    ))
                                }
                            </TableBody>
                        </Table>
                            :
                            <Typography variant={'h6'}>
                                No text for this brand yet
                            </Typography>
                    }

                </Grid>
            </Grid>
        </Grid>
    )
}


export default BrandTextManager;
