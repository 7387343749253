import React, {useState, useRef} from 'react';
import {Alert, Button, Grid, Snackbar} from "@mui/material";
import DownloadIcon from '@mui/icons-material/Download';
import IconButton from "@mui/material/IconButton";
import CodeIcon from '@mui/icons-material/Code';
import Badge from '@mui/material/Badge';
import MovieIcon from '@mui/icons-material/Movie';
import Tooltip from '@mui/material/Tooltip';
import Skeleton from '@mui/material/Skeleton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import VideoDetectionModal from "./VideoDetectionModal";
import DataService from "../DataService";
import toastr from "toastr";


function Status({ item }) {
    const STATUS_MAP = { 200: "Done", 400: "Error", 202: "In Progress" };

    function checkError(sessionId) {
        // @TODO: REMOVE TOAST IN THE FINAL RELEASE
        DataService.detect(sessionId).catch((request) =>
            toastr.error(request.response.data.errorMessage)
        );
    }

    if (item.status === 400) {
        return (
            <a
                href="/"
                onClick={(e) => {
                    e.preventDefault();
                    checkError(item.sessionId);
                }}
            >
                {STATUS_MAP[item.status]}
            </a>
        );
    } else {
        return STATUS_MAP[item.status] || null;
    }
}

function TextLoader() {
    return (
        <Skeleton
            sx={{
                width: '80%',
                margin: '0 auto',
            }}
            variant={'text'}
        />
    )
}


function ImgDiv({item, clickFn}) {
    const animationIntervals = useRef({});

    function mediaInfoToDuration(mediaInfo) {
        const seconds = mediaInfo.frames / mediaInfo.fps;
        const minutes = Math.floor(seconds / 60);
        const hours = Math.floor(minutes / 60);
        const secondsRemainder = Math.floor(seconds - minutes * 60);
        const minutesRemainder = Math.floor(minutes - hours * 60);

        return `${hours ? hours + ":" : ""}${(minutesRemainder + "").padStart(
            hours ? 2 : 1,
            "0"
        )}:${(secondsRemainder + "").padStart(2, "0")}`;
    }

    function onMouseEnter(target, item) {
        let frame = 0;
        const frameWidth = item.width / 4 / item.scaler;
        const frameHeight = item.height / 4 / item.scaler;

        animationIntervals.current[item.previewUrl] = setInterval(() => {
            let y = Math.floor(frame / 4) * frameHeight;
            let x = (frame % 4) * frameWidth;
            target.style.backgroundPositionX = "-" + x + "px";
            target.style.backgroundPositionY = y + "px";

            frame++;
            if (frame === 16) {
                frame = 0;
            }
        }, 500);
    }

    function onMouseLeave(target, previewUrl) {
        clearInterval(animationIntervals.current[previewUrl]);
        target.style.backgroundPosition = "";
    }


    const {width, height, scaler} = item;
    if (width) {
        return (
            <div
                onMouseEnter={(event) => onMouseEnter(event.target, item)}
                onMouseLeave={(event) => onMouseLeave(event.target, item.previewUrl)}
                style={{
                    backgroundColor: "#000",
                    backgroundClip: "content-box",
                    display: 'flex',
                    justifyContent: 'center',
                    position: 'relative',
                }}
                onClick={_ => {
                    return item.renderedMediaUrl? clickFn() : undefined;
                }}
            >

                <div
                    className="video-thumb align-self-end position-relative"
                    style={{
                        display: 'inline-block',
                        backgroundImage: `url(${item.previewUrl})`,
                        height: height / 4 / scaler,
                        width: width / 4 / scaler,
                        backgroundSize: width / scaler,
                        cursor: item.renderedMediaUrl ? "pointer" : "initial",
                    }}
                />
                <div style={{
                    position: 'absolute',
                    bottom: '5px',
                    right: '5px',
                    color: '#FFF',
                    backgroundColor: '#000',
                    borderRadius: '3px',
                    lineHeight: '12px',
                    fontSize: '12px',
                    outline: '1px solid #FFF',
                    padding: '2px',
                    fontWeight: 'bold',
                }}>
                    {item.mediaInfo && mediaInfoToDuration(item.mediaInfo)}
                </div>
            </div>
        )
    } else {
        return (
            <div
                style={{
                    width: "100%",
                    height: '100%',
                    minWidth: '255px',
                    minHeight: '145px',
                    background: 'black',
                    color: "white",
                    fontSize: "28px",
                    justifyContent: "center",
                }}
                className="align-items-center d-flex text-center"
            >
                No preview available
            </div>
        )
    }
}

export default function VideoDetectionRow({
    item = {},
    loading = false,
    onClickOnThumbnail,
    onClickOnQaStatus,
}) {

    const [cutText, setCutText] = useState(true);
    const [successCopy, setSuccessCopy] = useState({
        el: '',
        open: false,
    })

    let metaString = '';
    const {
        sessionId,
        progress,
        meta,
        csvUrl,
        status,
        qa,
    } = item;

    if (meta && meta['.name']) {
        metaString = meta['.name'];
    }

    const closeCopyToast = () => {
        setSuccessCopy(prev => {
            return {...prev, open: false}
        })
    }

    const copyMediaToClipboard = (mediaData) => {
        navigator.clipboard
            .writeText(mediaData)
            .then(() =>
                setSuccessCopy({
                    el: 'Media Tag', open: true,
                })
            );
    }

    function onCopyMediaUrl(mediaUrl) {
        navigator.clipboard
            .writeText(mediaUrl)
            .then(() =>
                setSuccessCopy({
                    el: 'Video Url', open: true,
                })
            );
    }

    return (
        <>
            <Grid
                container={true}
                justifyContent={'space-between'}
                flexWrap={'wrap'}
                sx={{
                    my: 2,
                    ":hover": {
                        backgroundColor: 'rgba(0,0,0,.05)',
                    },
                    p: 1,
                }}
            >
                <Grid item flex={'2'}>
                    {loading ? <Skeleton height={145} width={255}
                                         variant={'rectangular'}/> :
                        <ImgDiv item={item} clickFn={
                            _ => onClickOnThumbnail(true, sessionId)
                        }/>}
                </Grid>
                <Grid item flex={'1'} textAlign={'center'}>
                    {loading ? <TextLoader/> : <span>{sessionId}</span>}
                </Grid>
                <Grid item flex={'1'} textAlign={'center'}>
                    {loading ? <TextLoader/> : (
                        <>
                            <span>{
                                progress !== 100 ? progress : <Status item={item} />
                            }</span>
                            {
                                qa && qa.chunks &&
                                <>
                                    <br/>
                                <Button
                                    onClick={_ => {
                                        onClickOnQaStatus()
                                    }}
                                >
                                    QA: {qa.progress}%
                                </Button>
                                </>
                            }
                        </>
                    )}
                </Grid>
                <Grid item flex={'3'}>
                    {
                        loading ? <Skeleton height={100} width={'90%'}
                                            variant={'rectangular'}/> :
                            <div
                                style={{
                                    padding: '5px'
                                }}
                            >
                                {metaString.slice(0, cutText ? 150 : metaString.length)}
                                {(metaString.length > 150 && cutText) &&
                                    <span>...</span>}
                                {
                                    metaString.length > 150 &&
                                    <Button onClick={_ => setCutText(!cutText)}>
                                        See more
                                    </Button>
                                }
                            </div>
                    }
                </Grid>
                <Grid item flex={'1'} textAlign={'right'}>
                    {
                        loading ?
                            <Skeleton sx={{ml: 'auto'}} width={20} height={20}
                                      variant={'circular'}/> :
                            (
                                <>
                                    {
                                        metaString.length > 0 &&
                                        <div>
                                            <Tooltip
                                                title="Copy medatada"
                                                placement="left"
                                            >
                                                <IconButton
                                                    onClick={_ => copyMediaToClipboard(metaString)}
                                                >
                                                    <Badge badgeContent={
                                                        <ContentCopyIcon
                                                            sx={{fontSize: 13}}/>
                                                    }>
                                                        <CodeIcon/>
                                                    </Badge>
                                                </IconButton>
                                            </Tooltip>
                                        </div>
                                    }
                                    <div>
                                        <Tooltip
                                            title="Copy mediaUrl"
                                            placement="left"
                                        >
                                            <IconButton
                                                onClick={_ => onCopyMediaUrl(item.mediaUrl)}
                                            >
                                                <Badge badgeContent={
                                                    <ContentCopyIcon
                                                        sx={{fontSize: 13}}/>
                                                }>
                                                    <MovieIcon/>
                                                </Badge>
                                            </IconButton>
                                        </Tooltip>
                                    </div>
                                </>
                            )
                    }
                </Grid>
                <Grid item flex={'1'} textAlign={'right'}>
                    {loading ?
                        <div>
                            <Skeleton sx={{ml: 'auto'}} width={20} height={20}
                                      variant={'circular'}/>
                            <Skeleton sx={{ml: 'auto', mt: 1}} width={20}
                                      height={20} variant={'circular'}/>
                            <Skeleton sx={{ml: 'auto', mt: 1}} width={20}
                                      height={20} variant={'circular'}/>
                        </div> :
                        status === 200 && (
                            <>
                                <div>
                                    <a
                                        href={`https://api.visua.com/detect/${item.sessionId}/response?download=${item.sessionId}.json`}
                                    >
                                        JSON
                                        <DownloadIcon/>
                                    </a>
                                </div>
                                {csvUrl && (
                                    <div>
                                        <a href={csvUrl}>
                                            CSV
                                            <DownloadIcon/>
                                        </a>
                                    </div>
                                )}
                                {item.renderedMediaUrl && (
                                    <>
                                        <a download="a.mp4"
                                           href={item.renderedMediaUrl}>
                                            MP4
                                            <DownloadIcon/>
                                        </a>
                                    </>
                                )}
                            </>
                        )
                    }
                </Grid>
            </Grid>
            <Snackbar
                anchorOrigin={
                    {vertical: 'top', horizontal: 'right' }
                }
                open={successCopy.open}
                autoHideDuration={6000}
                onClose={closeCopyToast}
            >
                <Alert onClose={closeCopyToast} severity="success" sx={{ width: '100%' }}>
                    {successCopy.el} has been copied to your clipboard!
                </Alert>
            </Snackbar>
        </>
    )
}
