/**
 * Turn Bits in a human-readable size format
 * @param x
 * @returns {string}
 */

export const niceBytes = (x) => {
    const units = ['bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
    let l = 0, n = parseInt(x, 10) || 0;
    while(n >= 1024 && ++l){
        n = n/1024;
    }
    return(n.toFixed(n < 10 && l > 0 ? 1 : 0) + ' ' + units[l]);
}

export const indexOfAll = (arr, val) => arr.reduce((acc, el, i) => (el === val ? [...acc, i] : acc), []);

export const cleanAndParseCSV = (
    csvData = [],
    headersExpected = [],
) => {

    const indexKeys = [];
    const tableData = [];

    if (csvData.length === 0) {
        throw `Empty CSV file`
    }

    const headerArray = csvData[0]
        .filter(el => el.length !== 0)
        .map(
            el => el
                .replace(/[^A-Z0-9]/ig, "")
                .toLowerCase()
        );
    const data = csvData.slice(1).filter(row => row.filter(el => el.length !== 0).length !== 0);


    for (let i = 0; i < headersExpected.length; i++) {
       const {
           keyword,
           multiple,
           optional,
       }  = headersExpected[i];

       const lowerKeyword = keyword.toLowerCase()
       if (!!multiple) {
           const indexes = indexOfAll(headerArray, lowerKeyword)

           if (indexes.length === 0) {
               if (!optional) {
                   throw `Mandatory fields ${keyword} not found`
               }
           } else {
               indexKeys.push({
                   keyword: keyword,
                   index: indexes
               })
           }
       } else {
           const index = headerArray.indexOf(keyword)
           if (index === -1) {
               if (!optional) {
                   throw `Mandatory field ${keyword} not found`
               }
           } else {
               indexKeys.push({
                   keyword,
                   index,
               })
           }
       }
    }

    data.forEach((fields, index) => {
        const row = {};
        indexKeys.forEach(indexKey => {
            if(Array.isArray(indexKey.index)) {
               const dat = [];
                indexKey.index.forEach(i => {
                    dat.push(fields[i].trim())
                })
                row[indexKey.keyword] = dat;
            } else {
                row[indexKey.keyword] = fields[indexKey.index]
            }
        })
        tableData.push(row)
    })


    return tableData


}

const UNDEFINED_STATUS_CODE = 99999;

export const prePostFcFilter = (el) => el.task.fcType === 0;

export const commercialFilter = (el) => (
    (
        el.task.status === -105
        || el.task.status === -106
        || el.task.status === -110
    ) && (el.media.completionPercentage ?? 0) < 80
);

export const boostIVPfilter = (el) => (
    el.media.completionPercentage === 100
    && el.task.completionPercentage === 0
    && !el.task.developer
);

export const annotationFilter = (el) => (
    el.media.completionPercentage === 100
    || ((
        el.task?.completionPercentage
        && el.task?.completionPercentage > 0
    ) && el.task.developer)
);

export const qaFilter = (el) => (
    el.task.status <= -200 && el.task.status > -300
);

const FINAL_CHECK_STATUS = [-300, -303, -306, -307, -308, -309, -310];

export const finalCheckFilter = (el) => FINAL_CHECK_STATUS
    .includes(el.task?.status ?? UNDEFINED_STATUS_CODE);

const FINAL_FIX_STATUS = [-301, -302];

export const finalFixFilter = (el) => FINAL_FIX_STATUS
    .includes(el.task?.status ?? UNDEFINED_STATUS_CODE);

const ANNO_TOP_UP_STATUS = [-311, -312, -313, -314, -315];

export const annotationTopUpFilter = (el) => ANNO_TOP_UP_STATUS
    .includes(el.task?.status ?? UNDEFINED_STATUS_CODE);

export const preReleaseFilter = (el) => (
    el.task.status <= -400 && el.task.status > -500
);

const getStage = (el) => {
    if (preReleaseFilter(el)) return 'Pre-release';
    if (prePostFcFilter(el)) return 'Pre/Post FC';
    if (commercialFilter(el)) return 'Commercial';
    if (boostIVPfilter(el)) return 'Boost IVP';
    if (qaFilter(el)) return 'QA';
    if (finalCheckFilter(el)) return 'Final Check';
    if (finalFixFilter(el)) return 'Final Fix';
    if (annotationTopUpFilter(el)) return 'Annotation Top Up';
    if (el.task.status === 0) return 'Ingesting';
    if (annotationFilter(el)) return 'Annotation';
    return 'Unknown';
}

export const fromIannServerDataToTableData = (qaData) => {
    if (!qaData) return [];

    return qaData.map((el, id) => {
        const { task, project, media } = el;
        return {
            id,
            priority: task.property.priority,
            project: `${project?.detectionSettingId} - ${project?.name}`,
            location: media.developerMeta?.['Fixture Location'] ?? 'N/A',
            batch: el.task.batch,
            sessionId: media.sessionId,
            stage: task.statusDescription,
            creationData: media.dateEntered,
            fixtureName: media.developerMeta?.['Fixture Name'] ?? '',
            broadcaster: media.developerMeta?.['Broadcaster'] ?? '',
            fixtureTime: media.developerMeta?.['Fixture Time'] ?? '',
            homeTeam: media.developerMeta?.['Home Team'] ?? '',
            visitingTeam: media.developerMeta?.['Visiting Team'] ?? '',
            frameRate: media?.mediaMeta?.fps?.toFixed(2),
            resolution: `${media?.mediaMeta?.width ?? 'Unknown'}x${media?.mediaMeta?.height ?? 'Unknown'}`,
            hours: (((media?.mediaMeta?.frames || 0)
                / (media?.mediaMeta?.fps || 0)) / (60 * 60)).toFixed(2),
            seasonStage: media.developerMeta?.['Season Stage'] ?? '',
            fixtureType: media.developerMeta?.['Fixture Type'] ?? '',
            market: media.developerMeta?.['Market'] ?? '',
            spotRate: media.developerMeta?.['Spot Rate'] ?? '',
            mediaUrl: media.mediaUrl,
        };
    });
};
