import * as React from 'react';
import PropTypes from 'prop-types';
import {Card, CardContent, Collapse, Grid, IconButton, TextField, Typography} from "@mui/material";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import AddIcon from "@mui/icons-material/Add";
import AnimatedDoubleList from "./animatedList/AnimatedDoubleList";
import {useState} from "react";

function CollapseTagCard(
    {
        tags,
        setTags,
    }
) {

    const [openTagsCard, setOpenTagCard] = useState(false);
    const [tagName, setTagName] = useState('');
    const [tagValue, setTagValue] = useState('');

    const handleAddTag = () => {
        const oldTags = tags;

        oldTags[tagName] = tagValue;

        setTags(
            oldTags
        );

        setTagName('');
        setTagValue('');
    }

    const removeTags = (key) => {
        const newTags = {...tags};
        delete newTags[key]
        setTags(newTags);
    }

    return (
        <Card
            sx={{
                my: 1,
            }}
        >
            <Grid
                container
                alignItems={'center'}
                sx={{
                    p: 1,
                }}
            >
                <Grid
                    item
                    xs={6}
                    onClick={_ => {
                        setOpenTagCard(
                            !openTagsCard
                        )
                    }}
                >
                    <Typography
                        variant={'h5'}
                        sx={{
                            fontSize: '1rem',
                            fontWeight: 'bold',
                        }}
                    >
                        Meta Tags
                    </Typography>
                </Grid>
                <Grid item sx={{ml: 'auto'}}>
                    <IconButton
                        onClick={_ => {
                            setOpenTagCard(
                                !openTagsCard
                            )
                        }}
                        sx={{ml: 'auto'}}
                    >
                        {
                            openTagsCard
                                ? <KeyboardArrowUpIcon/>
                                : <KeyboardArrowDownIcon/>
                        }
                    </IconButton>
                </Grid>
            </Grid>

            <Collapse in={openTagsCard}>
                <CardContent
                    sx={{pt: 0}}
                >
                    <Grid container spacing={1}>
                        <Grid item xs={5}>
                            <TextField
                                label="Tag Name"
                                variant="standard"
                                value={tagName}
                                onChange={e => setTagName(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <TextField
                                label="Tag Value"
                                variant="standard"
                                value={tagValue}
                                onChange={e => setTagValue(e.target.value)}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={1}>
                            <IconButton
                                color={'success'}
                                onClick={_ => {
                                    handleAddTag()
                                }}
                            >
                                <AddIcon/>
                            </IconButton>
                        </Grid>
                    </Grid>
                    <AnimatedDoubleList
                        items={Object.keys(tags)}
                        subtitles={ Object.keys(tags).map(el => tags[el])}
                        removeListItem={removeTags}
                    />
                </CardContent>
            </Collapse>
        </Card>
    )
}

CollapseTagCard.propTypes = {
    tags: PropTypes.object.isRequired,
    setTags: PropTypes.func.isRequired,
}


export default CollapseTagCard;
