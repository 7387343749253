import React, {useState} from "react";
import {
    Container,
    Tabs,
    Tab,
    Box,
} from "@mui/material";
import {Toolbar} from "visuaui";
import {Link, NavLink, Redirect, Route, useRouteMatch} from "react-router-dom";
import Detections from "../Detections";
import Workflow from "../Workflow";
import VideoDetectionPage from "./media/VideoDetection";
import MediaUpload from "./media/MediaUpload/MediaUpload";
function MediaContainer({user}) {
    let { url } = useRouteMatch();
    let _privateTabs = [];
    let tabsConfig = [];


    const {
        subscriptionMeta
    } = user;

    if (subscriptionMeta) {
        const {metadata} = subscriptionMeta;
        if (metadata) {
            const {privateTabs} = metadata;
            if (privateTabs) {
                _privateTabs = privateTabs;
            }
        }
    }

    if (
        !user.isVideo && (!_privateTabs.includes("detections"))
    ) {
        tabsConfig.push({
            label: 'Detections',
            url: `${url}/detections`,
        })
    }

    if (user.isVideo && !_privateTabs.includes("video-detections")) {
        tabsConfig.push({
            label: 'Video detections',
            url: `${url}/video-detections`,
        })
    }


    tabsConfig.push({
        label: 'Upload media',
        url: `${url}/upload`,
    })


    if (user.permission.includes("apiWorkflow")) {
        tabsConfig.push({
            label: 'Workflow',
            url: `${url}/workflow`,
        })
    }

    const UserTabs = tabsConfig.map( (config) => {
        return<Tab
            key={config.label}
            label={config.label}
            component={Link}
            to={config.url}
            value={config.url}
            sx={{
                textTransform: 'none',
                "&.Mui-selected": {
                    fontWeight: 'bold !important',
                }
            }}
        />
    })

    return (
        <>
            <Toolbar>
                <Route
                    path="/"
                    render={({location}) => (
                            <Tabs value={location.pathname} aria-label="Second Level tabs for Media">
                                {UserTabs}
                            </Tabs>
                    )}
                />
            </Toolbar>
            <Container
                maxWidth={'xl'}
                sx={{
                    pt:2,
                }}
            >
                <Route path="/">
                </Route>

                <Route
                    path={`${url}/detections`}
                    render={routerProps => (
                        <Detections
                            {...routerProps}
                            user={user}
                            settings={user.developer.meta.firehose.apiMonitor}
                        />
                    )}
                />
                <Route
                    path={`${url}/video-detections`}
                    render={routerProps => (
                        <VideoDetectionPage
                            {...routerProps}
                            settings={user.developer.meta.firehose.apiMonitor}/>
                    )}
                />
                <Route
                    path={`${url}/upload`}
                    render={routerProps => (
                        <MediaUpload
                            {...routerProps}
                            user={user}
                        />
                    )}
                />
                <Route
                    path={`${url}/workflow`}
                    render={routerProps => (
                        <Workflow
                            {...routerProps}
                        />
                    )}
                />
            </Container>
        </>
    )
}

export default MediaContainer;
