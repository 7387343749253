import React, {useState, useEffect} from "react";
import PropTypes from 'prop-types';
import {DataGrid} from "@mui/x-data-grid";
import {
    Switch,
    LinearProgress,
    Button,
    Stack,
    IconButton,
} from '@mui/material'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import './ExploreCard.css';
import Box from "@mui/material/Box";
import {styled} from "@mui/system";
import Chip from "@mui/material/Chip";
import DataService from "../../DataService";
import CardTooltip from "../CardTooltip";
import moment from "moment";

const StyleChip = styled(
    (props) => (
        <Chip
            {...props}
        />
    )
)({
    fontSize: '11px',
    fontWeight: 'bold',
    marginRight: '4px',
    marginBottom: '4px'
});


const DetectionCell = (
    {
        currentId,
        selectedRow,
        detections
    }
) => {
    let name, iconUrl;
    let feedDetections = []


    detections.forEach(detection => {
        switch (detection.type) {
            case 'logo':
                name = detection.name;
                iconUrl = detection.iconUrl;
                feedDetections.push({
                    iconUrl,
                    color: 'error',
                    name,
                    confidence: detection.confidence
                })
                break;
            case 'object':
                name = detection.name;
                feedDetections.push({
                    name,
                    color: 'warning',
                    confidence: detection.confidence,
                })
                break;
            case 'text':
                const {text, confidence} = detection;
                feedDetections.push({
                    name: text,
                    confidence,
                    color: 'secondary'
                })
                break;
        }
    })
    const [detectionToShow, setDetectionToShow] = useState(feedDetections.slice(0, 3));

    useEffect(_ => {
        if (selectedRow === currentId) {
            setDetectionToShow(feedDetections)
        } else {
            setDetectionToShow(feedDetections.slice(0, 3));
        }
    }, [selectedRow])

    return (
        <Stack spacing={1} sx={{maxWidth: '100%'}}>
            {
                detectionToShow.map((el, index) => {
                    return (
                        <StyleChip
                            key={index}
                            color={`${el.color}`}
                            size={'small'}
                            label={`${el.name} ${el.confidence}`}
                        />
                    )
                })
            }
        </Stack>
    )
}


const DescriptionCell = (
    {
        description = '',
        currentId,
        selectedRow,
    }
) => {
    const MAX_CHAR_BEFORE_CUT = 200;
    const [textToShow, setTextToShow] = useState(description.slice(0, MAX_CHAR_BEFORE_CUT))
    const isTextToCut = description.length > 250;
    useEffect(_ => {
        if (selectedRow === currentId) {
            setTextToShow(`${description}`)
        } else {
            setTextToShow(`${description.slice(0, MAX_CHAR_BEFORE_CUT)}${isTextToCut ? "..." : ""}`);
        }
    }, [selectedRow])

    return (
        <>
            {textToShow}
        </>
    )
}

const ActionCell = (
    {
        params,
        isButtonRequired,
        setSelectedRow,
        selectedRow,
        setRowState,
    }) => {
    const {row} = params;
    const {sessionId, review} = row;
    const {developer, timeUpdated} = review;
    const [isBtnDown, setIsBtnDown] = useState(true);
    const [checked, setChecked] = useState(review.status === 1);
    const [reviewer, setReviewer] = useState(review.status === 1 ? (developer? developer.name : '') : '');
    const [reviewDate, setReviewDate] = useState(review.status === 1 ? timeUpdated : '');


    const showFullRowFn = () => {
        const currentStatus = selectedRow === sessionId;
        if (currentStatus) {
            setSelectedRow('');
        } else {
            setIsBtnDown(false);
            setSelectedRow(sessionId);
        }
    }

    useEffect(_ => {
        if (selectedRow !== sessionId) {
            setIsBtnDown(true);
        }
    }, [selectedRow])


    return (
        <Box sx={{
            height: '100%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: "space-between",
            alignItems: "center",
        }}>
            <CardTooltip
                title={
                    (review.status === 1) ? <><b>Reviewed by</b> {reviewer}
                        <br/> {moment(reviewDate).format("DD/MM/YYYY")}</> : ''
                }
                placement={'left'}
            >
                <span>
            <Switch
                disabled={review.status === 1}
                checked={checked}
                onChange={(e, v) => {
                    DataService.detectExplore(sessionId)
                    setRowState(
                        (prevState) => {
                            const currRows = prevState.rows;
                            const rowIndex = currRows.findIndex(el => el.sessionId === sessionId);
                            currRows[rowIndex].review.status = 1;
                            setReviewer('You');
                            setReviewDate(new Date());
                            return {
                                ...prevState,
                                rows: currRows
                            }
                        }
                    );
                    setChecked(v)
                }}
            />
                </span>
            </CardTooltip>
            {
                isButtonRequired &&
                <IconButton onClick={showFullRowFn}>
                    {isBtnDown
                        ? <KeyboardArrowDownIcon/>
                        : <KeyboardArrowUpIcon/>
                    }
                </IconButton>
            }
        </Box>
    )
}


function ExploreTable(
    {
        openModal,
        rowState,
        setRowState,
        metaCols,
    }
) {

    const [selectedRow, setSelectedRow] = useState(null);

    const columns = [
        {
            field: 'sessionId',
            headerName: 'id',
            sortable: false,
        },
        {
            field: 'mediaUrl',
            headerName: 'Media',
            sortable: false,
            flex: .5,
            renderCell: (params) => {
                const {row} = params;
                const {mediaUrl, sessionId} = row;
                return (
                    <div className={'ExploreTableImgContainer'} onClick={_ => openModal(sessionId)}>
                        <img src={mediaUrl} alt={sessionId}/>
                    </div>
                )
            }
        },
        {
            field: 'detections',
            headerName: 'detections',
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                const {row} = params;
                const {detections, sessionId} = row;
                return (
                    <DetectionCell
                        currentId={sessionId}
                        detections={detections}
                        selectedRow={selectedRow}
                    />
                )
            }
        },
        {
            field: 'timeCreated',
            headerName: 'date',
            sortable: true,
            flex: 1,
        },
    ];


    metaCols.forEach(metaCol => {
        const {name, type} = metaCol;
        const newCol = {
            field: name,
            headerName: name,
            sortable: false,
            flex: 1,
        };

        if (type === 'url') {
            newCol['renderCell'] = (params) => {
                const {row} = params;
                return (
                    <a href={row[name]}>{row[name]}</a>
                )
            };
        }

        if (type === 'blob') {
            newCol['renderCell'] = (params) => {
                const {row} = params;
                const {sessionId} = row;
                const content = row[name] ?? "" ;

                return (
                    <DescriptionCell
                        currentId={sessionId}
                        selectedRow={selectedRow}
                        description={content}
                    />
                )
            };
        }

       columns.push(newCol);
    });

    columns.push({
            field: "review.status",
            headerName: 'review',
            width: 100,
            renderCell: (params => {
                const {row} = params;

                const {detections} = row;
                const blobMeta = metaCols.filter(col => col.type === 'blob');
                let AreBlobsLong = false;

                blobMeta.forEach(blob => {
                    if (blob.length > 250) AreBlobsLong = true
                });


                const isButtonRequired = ( detections.length > 3) || AreBlobsLong;

                return (
                    <ActionCell
                        params={params}
                        selectedRow={selectedRow}
                        setSelectedRow={setSelectedRow}
                        isButtonRequired={isButtonRequired}
                        setRowState={setRowState}
                    />
                )
            })
    });

    return (
        <div style={{width: "100%", height: "calc(100vh - 72px - 32px - 60px)"}}>
            <DataGrid
                sx={{
                    '& .MuiDataGrid-renderingZone': {
                        maxHeight: 'none !important',
                    },
                    '& .MuiDataGrid-cell': {
                        lineHeight: 'unset !important',
                        maxHeight: 'none !important',
                        whiteSpace: 'normal',
                        fontSize: '12px',
                        wordBreak: "break-all",
                        alignItems: 'baseline',
                        paddingTop: '15px',
                        paddingBottom: '15px',
                    },
                    '& .MuiDataGrid-row': {
                        maxHeight: 'none !important',
                    },
                    ".MuiTablePagination-selectLabel": {
                        mb: 0,
                    },
                    ".MuiTablePagination-displayedRows": {
                        mb: 0,
                    }

                }}
                rows={rowState.rows}
                columns={columns}
                pageSize={rowState.pageSize}
                rowCount={rowState.totalRows}
                pagination
                paginationMode="server"
                rowsPerPageOptions={[25, 50, 100]}
                disableSelectionOnClick
                rowHeight={150}
                getRowId={el => el.sessionId}
                loading={rowState.loading}
                columnVisibilityModel={{
                    sessionId: false,
                }}
                components={{
                    LoadingOverlay: LinearProgress,
                }}
                onPageChange={
                    (page) => {
                        setRowState((prevState) => ({
                            ...prevState,
                            page: page + 1
                        }));
                    }
                }
                onPageSizeChange={
                    size => {
                        setRowState((prevState) => ({
                            ...prevState,
                            pageSize: size
                        }));
                    }
                }
                disableColumnMenu
            />
        </div>
    )
}


export default ExploreTable;
