import React, {useEffect, useState} from "react";
import DataService from "../../DataService";
import {useDateRangePicker} from "../../CustomHooks";
import {
    Card,
    CardActions,
    CardContent,
    FormControl,
    Grid,
    IconButton,
    InputLabel,
    MenuItem,
    Pagination,
    Select,
    Skeleton,
    Typography,
} from "@mui/material";
import {Toolbar} from "visuaui";
import "../../VideoDetections.scss"
import BlockIcon from '@mui/icons-material/Block';
import CalendarTodayIcon from '@mui/icons-material/CalendarToday';
import VideoDetectionRow from "../../components/VideoDetectionRow";
import CheckIcon from '@mui/icons-material/Check';
import VideoDetectionModal from "../../components/VideoDetectionModal";
import VideoQADataModal from "../../components/VideoQADataModal";

export default function VideoDetectionPage({
                                               settings,
                                               location,
                                               history
                                           }) {
    const params = new URLSearchParams(location.search);
    const {startDate, endDate, selectedDateRange} = useDateRangePicker(
        params,
        settings
    );

    const urlCurrentPage = params.get('currentPage');
    const [currentPage, setCurrentPage] = useState(urlCurrentPage ?
        parseInt(urlCurrentPage, 10) : 1);

    const [pageCount, setPageCount] = useState(0);

    let initialPageSize = settings.video ? settings.video.pageSize || 10 : 10
    const urlPageSize = params.get("pageSize");
    const [pageSize, setPageSize] = useState(
        urlPageSize ? parseInt(urlPageSize, 10) : initialPageSize,
    );
    const [totalCount, setTotalCount] = useState(0);
    const [groupByDate, setGroupByDate] = useState(
        settings.video ? settings.video.groupByDate : true
    );
    const [videoData, setVideoData] = useState({});
    const [loading, setLoading] = useState(true);

    const [openVideoModal, setOpenVideoModal] = useState(false);
    const [itemModal, setItemModal] = useState({
        renderedMediaUrl: '',
        timeCreated: '',
    });

    const [openQaModal, setOpenQaModal] = useState(false);
    const [itemQaStatus, setItemQaStatus] = useState({});

    useEffect(_ => {
        setLoading(true);
        DataService.getFeed({
            dateBegin: startDate,
            dateEnd: endDate,
            page: currentPage,
            pageSize,
        }).then(data => {
            const {feed, total} = data;
            setTotalCount(total);
            setPageCount(Math.ceil(total / pageSize))
            if (feed) {
                const imagePromises = data.feed.map(
                    (row) =>
                        new Promise((resolve, reject) => {
                            const img = new Image();
                            img.onload = () =>
                                resolve(
                                    Object.assign(row, {
                                        width: img.width,
                                        height: img.height,
                                        scaler: Math.max(img.width / 4 / 255, img.height / 4 / 145),
                                    })
                                );
                            img.onerror = () => resolve(row);
                            img.src = row.previewUrl;
                        })
                );


                Promise.all(imagePromises)
                    .then((augmentedData) => {
                        const groupedByDate = augmentedData.reduce((acc, val) => {
                            const date = val.timeCreated.slice(0, -9);
                            acc[date] = acc[date] || [];
                            acc[date].push(val);
                            return acc;
                        }, {});
                        console.log(groupedByDate);
                        setVideoData(groupedByDate)
                        setLoading(false);
                    })
                    .then(_ => {
                        const sessionId = params.get("sessionId");
                        if (sessionId) {
                            const modalElem = feed.find(el => el.sessionId === sessionId);

                            if (modalElem) {
                                setItemModal(modalElem);
                                setOpenVideoModal(true);
                            }
                        }
                    });
            }
        })

    }, [startDate, endDate, currentPage, pageSize])


    const handleVideoModal = (status, item) => {
        if (status === true) {
            setItemModal(item);
            history.push({
                pathname: "/media/video-detections",
                search: `?sessionId=${item.sessionId}&startDate=${startDate.format(
                    "YYYY-MM-DD"
                )}&endDate=${endDate.format("YYYY-MM-DD")}&currentPage=${currentPage}&pageSize=${pageSize}`,
            });
        } else {
            setItemModal({});
            history.push({
                pathname: "/media/video-detections",
            });
        }
        setOpenVideoModal(status);
    }

    const handleQaModal = (status, item) => {
        if (status === true) {
            setItemQaStatus(item);
        }
        setOpenQaModal(status)
    }
    return (
        <>
            <Card
                raised={true}
                sx={{
                    p: 1,
                    mb: 5,
                }}
            >
                <Toolbar removeBackground={true}>
                    <Grid container alignItems={'center'}>
                        <Grid item>
                            <div className="p-2">
                                <div className="form-control"
                                     lg-range-selector="">
                                    {selectedDateRange}
                                </div>
                            </div>
                        </Grid>
                        <Grid item className={'p-2'} sx={{mx: 2}}>
                            Group by date
                            <IconButton
                                onClick={_ => {
                                    setGroupByDate(
                                        !groupByDate
                                    )
                                }}
                            >
                                {
                                    groupByDate ?
                                        (
                                            <>
                                                <CalendarTodayIcon
                                                    color={'primary'}/>
                                                <CheckIcon sx={{
                                                    position: "absolute",
                                                    fontSize: '20px',
                                                    bottom: '8px',
                                                    right: '10px',
                                                    color: 'green'
                                                }}
                                                />
                                            </>
                                        )
                                        : (
                                            <>
                                                <CalendarTodayIcon/>
                                                <BlockIcon sx={{
                                                    position: 'absolute',
                                                    fontSize: '32px',
                                                    color: 'red',
                                                }}/>
                                            </>
                                        )
                                }
                            </IconButton>
                        </Grid>
                        <Grid item sx={{ml: 'auto'}} className={'p2'}>
                            <FormControl variant="standard"
                                         sx={{m: 1, minWidth: 120}}>
                                <InputLabel
                                    id="demo-simple-select-standard-label">Videos
                                    per page</InputLabel>
                                <Select
                                    labelId="demo-simple-select-standard-label"
                                    id="demo-simple-select-standard"
                                    value={pageSize}
                                    onChange={(e) => setPageSize(e.target.value)}
                                >
                                    <MenuItem value={10}>10</MenuItem>
                                    <MenuItem value={20}>20</MenuItem>
                                    <MenuItem value={50}>50</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>


                </Toolbar>
                <CardContent sx={{
                    minWidth: '1024px'
                }}>
                    <Grid
                        container={true}
                        justifyContent={'space-between'}
                        sx={{
                            borderBottom: '1px solid #ccc',
                            p: 1
                        }}
                    >
                        <Grid item flex={'2'}>
                            <span>Media</span>
                        </Grid>
                        <Grid item flex={'1'} textAlign={'center'}>
                            <span>Session ID</span>
                        </Grid>
                        <Grid item flex={'1'} textAlign={'center'}>
                            <span>Status</span>
                        </Grid>
                        <Grid item flex={'3'}>
                            <span>Media Tag</span>
                        </Grid>
                        <Grid item flex={'1'} textAlign={'right'}>
                            <span>Actions</span>
                        </Grid>
                        <Grid item flex={'1'} textAlign={'right'}>
                            <span>Download</span>
                        </Grid>
                    </Grid>

                    {
                        loading
                            ? (
                                <>
                                    {
                                        groupByDate &&
                                        <p
                                            style={{
                                                backgroundColor: "#BCC5CF",
                                                padding: "5px",
                                                fontWeight: "bold",
                                                fontSize: '14px',
                                            }}
                                        >
                                            <Skeleton variant={'text'}/>
                                        </p>
                                    }
                                    {
                                        [...Array(10).keys()].map((video, index) =>
                                            <VideoDetectionRow
                                                loading={true}
                                                key={index}
                                            />
                                        )
                                    }
                                </>
                            )
                            : (
                                Object.keys(videoData).map(key => (
                                        <>
                                            {
                                                groupByDate &&
                                                <p
                                                    style={{
                                                        backgroundColor: "#BCC5CF",
                                                        padding: "5px",
                                                        fontWeight: "bold",
                                                        fontSize: '14px',
                                                    }}
                                                    key={key}
                                                >
                                                    {key}
                                                </p>
                                            }
                                            {
                                                videoData[key].map(video =>
                                                    <VideoDetectionRow
                                                        onClickOnQaStatus={_ => handleQaModal(true, video)}
                                                        onClickOnThumbnail={_ => handleVideoModal(true, video)}
                                                        key={video.sessionId}
                                                        item={video}/>
                                                )
                                            }
                                        </>
                                    )
                                )
                            )
                    }
                    {
                        (Object.keys(videoData).length === 0 && !loading)
                        && <Grid container justifyContent={'center'}
                                 alignItems={'center'}>
                            <Grid item xs={8} sx={{mt: 4}}>
                                <Typography
                                    variant={'h3'}
                                >
                                    Uh oh...
                                </Typography>
                                <Typography
                                    variant={'h6'}
                                >
                                    Sorry! We couldn't find any data using the
                                    filters selected. Maybe
                                    try something else?
                                </Typography>
                            </Grid>
                        </Grid>

                    }

                </CardContent>
                {
                    pageCount > 1 &&
                    <CardActions sx={{
                        justifyContent: 'center',
                    }}>
                        <Pagination
                            count={pageCount}
                            variant="outlined"
                            page={currentPage}
                            color="primary"
                            onChange={(event, value) => {
                                setCurrentPage(value);
                            }}
                        />
                    </CardActions>
                }
            </Card>
            <VideoDetectionModal
                open={openVideoModal}
                item={itemModal}
                handleClose={_ => handleVideoModal(false)}
            />
            <VideoQADataModal
                open={openQaModal}
                item={itemQaStatus}
                handleClose={_ => handleQaModal(false)}
            />
        </>
    )
}
